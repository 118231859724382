import React, { useState } from 'react';
import { Container, Row, Button, Card, Modal, Form } from 'react-bootstrap';
import { XCircle } from 'react-bootstrap-icons';
import pdfimg from './pdfimg.png'
import './campaigncss/FileUpload.css'
function FileUpload() {
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null); // To preview the selected image or PDF

    // Handle opening and closing modals
    const handleOpenPhotoModal = () => {
        setSelectedFile(null); // Reset the file when opening modal
        setPreviewUrl(null);
        setShowPhotoModal(true);
    };
    const handleClosePhotoModal = () => setShowPhotoModal(false);
    const handleOpenDocumentModal = () => {
        setSelectedFile(null);
        setPreviewUrl(null);
        setShowDocumentModal(true);
    };
    const handleCloseDocumentModal = () => setShowDocumentModal(false);

    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        // Preview image or PDF
        const fileUrl = URL.createObjectURL(file);
        setPreviewUrl(fileUrl);
    };

    // Handle saving
    const handleSave = () => {
        if (selectedFile) {
            console.log("Selected file:", selectedFile);
        }
        // Close the modal after saving
        handleClosePhotoModal();
        handleCloseDocumentModal();
    };

    // Handle removing the selected file
    const handleRemoveFile = () => {
        setSelectedFile(null);
        setPreviewUrl(null);
    };

    return (
        <Container >
            <Row>
                <Card className="mt-0 border-0" style={{ position: 'relative' }}>
                    <Card.Body>
                        <h5 className='fileheading'>File Upload</h5>
                        <br />
                        <Row>
                            <Button style={{ width: '150px', fontSize: '13px' }} onClick={handleOpenPhotoModal} className='filebtn'>
                                Add Photo/Video
                            </Button>
                        </Row>
                        <br />
                        <Row>
                            <Button style={{ width: '150px', fontSize: '13px' }} onClick={handleOpenDocumentModal} className='filebtn'>
                                Add Document
                            </Button>
                        </Row>

                        <Row className="mt-5">

                            <Button style={{ backgroundColor: 'white', border: '#1976D2 1px solid', color: '#1976D2' }} className="custom-success-btn me-2 mt-5 mb-2">
                                Cancel
                            </Button>
                            <Button style={{ backgroundColor: '#9B9B9B', border: 'none' }} className="custom-success-btn me-2  mt-5">
                                Save
                            </Button >
                            <Button style={{ backgroundColor: '#9B9B9B', border: 'none', width: '145px' }} className="custom-success-btn me-2  mt-5">
                                Request Approve
                            </Button>

                        </Row>
                    </Card.Body>
                </Card>
            </Row>

            {/* Photo/Video Upload Modal */}
            <Modal show={showPhotoModal} onHide={handleClosePhotoModal} centered size="lg" className="custom-modal">
                <Modal.Body style={{ height: '560px' }}>
                    <h4 className="maintitle">Add Photo/Video</h4>
                    <div style={{ textAlign: 'center', height: '156px', padding: '40px', margin: '20px', border: '1px solid #D9D9D9' }}>
                        <p>Ratio limit 4:3, e.g. 375(W) * 221(H). File size: within 500MB</p>
                        <Form.Group controlId="formFile">
                            <input
                                type="file"
                                id="file-input"
                                accept="image/*,video/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Button onClick={() => document.getElementById('file-input').click()}>Select</Button>
                        </Form.Group>
                    </div>

                    {/* Preview Image or Video */}
                    {previewUrl && (
                        <div style={{ textAlign: 'left', marginTop: '20px' }}>
                            {selectedFile.type.startsWith('image/') ? (
                                <img src={previewUrl} alt="Preview" style={{ width: '375px', height: '221px' }} />
                            ) : (
                                <video width="375" height="221" controls>
                                    <source src={previewUrl} />
                                </video>
                            )}
                            <XCircle
                                style={{ cursor: 'pointer', color: 'red', marginTop: '10px' }}
                                size={24}
                                onClick={handleRemoveFile}
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePhotoModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={!selectedFile}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Document Upload Modal */}
            <Modal show={showDocumentModal} onHide={handleCloseDocumentModal} centered size="lg" className="custom-modal">
                <Modal.Body style={{ height: '560px' }}>
                    <h4 className="maintitle">Add Document</h4>
                    <div style={{ textAlign: 'center', height: '156px', padding: '40px', margin: '20px', border: '1px solid #D9D9D9' }}>
                        <p>File size: within 25MB, format PDF</p>
                        <Form.Group controlId="formFileDocument">
                            <input
                                type="file"
                                id="file-input-doc"
                                accept=".pdf,.doc,.docx"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Button onClick={() => document.getElementById('file-input-doc').click()}>Select</Button>
                        </Form.Group>
                    </div>

                    {/* Preview PDF or Document */}
                    {previewUrl && (
                        <div style={{ textAlign: 'left', marginTop: '20px' }}>
                            {selectedFile.type === 'application/pdf' ? (
                                <img src={pdfimg} alt="PDF Preview" style={{ width: '66px', height: '73px' }} />

                            ) : (
                                <img src="pdfimg.png" alt="PDF Preview" style={{ width: '66px', height: '73px' }} />
                            )}
                            <XCircle
                                style={{ cursor: 'pointer', color: 'red', marginTop: '10px' }}
                                size={24}
                                onClick={handleRemoveFile}
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDocumentModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={!selectedFile}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default FileUpload;



// import React from 'react';
// import { Form, Button, Table, Container, Row, Col } from 'react-bootstrap';

// function FileUpload() {
//     return (
//         <Container className="my-4">
//             <h4>Add a Group</h4>

//             {/* Campaign Name Section */}
//             <Row className="mb-3">
//                 <Col>
//                     <Form.Group controlId="campaignName">
//                         <Form.Label>Campaign Name</Form.Label>
//                         <Form.Select>
//                             <option>Cantonese Opera Class (Beginner)</option>
//                             {/* Add more options if needed */}
//                         </Form.Select>
//                     </Form.Group>
//                 </Col>
//             </Row>

//             {/* Filter Section */}
//             <Row className="mb-3">
//                 <Col>
//                     <Form.Group controlId="filter">
//                         <Form.Label>Filter</Form.Label>
//                         <Form.Select>
//                             <option>None</option>
//                             <option>Above</option>
//                             {/* Add more options if needed */}
//                         </Form.Select>
//                     </Form.Group>
//                 </Col>
//                 <Col className="d-flex align-items-end">
//                     <Form.Control type="text" placeholder="above" />
//                 </Col>
//                 <Col className="d-flex align-items-end">
//                     <Button variant="primary">Clear</Button>
//                 </Col>
//                 <Col className="d-flex align-items-end">
//                     <Button variant="warning">Search</Button>
//                 </Col>
//             </Row>

//             {/* Quota Info Section */}
//             <Row className="mb-3">
//                 <Col>Quota: 10</Col>
//                 <Col>No. of applicants: 8</Col>
//                 <Col>Quota Usage: 80%</Col>
//             </Row>

//             {/* Table */}
//             <Table striped bordered hover responsive>
//                 <thead>
//                     <tr>
//                         <th>Member ID</th>
//                         <th>Member Name</th>
//                         <th>Mobile</th>
//                         <th>Class 1</th>
//                         <th>Class 2</th>
//                         <th>Class 3</th>
//                         <th>Cla</th>
//                         <th>Reason of Absence</th>
//                         <th>Overall Attendance</th>
//                         <th>Status</th>
//                         <th>Edit</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     <tr>
//                         <td>Q000006</td>
//                         <td>Lily Kong</td>
//                         <td>98370232</td>
//                         <td>1</td>
//                         <td>1</td>
//                         <td>1</td>
//                         <td>1</td>
//                         <td></td>
//                         <td>100%</td>
//                         <td>Approved</td>
//                         <td>
//                             <Button variant="primary" size="sm">✎</Button>
//                         </td>
//                     </tr>
//                     {/* Add more rows here */}
//                 </tbody>
//             </Table>

//             {/* Bottom Buttons */}
//             <Row className="mt-3">
//                 <Col>
//                     <Button variant="secondary">Cancel</Button>
//                     <Button variant="success" className="ms-2">Import</Button>
//                 </Col>
//             </Row>
//         </Container>
//     );
// }

// export default FileUpload;
