import React, { useState } from "react";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Switch } from "@mui/material";
import './campaigncss/Instructor.css'
function Instructor() {
    return (
        <Container fluid>
            <Row>

                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Card.Body>
                        <Form>

                            <h5 className="Instructorheading">
                                Instructor(s)
                            </h5>
                            <Row className="mt-3">

                                <Col md={4}>
                                    <Form.Group controlId="inquiry" className="floating-label-group">
                                        <Form.Select name="inquiry" className="floating-input">
                                            <option value="Ling"> Lily Leung</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">Name</Form.Label>
                                    </Form.Group>
                                </Col>

                                <Col md={1} className="d-flex justify-content-center align-items-center">
                                    <Button
                                        style={{
                                            backgroundColor: '#FF4694',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '0', // Ensures no extra space around the text
                                        }}
                                    >
                                        --
                                    </Button>
                                </Col>

                            </Row>

                            <Row>

                                <Col md={4}>
                                    <Form.Group controlId="inquiry" className="floating-label-group">
                                        <Form.Select name="inquiry" className="floating-input">
                                            <option value="Ling"> Flora Fong</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">Name</Form.Label>
                                    </Form.Group>
                                </Col>

                                <Col md={1} className="d-flex justify-content-center align-items-center">
                                    <Button
                                        style={{
                                            backgroundColor: '#FF4694',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '0', // Ensures no extra space around the text
                                        }}
                                    >
                                        --
                                    </Button>
                                </Col>

                            </Row>

                            <Row>

                                <Col md={4}>
                                    <Form.Group controlId="inquiry" className="floating-label-group">
                                        <Form.Select name="inquiry" className="floating-input">
                                            <option value="Ling"> Jie Gui Fang</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">Name</Form.Label>
                                    </Form.Group>
                                </Col>

                                <Col md={1} className="d-flex justify-content-center align-items-center">
                                    <Button
                                        style={{
                                            backgroundColor: '#FF4694',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '0', // Ensures no extra space around the text
                                        }}
                                    >
                                        --
                                    </Button>
                                </Col>

                            </Row>

                            <br></br><br></br>
                         
                           <Row className="mt-5">

                         

                            <Button style={{ backgroundColor: 'white', border: '#1976D2 1px solid', color: '#1976D2' }} className="custom-success-btn me-2 mt-5 mb-2">
                                Cancel
                            </Button>
                            <Button style={{ backgroundColor: '#9B9B9B', border: 'none' }} className="custom-success-btn me-2  mt-5">
                                Save
                            </Button >
                            <Button style={{ backgroundColor: '#9B9B9B', border: 'none', width: '145px' }} className="custom-success-btn me-2  mt-5">
                                Request Approve
                            </Button>
                            <Button variant="success" className="custom-success-btn me-2  mt-5" style={{ width: '160px' }}>
                                Add Instructor
                            </Button>
                            
                            </Row>

                        </Form>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    )
}

export default Instructor
