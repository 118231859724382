// import React, { useState } from 'react';
// import { Container, Row, Col, Card, Form, Collapse, Button, Table } from 'react-bootstrap';
// import { FaMinus } from 'react-icons/fa'; // Import Minus icon from react-icons
// import './campaigncss/Lesson.css';
// // import { Container, Row, Col, Button, Modal, Form, Table, InputGroup, Card } from 'react-bootstrap';

// function Attendencedetail() {
//     // Set green box to be visible by default and form hidden
//     const [showGreenBox, setShowGreenBox] = useState(true); // Green box initially visible
//     const [showForm, setShowForm] = useState(false);         // Form initially hidden

//     return (
//         <Container fluid>
//             <Row>
//                 <Card className="mt-0 border-0" style={{ position: "relative" }}>
//                     <h5 className='lessonheading p-2'>Attendance Details</h5>

//                     <Card.Body>
//                         {/* Green Box Section - Visible by default */}
//                         {showGreenBox && (
//                             <div
//                                 className="p-3 border border-success rounded d-flex justify-content-between align-items-center mb-3 lessoncard"
//                                 style={{ cursor: 'pointer' }}
//                                 onClick={() => setShowForm(!showForm)} // Toggle form visibility when clicking the box
//                             >
//                                 <h5 className="mb-0 cardtext">Date :</h5>
//                                 <FaMinus style={{ color: 'white' }} /> {/* Minus icon on the right side */}
//                             </div>
//                         )}

//                         {/* Form Section - Only show if green box is clicked */}
//                         <Collapse in={showForm}>
//                             <div className="p-3 ">
//                                 <Row>
//                                     <Col md={12} className="d-flex justify-content-between align-items-center mt-5">
//                                         <div className="d-flex">
//                                             <p className="grouplabel me-3 mb-0">No. of attendee(s): 7</p>
//                                             <p className="grouplabel mb-0 ms-5">No. of absentee(s): 1</p>
//                                         </div>
//                                         <Button variant="primary">Add</Button> {/* Save button aligned to the right */}
//                                     </Col>
//                                 </Row>


//                                 <Table striped hover responsive className='mt-3'>
//                                     <thead>
//                                         <tr>
//                                             <th className='tableheadgrp'>Member ID</th>
//                                             <th className='tableheadgrp'>Member Name</th>
//                                             <th className='tableheadgrp'>Mobile</th>
//                                             <th className='tableheadgrp'>Attendance</th>
//                                             <th className='tableheadgrp'>Absent Reason</th>
//                                             <th className='tableheadgrp'>Approver</th>
//                                             <th className='tableheadgrp'>Funcion(s)</th>

//                                         </tr>
//                                     </thead>
//                                     <tbody className='tableheadgrp'>
//                                         <tr>
//                                             <td>Q000006</td>
//                                             <td>Lily Kong</td>
//                                             <td>98370232</td>
//                                             <td>1</td>
//                                             <td>1</td>
//                                             <td>1</td>

//                                             <td>
//                                                 <Button variant="outline-primary" size="sm">Edit</Button> {/* Edit button in last td */}
//                                             </td>
//                                         </tr>


//                                     </tbody>

//                                 </Table>
//                             </div>
//                         </Collapse>
//                     </Card.Body>
//                 </Card>
//             </Row>
//         </Container>
//     );
// }

// export default Attendencedetail;






import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Collapse, Button, Table, Modal } from 'react-bootstrap';
import { FaMinus } from 'react-icons/fa';
import './campaigncss/Attendance.css';

function Attendencedetail() {
    // State for showing green box and form
    const [showGreenBox, setShowGreenBox] = useState(true);
    const [showForm, setShowForm] = useState(false);

    // State for modal visibility and edit mode
    const [showMemberModal, setShowMemberModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [newMember, setNewMember] = useState({
        memberId: '',
        memberName: '',
        mobile: '',
        status: ''
    });

    // Toggle modal visibility
    const handleShowMemberModal = (edit = false) => {
        setIsEditing(edit);
        setShowMemberModal(true);
    };
    const handleCloseMemberModal = () => setShowMemberModal(false);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewMember(prevState => ({ ...prevState, [name]: value }));
    };

    // Save or update member function
    const handleSaveMember = () => {
        if (isEditing) {
            // Handle update logic here
        } else {
            // Handle save logic here
        }
        handleCloseMemberModal();
    };

    return (
        <Container fluid>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <h5 className='lessonheading p-2'>Attendance Details</h5>

                    <Card.Body>
                        {/* Green Box Section - Visible by default */}
                        {showGreenBox && (
                            <div
                                className="p-3 border border-success rounded d-flex justify-content-between align-items-center mb-3 lessoncard"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowForm(!showForm)}
                            >
                                <h5 className="mb-0 cardtext">Date :</h5>
                                <FaMinus style={{ color: 'white' }} />
                            </div>
                        )}

                        {/* Form Section - Only show if green box is clicked */}
                        <Collapse in={showForm}>
                            <div className="p-2 ">
                                <Row>
                                    <Col md={12} className="d-flex justify-content-between align-items-center mt-5">
                                        <div className="d-flex">
                                            <p className="grouplabel me-3 mb-0">No. of attendee(s): 7</p>
                                            <p className="grouplabel mb-0 ms-5">No. of absentee(s): 1</p>
                                        </div>
                                        <Button variant="" onClick={() => handleShowMemberModal(false)} className='attendancebtn'>Add</Button>
                                    </Col>
                                </Row>

                                <Table striped hover responsive className='mt-3'>
                                    <thead>
                                        <tr>
                                            <th className='tableheadgrp'>Member ID</th>
                                            <th className='tableheadgrp'>Member Name</th>
                                            <th className='tableheadgrp'>Mobile</th>
                                            <th className='tableheadgrp'>Attendance</th>
                                            <th className='tableheadgrp'>Absent Reason</th>
                                            <th className='tableheadgrp'>Approver</th>
                                            <th className='tableheadgrp'>Function(s)</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tableheadgrp'>
                                        <tr>
                                            <td>Q000006</td>
                                            <td>Lily Kong</td>
                                            <td>98370232</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td>
                                                <Button variant="" size="sm" onClick={() => handleShowMemberModal(true)} className='attendancebtn'>Edit</Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Collapse>
                    </Card.Body>
                </Card>
            </Row>

            {/* Modal for Add/Edit Member */}
            <Modal show={showMemberModal} onHide={handleCloseMemberModal} centered className="custom-modal-width">
                <Modal.Body>
                    <Form>
                        <h4 className='memberheads'>{isEditing ? 'Attendance Details - Edit' : 'Attendance Details - Save'}</h4>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="memberId">
                                    <Form.Label className='grouplabel'>Member ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="memberId"
                                        className="floating-input memberslabels"
                                        value={newMember.memberId}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={9}>
                                <Form.Group controlId="memberName">
                                    <Form.Label className='grouplabel'>Member Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="memberName"
                                        className="floating-input memberslabels"
                                        value={newMember.memberName}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group controlId="mobile">
                                    <Form.Label className='grouplabel'>Mobile</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="mobile"
                                        className="floating-input memberslabels"
                                        value={newMember.mobile}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group controlId="formStatus" className="mt-3 mb-3">
                            <Form.Label className='grouplabel'>Attendance</Form.Label>
                            <div className="d-flex">
                                <Form.Check
                                    type="radio"
                                    label="Yes"
                                    name="status"
                                    value="approve"
                                    checked={newMember.status === 'approve'}
                                    onChange={handleInputChange}
                                    className='grouplabel me-3' // Adds margin to the right
                                />
                                <Form.Check
                                    type="radio"
                                    label="No"
                                    name="status"
                                    value="reject"
                                    checked={newMember.status === 'reject'}
                                    onChange={handleInputChange}
                                    className='grouplabel'
                                />
                            </div>
                        </Form.Group>


                        <Row className="mb-3">
                            <Col md={7}>
                                <Form.Group controlId="Absent Reason">
                                    <Form.Label className='grouplabel'>Absent Reason</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="Absent Reason"
                                        className="floating-input memberslabels"
                                        value={newMember.mobile}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="" onClick={handleCloseMemberModal} className="me-2 groupclear">
                        Cancel
                    </Button>
                    <Button variant="" onClick={handleSaveMember} className='groupsearch'>
                        {isEditing ? 'Update' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Attendencedetail;
