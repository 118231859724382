import { Icon } from '@mui/material';
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import './campaigncss/Survey.css'
function Survy() {
    // Track the index of the row being edited (-1 means no row is being edited)
    const [editableRow, setEditableRow] = useState(-1);

    // Toggle between editable and read-only modes for a specific row
    const handleEditClick = (index) => {
        setEditableRow(index === editableRow ? -1 : index); // Toggle editable state
    };

    const questions = [
        {
            chinese: "您對導師的滿意度如何？(1 顆星：非常差，5 顆星：非常好)",
            english: "How satisfied are you with your instructor? (1 star: very bad, 5 stars: very good)",
            weight: "50%"
        },
        {
            chinese: "您對導師的滿意度如何？(1 顆星：非常差，5 顆星：非常好)",
            english: "What do you think of the course content?(1 star: very bad, 5 stars: very good)",
            weight: "50%"
        }
    ];

    return (
        <Container>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Card.Body>
                        <Form>
                            <h5 className='surveyheading'>Survey</h5>

                            {questions.map((question, index) => (
                                <Row className="align-items-center mb-3 mt-3" key={index}>
                                    <Col>
                                        <Form.Group controlId={`question${index + 1}-chinese`} className="floating-label-group">
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                name={`question${index + 1}-chinese`}
                                                className="floating-input"
                                                style={{
                                                    height: 'auto',
                                                    color: editableRow === index ? '#000' : '#848484',
                                                    borderColor: '#848484',
                                                    backgroundColor: 'transparent'
                                                }}
                                                value={question.chinese}
                                                readOnly={editableRow !== index} // Only editable if this row is being edited
                                            />
                                            <Form.Label className="floating-label">Question (Chinese)</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId={`question${index + 1}-english`} className="floating-label-group">
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                name={`question${index + 1}-english`}
                                                className="floating-input"
                                                style={{
                                                    height: 'auto',
                                                    color: editableRow === index ? '#000' : '#848484',
                                                    borderColor: '#848484',
                                                    backgroundColor: 'transparent'
                                                }}
                                                value={question.english}
                                                readOnly={editableRow !== index}
                                            />
                                            <Form.Label className="floating-label">Question (English)</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group controlId={`weight${index + 1}`} className="floating-label-group">
                                            <Form.Control
                                                type="text"
                                                name={`weight${index + 1}`}
                                                className="floating-input"
                                                style={{
                                                    color: editableRow === index ? '#000' : '#848484',
                                                    borderColor: '#848484',
                                                    backgroundColor: 'transparent'
                                                }}
                                                value={question.weight}
                                                readOnly={editableRow !== index}
                                            />
                                            <Form.Label className="floating-label">Weight</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={1} className="d-flex flex-column justify-content-between">
                                        <Button
                                            style={{
                                                backgroundColor: '#FF4694',
                                                border: 'none',
                                                height: '24px',
                                                width: '24px',
                                                padding: '0',
                                                marginBottom: '8px'
                                            }}
                                        >
                                            <Icon>remove</Icon>
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: '#1976D2',
                                                border: 'none',
                                                height: '24px',
                                                width: '24px',
                                                padding: '0'
                                            }}
                                            onClick={() => handleEditClick(index)} // Toggle edit mode for this row
                                        >
                                            <Icon>edit</Icon>
                                        </Button>
                                    </Col>
                                </Row>
                            ))}

                            {/* User Comments Section */}
                            <Row className='mt-5'>
                                <h5 className='usercomment mt-5'> User Comments <FormControlLabel control={<Switch />} label="" /></h5>
                                <p className='usertext'>Enable User Comments in the Portal, and members can freely comment on the mobile app.</p>
                            </Row>

                            {/* Action Buttons */}
                            <Row className="mt-4">
                                <Button style={{ backgroundColor: 'white', border: '#1976D2 1px solid', color: '#1976D2' }} className="custom-success-btn me-2">
                                    Cancel
                                </Button>
                                <Button style={{ backgroundColor: '#9B9B9B', border: 'none' }} className="custom-success-btn me-2">
                                    Save
                                </Button>
                                <Button style={{ backgroundColor: '#9B9B9B', border: 'none', width: '145px' }} className="custom-success-btn me-2">
                                    Request Approve
                                </Button>
                                <Button variant="success" className="custom-success-btn me-2" style={{ width: '160px' }}>
                                    Add Question
                                </Button>

                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
}

export default Survy;
