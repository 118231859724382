import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
// MUI DATE PICKER
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// ALL CONTEXT FILES
// import { AuthProvider } from "app/contexts/FirebaseAuthContext";
import { AuthProvider } from "app/contexts/JWTAuthContext";
import { SettingsProvider } from "app/contexts/SettingsContext";
// THEME PROVIDER
import { MatxTheme } from "./components/MatxTheme";
// REDUX STORE
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
// ALL ROUTES
import routes from "./routes";
// FAKE DATABASE
import "../fake-db";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
  const content = useRoutes(routes);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SettingsProvider>
            <MatxTheme>
              <AuthProvider>{content}</AuthProvider>
            </MatxTheme>
          </SettingsProvider>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  );
}
