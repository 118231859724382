

// import React, { useState, useEffect } from "react";
// import { Table, Button, Form, Modal, Row, Col } from "react-bootstrap";
// import './categoriescss/UserCategories.css';
// import { Campaign } from "@mui/icons-material";
// import axios from 'axios';
// import { TablePagination } from "@mui/material"; // Import TablePagination from MUI

// const UserCategories = () => {
//     const [selectedOption, setSelectedOption] = useState("");
//     const [showModal, setShowModal] = useState(false);



//     const [inputValues, setInputValues] = useState({
//         input1: "",
//         input2: "",
//         input3: "",
//         input4: "",
//         input5: "",
//         input6: "",
//         input7: "",
//         input8: "",
//         input9: "",
//         input10: "",
//         input11: "",
//     });

//     // Event handler to update selected option
//     const handleSelectionChange = (e) => {
//         setSelectedOption(e.target.value);
//     };

//     // Function to toggle the modal
//     const handleModalShow = () => setShowModal(true);
//     const handleModalClose = () => {
//         setShowModal(false);
//         resetForm();
//     };

//     // Handle input change for all input fields
//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setInputValues({ ...inputValues, [name]: value });
//     };



//     // Reset form fields
//     const resetForm = () => {
//         setInputValues({
//             input1: "",
//             input2: "",
//             input3: "",
//             input4: "",
//             input5: "",
//             input6: "",
//             input7: "",
//             input8: "",
//             input9: "",
//             input10: "",
//             input11: "",
//         });
//     };

//     //---------------------------------------------------------------------------------------------------------\\

//     // Handle form submit post apis

//     const handleFormSubmit = async () => {
//         let endpoint = "";
//         const payload = {
//             membertype: inputValues.input1,
//             descriptionChinese: inputValues.input2,
//             descriptionEnglish: inputValues.input3,
//             ////////////////////////////
//             incentre: inputValues.input4,
//             venuechines: inputValues.input5,
//             venueenglish: inputValues.input6,
//             //////////////////////////////////
//             campaignnamechines: inputValues.input7,
//             campaignnameeglish: inputValues.input8,

//             //////////////////////////////////////

//             audienceChinese: inputValues.input9,
//             audienceEnglish: inputValues.input10,
//             Code: inputValues.input11,
//         };



//         switch (selectedOption) {
//             case "campaignType":
//                 endpoint = "  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add_campaign_type";
//                 break;
//             case "campaignVenue":
//                 endpoint = "  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add-campaign-venue";
//                 break;
//             case "campaignName":
//                 endpoint = "  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add-campaign-name";
//                 break;
//             case "campaignAudience":
//                 endpoint = "  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add-campaign-audience";
//                 break;
//             default:
//                 alert("Please select a valid option");
//                 return;
//         }

//         try {
//             const response = await fetch(endpoint, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify(payload,),
//             });

//             if (response.ok) {
//                 alert("Data added successfully!");
//                 resetForm();
//                 handleModalClose();
//             } else {
//                 alert("Failed to add data. Please try again.");
//             }
//         } catch (error) {
//             console.error("Error submitting form:", error);
//             alert("An error occurred while submitting the form.");
//         }
//     };


//     //---------------------------------------------------------------------------------------------------------\\

//     // Get Apis--------------------------------------------------


//     const [data, setData] = useState([]);

//     useEffect(() => {
//         fetch("    https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-types")
//             .then((response) => response.json())
//             .then((result) => {
//                 console.log("Fetched data:", result);
//                 setData(result.accounts || []);
//             })
//             .catch((error) => console.error("Error fetching data:", error));
//     }, []);


//     // const fetchCampaignTypes = async () => {
//     //     try {
//     //         const response = await axios.get("  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-types");
//     //         console.log("Fetched data:", response.data); // Log the entire response data

//     //         // Check if accounts exist in the response
//     //         if (response.data.accounts) {
//     //             // Filter for campaign types
//     //             setData(response.data.accounts.filter(user => user.pk_id.startsWith("cmptype#")));
//     //         } else {
//     //             console.error("Accounts data is undefined in the response.");
//     //             setData([]); // Optionally set data to an empty array
//     //         }
//     //     } catch (error) {
//     //         console.error("Error fetching campaign types:", error);
//     //     }
//     // };



//     const [venuedata, setvenuedataData] = useState([]);



//     useEffect(() => {
//         fetch("    https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-venues")
//             .then((response) => response.json())
//             .then((result) => {
//                 console.log("Fetched data:", result);
//                 setvenuedataData(result.accounts || []);
//             })
//             .catch((error) => console.error("Error fetching data:", error));
//     }, []);


//     // const fetchCampaignVenues = async () => {
//     //     try {
//     //         const response = await axios.get("  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-venues");
//     //         console.log("Fetched data:", response.data); // Debug API response
//     //         // setvenuedataData(response.data.venues || []); // Adjust according to your API response structure
//     //         setvenuedataData(response.data.accounts.filter(user => user.pk_id.startsWith("cmpvenue#")));

//     //     } catch (error) {
//     //         console.error("Error fetching campaign venues:", error);
//     //     }
//     // };

//     // // Call fetchCampaignVenues when the component mounts
//     // useEffect(() => {
//     //     fetchCampaignVenues();
//     // }, []);


//     const [namedata, setNameData] = useState([]);
//     const [audiencedata, setAudienceData] = useState([]);


//     // const fetchCampaignNames = async () => {
//     //     try {
//     //         const response = await axios.get("  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-name");
//     //         console.log("Fetched campaign names:", response.data); // Debug API response
//     //         // setNameData(response.data.names || []); // Adjust according to your API response structure cmpname
//     //         setNameData(response.data.accounts.filter(user => user.pk_id.startsWith("cmpname#")));

//     //     } catch (error) {
//     //         console.error("Error fetching campaign names:", error);
//     //     }
//     // };

//     // // Fetch campaign audiences from the backend API
//     // const fetchCampaignAudiences = async () => {
//     //     try {
//     //         const response = await axios.get("  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-audience");
//     //         console.log("Fetched campaign audiences:", response.data); // Debug API response
//     //         // setAudienceData(response.data.audiences || []); // Adjust according to your API response structure cmpaudience
//     //         setAudienceData(response.data.accounts.filter(user => user.pk_id.startsWith("cmpaudience#")));

//     //     } catch (error) {
//     //         console.error("Error fetching campaign audiences:", error);
//     //     }
//     // };

//     // // Call fetchCampaignNames when the component mounts
//     // useEffect(() => {
//     //     fetchCampaignNames();
//     // }, []);

//     // // Call fetchCampaignAudiences when the component mounts
//     // useEffect(() => {
//     //     fetchCampaignAudiences();
//     // }, []);



//     useEffect(() => {
//         fetch("    https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-name")
//             .then((response) => response.json())
//             .then((result) => {
//                 console.log("Fetched data:", result);
//                 setNameData(result.accounts || []);
//             })
//             .catch((error) => console.error("Error fetching data:", error));
//     }, []);




//     useEffect(() => {
//         fetch("    https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-audience")
//             .then((response) => response.json())
//             .then((result) => {
//                 console.log("Fetched data:", result);
//                 setAudienceData(result.accounts || []);
//             })
//             .catch((error) => console.error("Error fetching data:", error));
//     }, []);



//     //--------------------------------------------------------------------------------------------------------------------------------

//     // Delete apis.-----------------------------------------






//     const handleDeleteType = async (item) => {
//         // Show confirmation dialog
//         const confirmed = window.confirm(`Are you sure you want to delete Member Type ${item.membertype}?`);
//         if (!confirmed) {
//             return; // If the user cancels, exit the function
//         }

//         try {


//             // Send DELETE request
//             await axios.post(`  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/delete-campaign-types`, {
//                 pk_id: item.pk_id,
//                 sk_id: item.sk_id,
//             });

//             console.log("[DELETE_MEMBER_TYPE]: Member Type deleted successfully");

//             // Refresh data after deletion
//             setData((prevData) =>
//                 prevData.filter((dataItem) => dataItem.pk_id !== item.pk_id || dataItem.sk_id !== item.sk_id)
//             );

//         } catch (error) {
//             console.error("Error deleting type:", error);
//         }
//     };




//     const handleDeleteVenue = async (item) => {
//         // Show confirmation dialog
//         const confirmed = window.confirm(`Are you sure you want to delete Venue ${item.venuechines}?`);
//         if (!confirmed) {
//             return; // If the user cancels, exit the function
//         }

//         try {
//             // Send DELETE request
//             await axios.post(`  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/delete-campaign-venue`, {
//                 pk_id: item.pk_id,
//                 sk_id: item.sk_id,
//             });

//             console.log("[DELETE_VENUE]: Venue deleted successfully");

//             // Refresh data after deletion
//             setvenuedataData((prevData) =>
//                 prevData.filter((dataItem) => dataItem.pk_id !== item.pk_id || dataItem.sk_id !== item.sk_id)
//             );

//         } catch (error) {
//             console.error("Error deleting venue:", error);
//         }
//     };



//     const handleDeleteName = async (item) => {
//         // Show confirmation dialog
//         const confirmed = window.confirm(`Are you sure you want to delete Name ${item.campaignnamechines}?`);
//         if (!confirmed) {
//             return; // If the user cancels, exit the function
//         }

//         try {
//             // Send DELETE request
//             await axios.post(`  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/delete-campaign-name`, {
//                 pk_id: item.pk_id,
//                 sk_id: item.sk_id,
//             });

//             console.log("[DELETE_NAME]: Campaign name deleted successfully");


//             setNameData((prevData) =>
//                 prevData.filter((dataItem) => dataItem.pk_id !== item.pk_id || dataItem.sk_id !== item.sk_id)
//             );

//         } catch (error) {
//             console.error("Error deleting campaign name:", error);
//         }
//     };



//     const handleDeleteAudience = async (item) => {
//         // Show confirmation dialog
//         const confirmed = window.confirm(`Are you sure you want to delete Audience ${item.audienceChinese}?`);
//         if (!confirmed) {
//             return; // If the user cancels, exit the function
//         }

//         try {
//             // Send DELETE request
//             await axios.post(`  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/delete-campaign-audience`, {
//                 pk_id: item.pk_id,
//                 sk_id: item.sk_id,
//             });

//             console.log("[DELETE_AUDIENCE]: Audience deleted successfully");

//             // Refresh data after deletion
//             setAudienceData((prevData) =>
//                 prevData.filter((dataItem) => dataItem.pk_id !== item.pk_id || dataItem.sk_id !== item.sk_id)
//             );

//         } catch (error) {
//             console.error("Error deleting audience:", error);
//         }
//     };





//     //------------------------------------------------------Edit Apis.............................................................







//     // Render modal content dynamically based on the selected option
//     const renderModalContent = () => {
//         switch (selectedOption) {
//             case "campaignType":
//                 return (
//                     <>
//                         <p className="usercategoriesformhead">Add New Campaign Type</p>
//                         <Form.Group controlId="formInput1" className="floating-label-group">
//                             <Form.Control
//                                 type="text"
//                                 name="input1"
//                                 value={inputValues.input1}
//                                 onChange={handleChange}
//                                 className="floating-input"
//                             />
//                             <Form.Label className="floating-label">Type</Form.Label>
//                         </Form.Group>
//                         <Form.Group controlId="formInput2" className="floating-label-group mt-3">
//                             <Form.Control
//                                 type="text"
//                                 name="input2"
//                                 value={inputValues.input2}
//                                 onChange={handleChange}
//                                 className="floating-input"
//                             />
//                             <Form.Label className="floating-label">Description (Chinese)</Form.Label>
//                         </Form.Group>
//                         <Form.Group controlId="formInput3" className="floating-label-group mt-3">
//                             <Form.Control
//                                 type="text"
//                                 name="input3"
//                                 value={inputValues.input3}
//                                 onChange={handleChange}
//                                 className="floating-input"
//                             />
//                             <Form.Label className="floating-label">Description (English)</Form.Label>
//                         </Form.Group>
//                     </>
//                 );
//             case "campaignVenue":
//                 return (
//                     <>
//                         <p className="usercategoriesformhead">Edit Campaign Venue</p>
//                         <Form.Group controlId="formInput1" className="floating-label-group">
//                             <Form.Control
//                                 type="text"
//                                 name="input4"
//                                 value={inputValues.input4}
//                                 onChange={handleChange}
//                                 className="floating-input"
//                             />
//                             <Form.Label className="floating-label">In-Centre (YTNEC)</Form.Label>
//                         </Form.Group>
//                         <Form.Group controlId="formInput2" className="floating-label-group mt-3">
//                             <Form.Control
//                                 type="text"
//                                 name="input5"
//                                 value={inputValues.input5}
//                                 onChange={handleChange}
//                                 className="floating-input"
//                             />
//                             <Form.Label className="floating-label">Venue (Chinese)</Form.Label>
//                         </Form.Group>
//                         <Form.Group controlId="formInput3" className="floating-label-group mt-3">
//                             <Form.Control
//                                 type="text"
//                                 name="input6"
//                                 value={inputValues.input6}
//                                 onChange={handleChange}
//                                 className="floating-input"
//                             />
//                             <Form.Label className="floating-label">Venue (English)</Form.Label>
//                         </Form.Group>
//                     </>
//                 );
//             case "campaignName":
//                 return (
//                     <>
//                         <p className="usercategoriesformhead">Add New Campaign Name</p>
//                         <Form.Group controlId="formInput1" className="floating-label-group">
//                             <Form.Control
//                                 type="text"
//                                 name="input7"
//                                 value={inputValues.input7}
//                                 onChange={handleChange}
//                                 className="floating-input"
//                             />
//                             <Form.Label className="floating-label">Campaign Name (Chinese)</Form.Label>
//                         </Form.Group>
//                         <Form.Group controlId="formInput2" className="floating-label-group mt-3">
//                             <Form.Control
//                                 type="text"
//                                 name="input8"
//                                 value={inputValues.input8}
//                                 onChange={handleChange}
//                                 className="floating-input"
//                             />
//                             <Form.Label className="floating-label">Campaign Name (English)</Form.Label>
//                         </Form.Group>
//                     </>
//                 );
//             case "campaignAudience":
//                 return (
//                     <>
//                         <p className="usercategoriesformhead">Add New </p>
//                         <Form.Group controlId="formInput1" className="floating-label-group">
//                             <Form.Control
//                                 type="text"
//                                 name="input9"
//                                 value={inputValues.input9}
//                                 onChange={handleChange}
//                                 className="floating-input"
//                             />
//                             <Form.Label className="floating-label">Identity (Chinese)</Form.Label>
//                         </Form.Group>
//                         <Form.Group controlId="formInput2" className="floating-label-group mt-3">
//                             <Form.Control
//                                 type="text"
//                                 name="input10"
//                                 value={inputValues.input10}
//                                 onChange={handleChange}
//                                 className="floating-input"
//                             />
//                             <Form.Label className="floating-label">Identity (English)</Form.Label>
//                         </Form.Group>
//                         <Form.Group controlId="formInput3" className="floating-label-group mt-3">
//                             <Form.Control
//                                 type="text"
//                                 name="input11"
//                                 value={inputValues.input11}
//                                 onChange={handleChange}
//                                 className="floating-input"
//                             />
//                             <Form.Label className="floating-label">Code</Form.Label>
//                         </Form.Group>
//                     </>
//                 );
//             default:
//                 return <p>Please select a campaign management option.</p>;
//         }
//     };

//     return (
//         <div className="container mt-4 pt-3 ps-5">
//             <Row>
//                 <Col md={6}>
//                     <div className="mb-4">
//                         <p className="usercategorieshead">User-Defined Categories</p>
//                         <Form.Group controlId="campaignManagementForm" className="d-flex align-items-center">
//                             <Form.Label className="me-2 userlabel">Search:</Form.Label>
//                             <Form.Select
//                                 value={selectedOption}
//                                 onChange={handleSelectionChange}
//                                 className="custom-select me-2"
//                             >
//                                 <option value=""></option>
//                                 <option value="campaignType">Campaign Type Management</option>
//                                 <option value="campaignVenue">Campaign Venue Management</option>
//                                 <option value="campaignName">Campaign Name Management</option>
//                                 <option value="campaignAudience">Member Identity</option>
//                             </Form.Select>
//                             <Button onClick={handleModalShow} disabled={!selectedOption} className="useraddbtn">
//                                 Add
//                             </Button>
//                         </Form.Group>
//                     </div>
//                 </Col>
//             </Row>


//             <Row className="mt-4">
//                 {selectedOption === "campaignType" && (
//                     <Col md={6}>
//                         <Table striped hover>
//                             <thead>
//                                 <tr>
//                                     <th className="custom-th">Type</th>
//                                     <th className="custom-th">Description</th>
//                                     <th className="custom-th text-end pe-5">Function(s)</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {Array.isArray(data) && data.length > 0 ? (
//                                     data.map((item, index) => (
//                                         <tr key={index}>
//                                             <td className="custom-td">{item.membertype}</td>
//                                             <td className="custom-td">{item.descriptionChinese}</td>
//                                             <td className="custom-td text-end">
//                                                 <Button className="me-2 useraddbtnbtn" >Edit</Button>
//                                                 <Button className="userdelete" onClick={() => handleDeleteType(item)}>Delete</Button>
//                                             </td>
//                                         </tr>
//                                     ))

//                                 ) : (
//                                     <tr>
//                                         <td colSpan="3" className="text-center">
//                                             No data available
//                                         </td>
//                                     </tr>
//                                 )}
//                             </tbody>
//                         </Table>

//                     </Col>
//                 )}



//                 {selectedOption === "campaignVenue" && (
//                     <Col md={9}>
//                         <Table striped hover>
//                             <thead>
//                                 <tr>
//                                     <th className="custom-th">In-Centre (YTNEC)</th>
//                                     <th className="custom-th">Venue (Chinese)</th>
//                                     <th className="custom-th text-end">Function(s)</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {Array.isArray(venuedata) && venuedata.length > 0 ? (
//                                     venuedata.map((item, index) => (
//                                         <tr key={index}>
//                                             <td className="custom-td">{item.incentre}</td>
//                                             <td className="custom-td">{item.venuechines}</td>
//                                             <td className="custom-tdtd text-end">
//                                                 <Button className="me-2 useraddbtnbtn">Edit</Button>
//                                                 <Button className="userdelete" onClick={() => handleDeleteVenue(item)}>Delete</Button>
//                                             </td>
//                                         </tr>
//                                     ))
//                                 ) : (
//                                     <tr>
//                                         <td colSpan="3" className="text-center">
//                                             No data available
//                                         </td>
//                                     </tr>
//                                 )}
//                             </tbody>
//                         </Table>
//                     </Col>
//                 )}

//                 {selectedOption === "campaignName" && (
//                     <Col md={8}>
//                         <Table striped hover>
//                             <thead>
//                                 <tr>
//                                     <th className="custom-th">Campaign Name(Chinese)</th>
//                                     <th className="custom-th text-end pe-5">Function(s)</th>
//                                 </tr>
//                             </thead>


//                             <tbody>
//                                 {Array.isArray(namedata) && namedata.length > 0 ? (
//                                     namedata.map((item, index) => (
//                                         <tr key={index}>
//                                             <td className="custom-td">{item.campaignnamechines}</td>
//                                             <td className="custom-tdtd text-end">
//                                                 <Button className="me-2 useraddbtnbtn">Edit</Button>
//                                                 <Button className="userdelete" onClick={() => handleDeleteName(item)}>Delete</Button>
//                                             </td>
//                                         </tr>
//                                     ))
//                                 ) : (
//                                     <tr>
//                                         <td colSpan="3" className="text-center">
//                                             No data available
//                                         </td>
//                                     </tr>
//                                 )}
//                             </tbody>


//                         </Table>
//                     </Col>
//                 )}

//                 {selectedOption === "campaignAudience" && (
//                     <Col md={8}>
//                         <Table striped hover>
//                             <thead>
//                                 <tr>
//                                     <th className="custom-th">Identity (Chinese)</th>
//                                     <th className="custom-th">Identity (English)</th>
//                                     <th className="custom-th">Code</th>
//                                     <th className="custom-th">Function(s)</th>
//                                 </tr>
//                             </thead>




//                             <tbody>
//                                 {Array.isArray(audiencedata) && audiencedata.length > 0 ? (
//                                     audiencedata.map((item, index) => (
//                                         <tr key={index}>
//                                             <td className="custom-td">{item.audienceChinese}</td>
//                                             <td className="custom-td">{item.audienceEnglish}</td>
//                                             <td className="custom-td">{item.Code}</td>
//                                             <td className="custom-tdtd text-end">
//                                                 <Button className="me-2 useraddbtnbtn">Edit</Button>
//                                                 <Button className="userdelete" onClick={() => handleDeleteAudience(item)}>Delete</Button>

//                                             </td>
//                                         </tr>
//                                     ))
//                                 ) : (
//                                     <tr>
//                                         <td colSpan="3" className="text-center">
//                                             No data available
//                                         </td>
//                                     </tr>
//                                 )}
//                             </tbody>

//                         </Table>
//                     </Col>
//                 )}
//             </Row>




//             {/* Modal for Adding New Item */}
//             <Modal show={showModal} onHide={handleModalClose} centered>
//                 <Modal.Body>
//                     <Form>{renderModalContent()}</Form>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button onClick={handleModalClose} className="userformcansle">
//                         Cancel
//                     </Button>
//                     <Button onClick={handleFormSubmit} className="userformsave">
//                         Save
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// };

// export default UserCategories;















import React, { useState, useEffect } from "react";
import { Table, Button, Form, Modal, Row, Col } from "react-bootstrap";
import './categoriescss/UserCategories.css';
import { Campaign } from "@mui/icons-material";
import axios from 'axios';
import { TablePagination } from "@mui/material"; // Import TablePagination from MUI

const UserCategories = () => {
    const [selectedOption, setSelectedOption] = useState("");
    const [showModal, setShowModal] = useState(false);



    const [inputValues, setInputValues] = useState({
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        input10: "",
        input11: "",
    });

    // Event handler to update selected option
    const handleSelectionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    // Function to toggle the modal
    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => {
        setShowModal(false);
        resetForm();
    };

    // Handle input change for all input fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    };



    // Reset form fields
    const resetForm = () => {
        setInputValues({
            input1: "",
            input2: "",
            input3: "",
            input4: "",
            input5: "",
            input6: "",
            input7: "",
            input8: "",
            input9: "",
            input10: "",
            input11: "",
        });
    };

    //---------------------------------------------------------------------------------------------------------\\

    // Handle form submit post apis

    const handleFormSubmit = async () => {
        let endpoint = "";
        const payload = {
            membertype: inputValues.input1,
            descriptionChinese: inputValues.input2,
            descriptionEnglish: inputValues.input3,
            ////////////////////////////
            incentre: inputValues.input4,
            venuechines: inputValues.input5,
            venueenglish: inputValues.input6,
            //////////////////////////////////
            campaignnamechines: inputValues.input7,
            campaignnameeglish: inputValues.input8,

            //////////////////////////////////////

            audienceChinese: inputValues.input9,
            audienceEnglish: inputValues.input10,
            Code: inputValues.input11,
        };



        switch (selectedOption) {
            case "campaignType":
                endpoint = "https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add_campaign_type";
                break;
            case "campaignVenue":
                endpoint = "https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add_campaign_venues";
                break;
            case "campaignName":
                endpoint = "https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add_campaign_name";
                break;
            case "campaignAudience":
                endpoint = "https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add_campaign_audience";
                break;
            default:
                alert("Please select a valid option");
                return;
        }

        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload,),
            });

            if (response.ok) {
                alert("Data added successfully!");
                resetForm();
                handleModalClose();
            } else {
                alert("Failed to add data. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("An error occurred while submitting the form.");
        }
    };


    //---------------------------------------------------------------------------------------------------------\\

    // Get Apis--------------------------------------------------


    const [data, setData] = useState([]);

    // useEffect(() => {
    //     fetch("   https://e90vdycrra.execute-api.ap-east-1.amazonaws.com /dev/get-campaign-types")
    //         .then((response) => response.json())
    //         .then((result) => {
    //             console.log("Fetched data:", result);
    //             setData(result.accounts || []);
    //         })
    //         .catch((error) => console.error("Error fetching data:", error));
    // }, []);


    // const fetchCampaignTypes = async () => {
    //     try {
    //         const response = await axios.get("  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-types");
    //         console.log("Fetched data:", response.data); // Debug API response
    //         setData(response.data.campaignTypes || []); // Set data from the response
    //     } catch (error) {
    //         console.error("Error fetching campaign types:", error);
    //     }
    // };

    const fetchCampaignTypes = async () => {
        try {
            const response = await axios.get("https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-types");
            console.log("Fetched data:", response.data); // Debug API response

            // Parse the body since it's a JSON string
            const data = JSON.parse(response.data.body);
            setData(data || []); // Set data from the parsed response
        } catch (error) {
            console.error("Error fetching campaign types:", error);
        }
    };




    // Call fetchCampaignTypes when the component mounts
    useEffect(() => {
        fetchCampaignTypes();
    }, []);

    const [venuedata, setvenuedataData] = useState([]);



    // useEffect(() => {
    //     fetch("   https://e90vdycrra.execute-api.ap-east-1.amazonaws.com /dev/get-campaign-venues")
    //         .then((response) => response.json())
    //         .then((result) => {
    //             console.log("Fetched data:", result);
    //             setvenuedataData(result.accounts || []);
    //         })
    //         .catch((error) => console.error("Error fetching data:", error));
    // }, []);


    // const fetchCampaignVenues = async () => {
    //     try {
    //         const response = await axios.get("  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-venues");
    //         console.log("Fetched data:", response.data); // Debug API response
    //         setvenuedataData(response.data.venues || []); // Adjust according to your API response structure
    //     } catch (error) {
    //         console.error("Error fetching campaign venues:", error);
    //     }
    // };

    const fetchCampaignVenues = async () => {
        try {
            const response = await axios.get("https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-venues");
            console.log("Fetched data:", response.data); // Debug API response

            // Check if the response has a body and parse it
            const data = response.data.body ? JSON.parse(response.data.body) : [];

            // Set the parsed data
            setvenuedataData(data || []); // Assuming the parsed data is an array of venues
        } catch (error) {
            console.error("Error fetching campaign venues:", error);
        }
    };




    // Call fetchCampaignVenues when the component mounts
    useEffect(() => {
        fetchCampaignVenues();
    }, []);


    const [namedata, setNameData] = useState([]);
    const [audiencedata, setAudienceData] = useState([]);


    // const fetchCampaignNames = async () => {
    //     try {
    //         const response = await axios.get("https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-name");
    //         console.log("Fetched campaign names:", response.data); // Debug API response
    //         setNameData(response.data.names || []); // Adjust according to your API response structure
    //     } catch (error) {
    //         console.error("Error fetching campaign names:", error);
    //     }
    // };


    const fetchCampaignNames = async () => {
        try {
            const response = await axios.get("https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-name");
            console.log("Fetched campaign names:", response.data); // Debug API response

            // Parse the body to get the names data
            const nameData = JSON.parse(response.data.body);

            // Set the campaign names data
            setNameData(nameData || []); // Use the parsed names data
        } catch (error) {
            console.error("Error fetching campaign names:", error);
        }
    };



    // Fetch campaign audiences from the backend API
    // const fetchCampaignAudiences = async () => {
    //     try {
    //         const response = await axios.get("  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-audience");
    //         console.log("Fetched campaign audiences:", response.data); // Debug API response
    //         setAudienceData(response.data.audiences || []); // Adjust according to your API response structure
    //     } catch (error) {
    //         console.error("Error fetching campaign audiences:", error);
    //     }
    // };

    const fetchCampaignAudiences = async () => {
        try {
            const response = await axios.get("https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/get-campaign-audience");
            console.log("Fetched campaign audiences:", response.data); // Debug API response

            // Parse the body to get the audience data
            const audienceData = JSON.parse(response.data.body);

            // Set the audience data
            setAudienceData(audienceData || []); // Use the parsed audience data
        } catch (error) {
            console.error("Error fetching campaign audiences:", error);
        }
    };


    // Call fetchCampaignNames when the component mounts
    useEffect(() => {
        fetchCampaignNames();
    }, []);

    // Call fetchCampaignAudiences when the component mounts
    useEffect(() => {
        fetchCampaignAudiences();
    }, []);



    // useEffect(() => {
    //     fetch("   https://e90vdycrra.execute-api.ap-east-1.amazonaws.com /dev/get-campaign-name")
    //         .then((response) => response.json())
    //         .then((result) => {
    //             console.log("Fetched data:", result);
    //             setNameData(result.accounts || []);
    //         })
    //         .catch((error) => console.error("Error fetching data:", error));
    // }, []);




    // useEffect(() => {
    //     fetch("   https://e90vdycrra.execute-api.ap-east-1.amazonaws.com /dev/get-campaign-audience")
    //         .then((response) => response.json())
    //         .then((result) => {
    //             console.log("Fetched data:", result);
    //             setAudienceData(result.accounts || []);
    //         })
    //         .catch((error) => console.error("Error fetching data:", error));
    // }, []);



    //--------------------------------------------------------------------------------------------------------------------------------

    // Delete apis.-----------------------------------------






    const handleDeleteType = async (item) => {
        // Show confirmation dialog
        const confirmed = window.confirm(`Are you sure you want to delete Member Type ${item.membertype}?`);
        if (!confirmed) {
            return; // If the user cancels, exit the function
        }

        try {


            // Send DELETE request
            await axios.post(`  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/delete-campaign-types`, {
                pk_id: item.pk_id,
                sk_id: item.sk_id,
            });

            console.log("[DELETE_MEMBER_TYPE]: Member Type deleted successfully");

            // // Refresh data after deletion
            // setData((prevData) => 
            //     prevData.filter((dataItem) => dataItem.pk_id !== item.pk_id || dataItem.sk_id !== item.sk_id)
            // );
            fetchCampaignTypes()
        } catch (error) {
            console.error("Error deleting type:", error);
        }
    };




    const handleDeleteVenue = async (item) => {
        // Show confirmation dialog
        const confirmed = window.confirm(`Are you sure you want to delete Venue ${item.venuechines}?`);
        if (!confirmed) {
            return; // If the user cancels, exit the function
        }

        try {
            // Send DELETE request
            await axios.post(`  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/delete-campaign-venue`, {
                pk_id: item.pk_id,
                sk_id: item.sk_id,
            });

            console.log("[DELETE_VENUE]: Venue deleted successfully");

            // Refresh data after deletion
            // setvenuedataData((prevData) => 
            //     prevData.filter((dataItem) => dataItem.pk_id !== item.pk_id || dataItem.sk_id !== item.sk_id)
            // );
            fetchCampaignVenues();
        } catch (error) {
            console.error("Error deleting venue:", error);
        }
    };



    const handleDeleteName = async (item) => {
        // Show confirmation dialog
        const confirmed = window.confirm(`Are you sure you want to delete Name ${item.campaignnamechines}?`);
        if (!confirmed) {
            return; // If the user cancels, exit the function
        }

        try {
            // Send DELETE request
            await axios.post(`  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/delete-campaign-name`, {
                pk_id: item.pk_id,
                sk_id: item.sk_id,
            });

            console.log("[DELETE_NAME]: Campaign name deleted successfully");

            // Refresh data after deletion
            // setNameData((prevData) => 
            //     prevData.filter((dataItem) => dataItem.pk_id !== item.pk_id || dataItem.sk_id !== item.sk_id)
            // );
            fetchCampaignNames();
        } catch (error) {
            console.error("Error deleting campaign name:", error);
        }
    };



    const handleDeleteAudience = async (item) => {
        // Show confirmation dialog
        const confirmed = window.confirm(`Are you sure you want to delete Audience ${item.audienceChinese}?`);
        if (!confirmed) {
            return; // If the user cancels, exit the function
        }

        try {
            // Send DELETE request
            await axios.post(`https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/delete-campaign-audience`, {
                pk_id: item.pk_id,
                sk_id: item.sk_id,
            });

            console.log("[DELETE_AUDIENCE]: Audience deleted successfully");

            // Refresh data after deletion
            // setAudienceData((prevData) => 
            //     prevData.filter((dataItem) => dataItem.pk_id !== item.pk_id || dataItem.sk_id !== item.sk_id)
            // );
            fetchCampaignAudiences();
        } catch (error) {
            console.error("Error deleting audience:", error);
        }
    };





    //------------------------------------------------------Edit Apis.............................................................







    // Render modal content dynamically based on the selected option
    const renderModalContent = () => {
        switch (selectedOption) {
            case "campaignType":
                return (
                    <>
                        <p className="usercategoriesformhead">Add New Campaign Type</p>
                        <Form.Group controlId="formInput1" className="floating-label-group">
                            <Form.Control
                                type="text"
                                name="input1"
                                value={inputValues.input1}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Type</Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formInput2" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input2"
                                value={inputValues.input2}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Description (Chinese)</Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formInput3" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input3"
                                value={inputValues.input3}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Description (English)</Form.Label>
                        </Form.Group>
                    </>
                );
            case "campaignVenue":
                return (
                    <>
                        <p className="usercategoriesformhead">Edit Campaign Venue</p>
                        {/* <Form.Group controlId="formInput1" className="floating-label-group">
                            <Form.Control
                                type="text"
                                name="input4"
                                value={inputValues.input4}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">In-Centre (YTNEC)</Form.Label>
                        </Form.Group> */}
                        <Form.Group controlId="formInput1" className="floating-label-group">
                            <Form.Select
                                name="input4"
                                value={inputValues.input4}
                                onChange={handleChange}
                                className="floating-input"
                            >
                                <option value=""></option>
                                <option value="Y">Y</option>
                                <option value="N">N</option>
                            </Form.Select>
                            <Form.Label className="floating-label">In-Centre (YTNEC)</Form.Label>
                        </Form.Group>

                        <Form.Group controlId="formInput2" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input5"
                                value={inputValues.input5}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Venue (Chinese)</Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formInput3" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input6"
                                value={inputValues.input6}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Venue (English)</Form.Label>
                        </Form.Group>
                    </>
                );

            case "campaignName":
                return (
                    <>
                        <p className="usercategoriesformhead">Add New Campaign Name</p>
                        <Form.Group controlId="formInput1" className="floating-label-group">
                            <Form.Control
                                type="text"
                                name="input7"
                                value={inputValues.input7}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Campaign Name (Chinese)</Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formInput2" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input8"
                                value={inputValues.input8}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Campaign Name (English)</Form.Label>
                        </Form.Group>
                    </>
                );
            case "campaignAudience":
                return (
                    <>
                        <p className="usercategoriesformhead">Add New </p>
                        <Form.Group controlId="formInput1" className="floating-label-group">
                            <Form.Control
                                type="text"
                                name="input9"
                                value={inputValues.input9}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Identity (Chinese)</Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formInput2" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input10"
                                value={inputValues.input10}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Identity (English)</Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formInput3" className="floating-label-group mt-3">
                            <Form.Control
                                type="text"
                                name="input11"
                                value={inputValues.input11}
                                onChange={handleChange}
                                className="floating-input"
                            />
                            <Form.Label className="floating-label">Code</Form.Label>
                        </Form.Group>
                    </>
                );
            default:
                return <p>Please select a campaign management option.</p>;
        }
    };

    return (
        <div className="container mt-4 pt-3 ps-5">
            <Row>
                <Col md={6}>
                    <div className="mb-4">
                        <p className="usercategorieshead">User-Defined Categories</p>
                        <Form.Group controlId="campaignManagementForm" className="d-flex align-items-center">
                            <Form.Label className="me-2 userlabel">Search:</Form.Label>
                            <Form.Select
                                value={selectedOption}
                                onChange={handleSelectionChange}
                                className="custom-select me-2"
                            >
                                <option value=""></option>
                                <option value="campaignType">Campaign Type Management</option>
                                <option value="campaignVenue">Campaign Venue Management</option>
                                <option value="campaignName">Campaign Name Management</option>
                                <option value="campaignAudience">Member Identity</option>
                            </Form.Select>
                            <Button onClick={handleModalShow} disabled={!selectedOption} className="useraddbtn">
                                Add
                            </Button>
                        </Form.Group>
                    </div>
                </Col>
            </Row>

            <Row className="mt-4">
                {selectedOption === "campaignType" && (
                    <Col md={6}>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th className="custom-th">Type</th>
                                    <th className="custom-th">Description</th>
                                    <th className="custom-th text-end pe-5">Function(s)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(data) && data.length > 0 ? (
                                    data.map((item, index) => (
                                        <tr key={index}>
                                            <td className="custom-td">{item.membertype}</td>
                                            <td className="custom-td">{item.descriptionChinese}</td>
                                            <td className="custom-td text-end">
                                                <Button className="me-2 useraddbtnbtn" >Edit</Button>
                                                <Button className="userdelete" onClick={() => handleDeleteType(item)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))

                                ) : (
                                    <tr>
                                        <td colSpan="3" className="text-center">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                    </Col>
                )}



                {/* {selectedOption === "campaignVenue" && (
                    <Col md={9}>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th className="custom-th">In-Centre (YTNEC)</th>
                                    <th className="custom-th">Venue (Chinese)</th>
                                    <th className="custom-th text-end">Function(s)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(venuedata) && venuedata.length > 0 ? (
                                    venuedata.map((item, index) => (
                                        <tr key={index}>
                                            <td className="custom-td">{item.incentre}</td>
                                            <td className="custom-td">{item.venuechines}</td>
                                            <td className="custom-tdtd text-end">
                                                <Button className="me-2 useraddbtnbtn">Edit</Button>
                                                <Button className="userdelete" onClick={() => handleDeleteVenue(item)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="text-center">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                )} */}


                {selectedOption === "campaignVenue" && (
                    <Col md={9}>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th className="custom-th">In-Centre (YTNEC)</th>
                                    <th className="custom-th">Venue (Chinese)</th>
                                    <th className="custom-th text-end">Function(s)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(venuedata) && venuedata.length > 0 ? (
                                    venuedata.map((item, index) => (
                                        <tr key={index}>
                                            <td className="custom-td">{item.incentre}</td>
                                            <td className="custom-td">{item.venuechines}</td>
                                            <td className="custom-td text-end">
                                                <Button className="me-2 useraddbtnbtn">Edit</Button>
                                                <Button className="userdelete" onClick={() => handleDeleteVenue(item)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="text-center">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                )}


                {selectedOption === "campaignName" && (
                    <Col md={8}>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th className="custom-th">Campaign Name(Chinese)</th>
                                    <th className="custom-th text-end pe-5">Function(s)</th>
                                </tr>
                            </thead>

                            <tbody>
                                {Array.isArray(namedata) && namedata.length > 0 ? (
                                    namedata.map((item, index) => (
                                        <tr key={index}>
                                            <td className="custom-td">{item.campaignnamechines}</td>
                                            <td className="custom-td text-end">
                                                <Button className="me-2 useraddbtnbtn">Edit</Button>
                                                <Button className="userdelete" onClick={() => handleDeleteName(item)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="2" className="text-center">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>



                        </Table>
                    </Col>
                )}

                {/* {selectedOption === "campaignName" && (
                    <Col md={8}>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th className="custom-th">Campaign Name (Chinese)</th>
                                    <th className="custom-th text-end pe-5">Function(s)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(namedata) && namedata.length > 0 ? (
                                    namedata.map((item, index) => (
                                        <tr key={index}>
                                            <td className="custom-td">{item.campaignnamechines}</td>
                                            <td className="custom-tdtd text-end">
                                                <Button className="me-2 useraddbtnbtn">Edit</Button>
                                                <Button className="userdelete" onClick={() => handleDeleteName(item)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="2" className="text-center">No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                )} */}

                {selectedOption === "campaignAudience" && (
                    <Col md={8}>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th className="custom-th">Identity (Chinese)</th>
                                    <th className="custom-th">Identity (English)</th>
                                    <th className="custom-th">Code</th>
                                    <th className="custom-th">Function(s)</th>
                                </tr>
                            </thead>




                            <tbody>
                                {Array.isArray(audiencedata) && audiencedata.length > 0 ? (
                                    audiencedata.map((item, index) => (
                                        <tr key={index}>
                                            <td className="custom-td">{item.audienceChinese}</td>
                                            <td className="custom-td">{item.audienceEnglish}</td>
                                            <td className="custom-td">{item.Code}</td>
                                            <td className="custom-tdtd text-end">
                                                <Button className="me-2 useraddbtnbtn">Edit</Button>
                                                <Button className="userdelete" onClick={() => handleDeleteAudience(item)}>Delete</Button>

                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                        </Table>
                    </Col>
                )}
            </Row>




            {/* Modal for Adding New Item */}
            <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Body>
                    <Form>{renderModalContent()}</Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleModalClose} className="userformcansle">
                        Cancel
                    </Button>
                    <Button onClick={handleFormSubmit} className="userformsave">
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UserCategories;
