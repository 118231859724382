import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    basicInformation: {
        mem_name: "",
        mem_dob: "",
        mem_gender: "",
        mem_phone_number: "",
        mem_email: "",
        mem_prefered_language: "",
        member_id: "",
        mem_card_reference: "",
        mem_created_date: "",
        mem_age: "",
    },
};

const basicInfoSlice = createSlice({
    name: 'basicInfo',
    initialState,
    reducers: {
        setBasicInformation: (state, action) => {
            state.basicInformation = action.payload;
        },
    },
});

export const { setBasicInformation } = basicInfoSlice.actions;
// export default basicInfoSlice.reducer;




export const userReducers = basicInfoSlice.reducer;