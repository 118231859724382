



// import React, { useState } from 'react';
// import { Table, Form, Button, Container, Row, Col } from 'react-bootstrap';
// import TablePagination from '@mui/material/TablePagination'; // Import TablePagination
// import './CampaignsPage.css';
// import { useNavigate } from "react-router-dom";

// const CampaignsPage = () => {
//   const navigate = useNavigate();

//   const handleAddMember = () => {
//     navigate("/campaigns/mainpage");
//   };

//   // State for pagination
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5); // Default rows per page

//   // Sample data for campaigns
//   const campaigns = [
//     { name: "Cantonese Opera Class", type: "E", code: "240703", status: "Approval", sis: "Y", updatedBy: "Amy Lee", startDate: "19/07/2024", approvedBy: "Ling", lastUpdated: "01/07/2024 09:00" },
//     // Add more campaign objects here as needed
//   ];

//   // Calculate total pages
//   const totalCampaigns = campaigns.length;

//   // Handlers for pagination
//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0); // Reset to the first page on changing rows per page
//   };

//   // Get the current campaigns for the current page
//   const currentCampaigns = campaigns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//   return (
//     <Container>
//       <Form className="mb-3 p-3 border rounded shadow-custom" style={{ marginTop: '30px' }}>
//         <Row>
//           <Col>
//             <div className="d-flex flex-wrap">
//               <div className="d-flex flex-grow-1 align-items-center me-3">
//                 <Form.Label className="me-2 mb-0" style={{ whiteSpace: 'nowrap' }}>Campaign Name:</Form.Label>
//                 <Form.Control type="text" placeholder="" />
//               </div>
//               <div className="d-flex flex-grow-1 align-items-center me-3">
//                 <Form.Label className="me-2 mb-0" style={{ whiteSpace: 'nowrap' }}>Type:</Form.Label>
//                 <Form.Control as="select">
//                   <option></option>
//                 </Form.Control>
//               </div>
//               <div className="d-flex flex-grow-1 align-items-center me-3">
//                 <Form.Label className="me-2 mb-0" style={{ whiteSpace: 'nowrap' }}>Status:</Form.Label>
//                 <Form.Control as="select">
//                   <option></option>
//                 </Form.Control>
//               </div>
//               <div className="d-flex flex-grow-1 align-items-center me-3">
//                 <Form.Label className="me-2 mb-0" style={{ whiteSpace: 'nowrap' }}>SIS:</Form.Label>
//                 <Form.Control as="select">
//                   <option></option>
//                 </Form.Control>
//               </div>
//               <div className="d-flex flex-grow-1 align-items-center me-3">
//                 <Form.Label className="me-2 mb-0" style={{ whiteSpace: 'nowrap' }}>Start Date:</Form.Label>
//                 <Form.Control type="date" />
//               </div>
//               <div className="d-flex flex-grow-1 align-items-center">
//                 <Form.Label className="me-2 mb-0" style={{ whiteSpace: 'nowrap' }}>Approved by:</Form.Label>
//                 <Form.Control as="select">
//                   <option></option>
//                 </Form.Control>
//               </div>
//             </div>
//           </Col>
//         </Row>
//         <Row className="mt-3">
//           <Col>
//             <Button variant="success" className="custom-success-btn me-2">Search</Button>
//             <Button variant="success" className="custom-success-btn me-2">Reset</Button>
//           </Col>
//           <Col className='text-end'>
//             <Button onClick={handleAddMember} variant="success" className="custom-success-btn me-2" style={{ width: '178px' }}>Create New Campaign</Button>
//           </Col>
//         </Row>
//       </Form>

//       <hr />

//       <Table striped bordered hover className="mt-4">
//         <thead>
//           <tr>
//             <th>Campaign Name</th>
//             <th>Type</th>
//             <th>Code</th>
//             <th>Status</th>
//             <th>SIS</th>
//             <th>Updated by</th>
//             <th>Start Date</th>
//             <th>Approved By</th>
//             <th>Last Updated / Approve</th>
//             <th>Function(s)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {currentCampaigns.map((campaign, index) => (
//             <tr key={index}>
//               <td>{campaign.name}</td>
//               <td>{campaign.type}</td>
//               <td>{campaign.code}</td>
//               <td>{campaign.status}</td>
//               <td>{campaign.sis}</td>
//               <td>{campaign.updatedBy}</td>
//               <td>{campaign.startDate}</td>
//               <td>{campaign.approvedBy}</td>
//               <td>{campaign.lastUpdated}</td>
//               <td>
//                 <Button variant="success" className="custom-success-btn me-2">Details</Button>
//                 <Button variant="danger" className="custom-danger-btn">Delete</Button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* TablePagination Component */}
//       <TablePagination
//         component="div"
//         count={totalCampaigns}
//         page={page}
//         onPageChange={handleChangePage}
//         rowsPerPage={rowsPerPage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//         rowsPerPageOptions={[5, 10, 25]}
//         className='mt-5'
//       />
//     </Container>
//   );
// };

// export default CampaignsPage;






import React, { useState } from 'react';
import { Table, Form, Button, Container, Row, Col } from 'react-bootstrap';
import './CampaignsPage.css';
import { useNavigate } from "react-router-dom";

const CampaignsPage = () => {
  const navigate = useNavigate();

  const handleAddMember = () => {
    navigate("/campaigns/mainpage");
  };

  // State for pagination
  const [page, setPage] = useState(0);
  const rowsPerPage = 5; // Default rows per page

  // Sample data for campaigns
  const campaigns = [
    { name: "Cantonese Opera Class", type: "E", code: "240703", status: "Approval", sis: "Y", updatedBy: "Amy Lee", startDate: "19/07/2024", approvedBy: "Ling", lastUpdated: "01/07/2024 09:00" },
    // Add more campaign objects here as needed
  ];

  // Calculate total pages
  const totalCampaigns = campaigns.length;
  const totalPages = Math.ceil(totalCampaigns / rowsPerPage);

  // Get the current campaigns for the current page
  const currentCampaigns = campaigns.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  // Handlers for pagination
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <Container>
      <Form className="mb-3 p-3 border rounded shadow-custom" style={{ marginTop: '30px' }}>
        <Row>
          <Col>
            <div className="d-flex flex-wrap">
              <div className="d-flex flex-grow-1 align-items-center me-3">
                <Form.Label className="me-2 mb-0" style={{ whiteSpace: 'nowrap' }}>Campaign Name:</Form.Label>
                <Form.Control type="text" placeholder="" />
              </div>
              <div className="d-flex flex-grow-1 align-items-center me-3">
                <Form.Label className="me-2 mb-0" style={{ whiteSpace: 'nowrap' }}>Type:</Form.Label>
                <Form.Control as="select">
                  <option></option>
                </Form.Control>
              </div>
              <div className="d-flex flex-grow-1 align-items-center me-3">
                <Form.Label className="me-2 mb-0" style={{ whiteSpace: 'nowrap' }}>Status:</Form.Label>
                <Form.Control as="select">
                  <option></option>
                </Form.Control>
              </div>
              <div className="d-flex flex-grow-1 align-items-center me-3">
                <Form.Label className="me-2 mb-0" style={{ whiteSpace: 'nowrap' }}>SIS:</Form.Label>
                <Form.Control as="select">
                  <option></option>
                </Form.Control>
              </div>
              <div className="d-flex flex-grow-1 align-items-center me-3">
                <Form.Label className="me-2 mb-0" style={{ whiteSpace: 'nowrap' }}>Start Date:</Form.Label>
                <Form.Control type="date" />
              </div>
              <div className="d-flex flex-grow-1 align-items-center">
                <Form.Label className="me-2 mb-0" style={{ whiteSpace: 'nowrap' }}>Approved by:</Form.Label>
                <Form.Control as="select">
                  <option></option>
                </Form.Control>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button variant="success" className="custom-success-btn me-2">Search</Button>
            <Button variant="success" className="custom-success-btn me-2">Reset</Button>
          </Col>
          <Col className='text-end'>
            <Button onClick={handleAddMember} variant="success" className="custom-success-btn me-2" style={{ width: '178px' }}>Create New Campaign</Button>
          </Col>
        </Row>
      </Form>

      <hr />

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Campaign Name</th>
            <th>Type</th>
            <th>Code</th>
            <th>Status</th>
            <th>SIS</th>
            <th>Updated by</th>
            <th>Start Date</th>
            <th>Approved By</th>
            <th>Last Updated / Approve</th>
            <th>Function(s)</th>
          </tr>
        </thead>
        <tbody>
          {currentCampaigns.map((campaign, index) => (
            <tr key={index}>
              <td>{campaign.name}</td>
              <td>{campaign.type}</td>
              <td>{campaign.code}</td>
              <td>{campaign.status}</td>
              <td>{campaign.sis}</td>
              <td>{campaign.updatedBy}</td>
              <td>{campaign.startDate}</td>
              <td>{campaign.approvedBy}</td>
              <td>{campaign.lastUpdated}</td>
              <td>
                <Button variant="success" className="custom-success-btn me-2">Details</Button>
                <Button variant="danger" className="custom-danger-btn">Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <nav aria-label="Page navigation example" className='mt-5'>
        <ul className="pagination justify-content-end">
          <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
            <a
              className="page-link"
              href="#"
              onClick={() => handlePageChange(page - 1)}
              tabIndex="-1"
              aria-disabled={page === 0}
            >
              Previous
            </a>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index} className={`page-item ${page === index ? 'active' : ''}`}>
              <a
                className="page-link"
                href="#"
                onClick={() => handlePageChange(index)}
              >
                {index + 1}
              </a>
            </li>
          ))}
          <li className={`page-item ${page === totalPages - 1 ? 'disabled' : ''}`}>
            <a
              className="page-link"
              href="#"
              onClick={() => handlePageChange(page + 1)}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    </Container>
  );
};

export default CampaignsPage;
