// import React, { useState } from 'react';
// import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
// import { FaCalendarAlt } from 'react-icons/fa';
// import { Box } from "@mui/material";
// import Breadcrumb from "app/components/Breadcrumb";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './notificationcss/NotificationForm.css'
// import { XCircle } from 'react-bootstrap-icons';
// const NotificationForm = () => {
//   const [schedule, setSchedule] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [previewUrl, setPreviewUrl] = useState(null);
//   const handleShow = () => setShowModal(true);
//   const handleClose = () => setShowModal(false);

//   const [startDate, setStartDate] = useState(null);
//   const [show, setShow] = useState(false);

//   const handleShowDate = () => setShow(true);
//   const handleCloseDate = () => setShow(false);
//   return (
//     <div className="container mt-4">
//       <Box className="breadcrumb">
//         <Breadcrumb routeSegments={[{ name: "Search" }, { name: "Push Notifications" }]} />
//       </Box>

//       <Form>

//         <Row className="mb-4">
//           <Col className="d-flex justify-content-center">
//             <Button variant="primary" onClick={handleShow} className='addphotobtn'>
//               Add Photo/Video
//             </Button>
//           </Col>
//         </Row>

//         {/* Titles and Subtitles Section */}
//         <Row className="mb-3 ms-4">
//           <Col md={6}>
//             <Form.Group controlId="formPassword" className="floating-label-group">
//               <Form.Control
//                 type="text"
//                 className="floating-input"
//               />
//               <Form.Label className="floating-label">Title (Chinese)</Form.Label>
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formPassword" className="floating-label-group">
//               <Form.Control
//                 type="text"
//                 className="floating-input"
//               />
//               <Form.Label className="floating-label">Title (English)</Form.Label>
//             </Form.Group>
//           </Col>
//         </Row>

//         <Row className="mb-3 ms-4">
//           <Col md={6}>
//             <Form.Group controlId="formPassword" className="floating-label-group">
//               <Form.Control
//                 type="text"
//                 className="floating-input"
//               />
//               <Form.Label className="floating-label">Subtitle (Chinese)</Form.Label>
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formPassword" className="floating-label-group">
//               <Form.Control
//                 type="text"
//                 className="floating-input"
//               />
//               <Form.Label className="floating-label">Subtitle (English)</Form.Label>
//             </Form.Group>
//           </Col>
//         </Row>

//         {/* Message Content Section */}
//         <Row className="mb-4 ms-4">
//           <Col md={6}>
//             {/* Chinese Message Content */}
//             <Form.Group controlId="formMessageChinese" className="floating-label-group">
//               <Form.Control
//                 as="textarea"
//                 rows={3}
//                 className="floating-input"
//                 style={{ height: '100px' }} // Inline style to force the height
//               />
//               <Form.Label className="floating-label">Message Content (Chinese)</Form.Label>
//             </Form.Group>
//           </Col>

//           <Col md={6}>
//             {/* English Message Content */}
//             <Form.Group controlId="formMessageEnglish" className="floating-label-group">
//               <Form.Control
//                 as="textarea"
//                 rows={3}
//                 className="floating-input"
//                 style={{ height: '100px' }} // Inline style to force the height
//               />
//               <Form.Label className="floating-label">Message Content (English)</Form.Label>
//             </Form.Group>
//           </Col>
//         </Row>

//         {/* Send Date & Time Section */}

//         <Row className="mb-5 ms-4">
//           <Col md={5}>
//             <h5 className='notificationdatetime'>Send Date & Time</h5>

//             {/* Preview, Login Name, and Test Button (Side by Side) */}
//             <Row className="align-items-center mb-2 mt-3">
//               <Col md="auto">
//                 <Form.Check
//                   type="radio"
//                   label="Preview"
//                   name="sendTimeOptions"
//                   className="mb-0 previewcheck"
//                 />

//               </Col>
//               <Col md={5}>
//                 <Form.Control type="text" placeholder="Login Name" className=' ms-3 datetimelogin' />
//               </Col>
//               <Col md="auto">
//                 <Button variant="" className='ms-4 datetimebtn'>Test</Button>
//               </Col>
//             </Row>

//             {/* Schedule At and Now (Side by Side) */}
//             <Row className="align-items-center">
//               <Col md="auto">
//                 <Form.Check
//                   type="radio"
//                   label="Schedule at"
//                   name="sendTimeOptions"
//                   className="mb-0 previewcheck"
//                 />
//               </Col>
//               <Col md={4}>
//                 <Form.Control type="date" placeholder="Select Date" onClick={handleShowDate} className='datetimelogin' />
//               </Col>
//               <Col md="auto">
//                 <Form.Check
//                   type="radio"
//                   label="Now"
//                   name="sendTimeOptions"
//                   className="mb-0  ms-5 previewcheck"
//                 />
//               </Col>
//             </Row>
//           </Col>
//         </Row>

//         {/* Target Audience Section */}
//         <Row className="mb-3 ms-4">
//           <Col md={5}>
//             <h5 className='targethead'>Target Audience</h5>
//             {/* All App Users Option */}
//             <Form.Check
//               type="radio"
//               label="All App Users"
//               name="audienceOptions"
//               className="mb-2 mt-4 previewcheck"
//             />

//             {/* Specific Users Option */}
//             <Form.Check
//               type="radio"
//               label="Specific Users"
//               name="audienceOptions"
//               className="mb-2 previewcheck"
//             />

//             {/* Membership Level */}
//             <Form.Group controlId="formRole" className="floating-label-group mt-4">
//               <Form.Select

//                 className="floating-input"
//               >
//                 <option value=""></option>
//                 <option value="Free">Free</option>
//                 <option value="Paid">Paid</option>

//               </Form.Select>
//               <Form.Label className="floating-label " >Membership Level</Form.Label>
//             </Form.Group>

//             {/* Membership Role */}

//             <Form.Group controlId="formRole" className="floating-label-group">
//               <Form.Select

//                 className="floating-input"
//               >
//                 <option value=""></option>
//                 <option value="Free">Normal</option>
//                 <option value="Paid">Instructor</option>

//               </Form.Select>
//               <Form.Label className="floating-label">Membership Role</Form.Label>
//             </Form.Group>

//             {/* Age Group */}
//             <Form.Group controlId="formRole" className="floating-label-group">
//               <Form.Select

//                 className="floating-input"
//               >
//                 <option value=""></option>
//                 <option value="Senior">Senior</option>
//                 <option value="PreSenior">Pre-Senior</option>
//                 <option value="Other">Other</option>


//               </Form.Select>
//               <Form.Label className="floating-label">Age Group</Form.Label>
//             </Form.Group>

//             {/* Member Type */}
//             <Form.Group controlId="formRole" className="floating-label-group">
//               <Form.Select

//                 className="floating-input"
//               >
//                 <option value=""></option>
//                 <option value="Valunteer">Valunteer</option>
//                 <option value="Caregiver">Caregiver</option>
//               </Form.Select>
//               <Form.Label className="floating-label">Membership Type</Form.Label>
//             </Form.Group>
//           </Col>
//         </Row>

//         {/* Buttons Section */}
//         <Row className="mb-3 ms-4 mt-4">
//           <Col md={4} className="d-flex justify-content-between">
//             <Button variant="success" className='notificationsave'>Save</Button>
//             <Button variant="danger" className=' notificationdelete'>Delete</Button>
//             <Button variant="primary" className='notificationconf'>Confirm</Button>
//             <Button variant="secondary" className='ps-2 notificationdelete'>Withdraw</Button>
//           </Col>
//         </Row>




//       </Form>

//       {/* <Modal show={showModal} onHide={handleClose} centered >
//         <Modal.Header closeButton>
//           <Modal.Title>Add Instructor Photo</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="text-center mb-3">
//             <div className="border p-3">
//               <p>Ratio limit 79:82, format PNG/JPG, e.g. 395(W) * 410(H)</p>
//               <Button variant="warning">Select</Button>
//             </div>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Cancel
//           </Button>
//           <Button variant="primary" onClick={handleClose}>
//             Save
//           </Button>
//         </Modal.Footer>
//       </Modal> */}




//       {/* Modal for Calendar */}
//       <Modal show={show} onHide={handleCloseDate} centered size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>Send Date & Time</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {/* Calendar */}
//           <div className="d-flex justify-content-center">
//             <DatePicker
//               selected={startDate}
//               onChange={(date) => setStartDate(date)}
//               showTimeSelect
//               dateFormat="MMMM d, yyyy h:mm aa"
//               inline
//               monthsShown={3}
//             />
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseDate}>
//             Cancel
//           </Button>
//           <Button variant="primary" onClick={handleCloseDate}>
//             Save
//           </Button>
//         </Modal.Footer>
//       </Modal>


//       <Modal show={showModal} onHide={handleClose} centered size="lg" className="custom-modal">
//         <Modal.Body style={{ height: '360px' }}>
//           <h4 className="maintitle">Add Instructor Photo</h4>
//           <div style={{ textAlign: 'center', height: '156px', padding: '40px', margin: '20px', border: '1px solid #D9D9D9' }}>
//             <p>Ratio limit 79:82,format PNG/JPG, e.g. 395(W) * 410(H)</p>
//             <Form.Group controlId="formFile">
//               <input
//                 type="file"
//                 id="file-input"
//                 accept="image/*,video/*"

//                 style={{ display: 'none' }}
//               />
//               <Button onClick={() => document.getElementById('file-input').click()}>Select</Button>
//             </Form.Group>
//           </div>

//           {/* Preview Image or Video */}
//           {previewUrl && (
//             <div style={{ textAlign: 'left', marginTop: '20px' }}>
//               {selectedFile.type.startsWith('image/') ? (
//                 <img src={previewUrl} alt="Preview" style={{ width: '375px', height: '221px' }} />
//               ) : (
//                 <video width="375" height="221" controls>
//                   <source src={previewUrl} />
//                 </video>
//               )}
//               <XCircle
//                 style={{ cursor: 'pointer', color: 'red', marginTop: '10px' }}
//                 size={24}

//               />
//             </div>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Cancel
//           </Button>
//           <Button variant="primary" onClick={handleClose} disabled={!selectedFile}>
//             Save
//           </Button>
//         </Modal.Footer>
//       </Modal>

//     </div>
//   );
// };

// export default NotificationForm;






import React, { useState } from 'react';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import { FaCalendarAlt } from 'react-icons/fa';
import { Box } from "@mui/material";
import Breadcrumb from "app/components/Breadcrumb";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './notificationcss/NotificationForm.css'
import { XCircle } from 'react-bootstrap-icons';
import axios from 'axios';  // Import axios for API calls

const NotificationForm = () => {
  const [schedule, setSchedule] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    titleChinese: '',
    titleEnglish: '',
    subtitleChinese: '',
    subtitleEnglish: '',
    messageChinese: '',
    messageEnglish: '',
    sendDate: null,
    audienceType: '',
    membershipLevel: '',
    membershipRole: '',
    ageGroup: '',
    membershipType: ''
  });

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleShowDate = () => setShow(true);
  const handleCloseDate = () => setShow(false);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      const response = await axios.post('  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add-notification', {
        ...formData,
        sendDate: startDate
      });

      // Check if the response is successful
      if (response.status === 200) {
        alert("Notification details added successfully.");
        // Clear form or redirect if necessary
        setFormData({
          titleChinese: '',
          titleEnglish: '',
          subtitleChinese: '',
          subtitleEnglish: '',
          messageChinese: '',
          messageEnglish: '',
          sendDate: null,
          audienceType: '',
          membershipLevel: '',
          membershipRole: '',
          ageGroup: '',
          membershipType: ''
        }); // Clear form data if you need to reset the form
        // Optionally, you can redirect or perform other actions here
      }
    } catch (err) {
      console.error("Error submitting form data", err);
      alert("Failed to add notification details.");
    }
  };

  return (
    <div className="container mt-4">
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "Search" }, { name: "Push Notifications" }]} />
      </Box>

      <Form onSubmit={handleSubmit}>

        <Row className="mb-4">
          <Col className="d-flex justify-content-center">
            <Button variant="primary" onClick={handleShow} className='addphotobtn'>
              Add Photo/Video
            </Button>
          </Col>
        </Row>
        {/* Titles and Subtitles Section */}
        <Row className="mb-3 ms-4">
          <Col md={6}>
            <Form.Group controlId="titleChinese" className="floating-label-group">
              <Form.Control
                type="text"
                className="floating-input"
                name="titleChinese"
                value={formData.titleChinese}
                onChange={handleInputChange}
                required  // Required field
              />
              <Form.Label className="floating-label">Title (Chinese)</Form.Label>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="titleEnglish" className="floating-label-group">
              <Form.Control
                type="text"
                className="floating-input"
                name="titleEnglish"
                value={formData.titleEnglish}
                onChange={handleInputChange}
                required  // Required field
              />
              <Form.Label className="floating-label">Title (English)</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3 ms-4">
          <Col md={6}>
            <Form.Group controlId="subtitleChinese" className="floating-label-group">
              <Form.Control
                type="text"
                className="floating-input"
                name="subtitleChinese"
                value={formData.subtitleChinese}
                onChange={handleInputChange}
                required  // Required field
              />
              <Form.Label className="floating-label">Subtitle (Chinese)</Form.Label>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="subtitleEnglish" className="floating-label-group">
              <Form.Control
                type="text"
                className="floating-input"
                name="subtitleEnglish"
                value={formData.subtitleEnglish}
                onChange={handleInputChange}
                required  // Required field
              />
              <Form.Label className="floating-label">Subtitle (English)</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        {/* Message Content Section */}
        <Row className="mb-4 ms-4">
          <Col md={6}>
            <Form.Group controlId="messageChinese" className="floating-label-group">
              <Form.Control
                as="textarea"
                rows={3}
                className="floating-input"
                name="messageChinese"
                value={formData.messageChinese}
                onChange={handleInputChange}
                style={{ height: '100px' }}
                required  // Required field
              />
              <Form.Label className="floating-label">Message Content (Chinese)</Form.Label>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="messageEnglish" className="floating-label-group">
              <Form.Control
                as="textarea"
                rows={3}
                className="floating-input"
                name="messageEnglish"
                value={formData.messageEnglish}
                onChange={handleInputChange}
                style={{ height: '100px' }}
                required  // Required field
              />
              <Form.Label className="floating-label">Message Content (English)</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        {/* Send Date & Time Section */}
        <Row className="mb-5 ms-4">
          <Col md={5}>
            <h5 className='notificationdatetime'>Send Date & Time</h5>
            <Row className="mt-4 align-items-center">
              <Col md="auto">
                <Form.Check
                  type="radio"
                  label="Preview"
                  name="sendTimeOptions"
                  className="mb-0 previewcheck"
                  onClick={handleShowDate}
                  required  // Required field
                />
              </Col>
              <Col md={4} className='ms-4'>
                <Form.Control
                  type="text"
                  placeholder="Login Name"
                  onClick={handleShowDate}
                  className='datetimelogin'
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}  // Update startDate
                  required  // Required field
                />
              </Col>
              <Col md="auto">
                <Button variant="" className="ms-5 testbtn" >
                  Test
                </Button>
              </Col>
            </Row>

            <Row className="align-items-center mt-4">
              <Col md="auto">
                <Form.Check
                  type="radio"
                  label="Schedule at"
                  name="sendTimeOptions"
                  className="mb-0 previewcheck"
                  onClick={handleShowDate}
                  required  // Required field
                />
              </Col>
              <Col md={4}>
                <Form.Control
                  type="date"
                  placeholder="Select Date"
                  onClick={handleShowDate}
                  className='datetimelogin'
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}  // Update startDate
                  required  // Required field
                />
              </Col>
              <Col md="auto" className='ms-5'>
                <Form.Check
                  type="radio"
                  label="Now"
                  name="sendTimeOptions"
                  className="mb-0 ms-5 previewcheck"
                  onClick={() => setStartDate(new Date())}
                  required  // Required field
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Target Audience Section */}
        <Row className="mb-3 ms-4">
          <Col md={5}>
            <h5 className='targethead'>Target Audience</h5>
            <Form.Check
              type="radio"
              label="All App Users"
              name="audienceOptions"
              className="mb-2 mt-4 previewcheck"
              value="All"
              onChange={handleInputChange}
              name="audienceType"
              required  // Required field
            />
            <Form.Check
              type="radio"
              label="Specific Users"
              name="audienceOptions"
              className="mb-2 previewcheck"
              value="Specific"
              onChange={handleInputChange}
              name="audienceType"
              required  // Required field
            />

            <Form.Group controlId="membershipLevel" className="floating-label-group mt-4">
              <Form.Select
                name="membershipLevel"
                value={formData.membershipLevel}
                onChange={handleInputChange}
                className="floating-input"
                required  // Required field
              >
                <option value=""></option>
                <option value="Free">Free</option>
                <option value="Paid">Paid</option>
              </Form.Select>
              <Form.Label className="floating-label">Membership Level</Form.Label>
            </Form.Group>

            <Form.Group controlId="membershipRole" className="floating-label-group">
              <Form.Select
                name="membershipRole"
                value={formData.membershipRole}
                onChange={handleInputChange}
                className="floating-input"
                required  // Required field
              >
                <option value=""></option>
                <option value="Normal">Normal</option>
                <option value="Instructor">Instructor</option>
              </Form.Select>
              <Form.Label className="floating-label">Membership Role</Form.Label>
            </Form.Group>

            <Form.Group controlId="ageGroup" className="floating-label-group">
              <Form.Select
                name="ageGroup"
                value={formData.ageGroup}
                onChange={handleInputChange}
                className="floating-input"
                required  // Required field
              >
                <option value=""></option>
                <option value="Senior">Senior</option>
                <option value="PreSenior">Pre-Senior</option>
                <option value="Other">Other</option>
              </Form.Select>
              <Form.Label className="floating-label">Age Group</Form.Label>
            </Form.Group>

            <Form.Group controlId="membershipType" className="floating-label-group">
              <Form.Select
                name="membershipType"
                value={formData.membershipType}
                onChange={handleInputChange}
                className="floating-input"
                required  // Required field
              >
                <option value=""></option>
                <option value="Student">Caregiver</option>
                <option value="Caregiver">Volunteer</option>
              </Form.Select>
              <Form.Label className="floating-label">Membership Type</Form.Label>
            </Form.Group>
          </Col>
        </Row>

        {/* Submit Button */}
        {/* <div className="buttondiv">
          <Button type="submit" className="submitbutton notificationsave">Send Notification</Button>
          <Button variant="danger" className=' notificationdelete'>Delete</Button>
          <Button variant="primary" className='notificationconf'>Confirm</Button>
          <Button variant="secondary" className='ps-2 notificationdelete'>Withdraw</Button>
        </div> */}



        <Row className="mb-3 ms-4 mt-4">
          <Col md={4} className="d-flex ">
            <Button variant="" type="submit" className='m submitbutton notificationsave'>Save</Button>
            <Button variant="" className='ms-3 notificationdelete'>Delete</Button>
            <Button variant="" className='ms-3 notificationconf'>Confirm</Button>
            <Button variant="" className='ms-3 ps-2 notificationdelete'>Withdraw</Button>
          </Col>
        </Row>



      </Form>




      <Modal show={showModal} onHide={handleClose} centered size="lg" className="custom-modal">
        <Modal.Body style={{ height: '360px' }}>
          <h4 className="maintitle">Add Instructor Photo</h4>
          <div style={{ textAlign: 'center', height: '156px', padding: '40px', margin: '20px', border: '1px solid #D9D9D9' }}>
            <p>Ratio limit 79:82,format PNG/JPG, e.g. 395(W) * 410(H)</p>
            <Form.Group controlId="formFile">
              <input
                type="file"
                id="file-input"
                accept="image/*,video/*"

                style={{ display: 'none' }}
              />
              <Button onClick={() => document.getElementById('file-input').click()}>Select</Button>
            </Form.Group>
          </div>

          {/* Preview Image or Video */}
          {previewUrl && (
            <div style={{ textAlign: 'left', marginTop: '20px' }}>
              {selectedFile.type.startsWith('image/') ? (
                <img src={previewUrl} alt="Preview" style={{ width: '375px', height: '221px' }} />
              ) : (
                <video width="375" height="221" controls>
                  <source src={previewUrl} />
                </video>
              )}
              <XCircle
                style={{ cursor: 'pointer', color: 'red', marginTop: '10px' }}
                size={24}

              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose} disabled={!selectedFile}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default NotificationForm;

