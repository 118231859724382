



import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import axios from 'axios';
import "./membercss/MemberInfo.css";
import './membercss/BasicInformation.css';
import Avatars from './Avatars';

function BasicInformation() {
    const [inputValues, setInputValues] = useState({
        input1: "", // Name
        input2: "", // Date of Birth
        input3: "", // Gender
        input4: "", // Phone
        input5: "", // Email
        input6: "", // Language
        input7: "", // Member ID
        input8: "", // Card Ref
        input9: ""  // Joined Date
    });
    const [age, setAge] = useState(""); // Age display in years and months
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });

        // Calculate age if date of birth is changed
        if (name === "input2") {
            calculateAge(value);
        }
    };

    const calculateAge = (dob) => {
        if (!dob) return;

        const birthDate = new Date(dob);
        const today = new Date();

        let years = today.getFullYear() - birthDate.getFullYear();

        // Adjust if the current date is before the birth date in the current year
        const isBeforeBirthdayThisYear =
            today.getMonth() < birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate());

        if (isBeforeBirthdayThisYear) {
            years -= 1;
        }

        setAge(`${years} ${years !== 1 ? '' : ''}`);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

       
        if (inputValues.input4.length > 11) {
            setError("Phone number should not exceed 11 digits.");
            return;
        }

        // Email validation: simple regex for basic validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(inputValues.input5)) {
            setError("Please enter a valid email address.");
            return;
        }

        // Joined Date validation: must not be a future date
        const today = new Date().toISOString().split("T")[0];
        if (inputValues.input9 > today) {
            setError("Joined Date cannot be a future date.");
            return;
        }

        try {
            const response = await axios.post("https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add-basic-info", {
                ...inputValues,
                age
            });
            setSuccessMessage("Basic information saved successfully!");
            setError(null);
            setInputValues({
                input1: "", input2: "", input3: "", input4: "",
                input5: "", input6: "", input7: "", input8: "", input9: ""
            });
            setAge(""); // Reset age field
        } catch (error) {
            setError("Failed to save basic information. Please try again.");
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0">
                        <Card.Body>
                            <Avatars />
                            <br />
                            <br />
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <h5 className="basicinfo">Basic Information</h5>
                                    <Col md={6}>
                                        <Form.Group controlId="formInput1" className="floating-label-group">
                                            <Form.Control
                                                type="text"
                                                name="input1"
                                                value={inputValues.input1}
                                                onChange={handleChange}
                                                className="floating-input basicinfoinput"
                                                required
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Name</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput2" className="floating-label-group">
                                            <Form.Control
                                                type="date"
                                                name="input2"
                                                value={inputValues.input2}
                                                onChange={handleChange}
                                                className="floating-input"
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Date of Birth</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInputAge" className="floating-label-group">
                                            <Form.Control
                                                type="text"
                                                value={age}
                                                disabled
                                                className="floating-input basicinfoinput"
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Age</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput3" className="floating-label-group">
                                            <Form.Select
                                                name="input3"
                                                value={inputValues.input3}
                                                onChange={handleChange}
                                                className="floating-input"
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </Form.Select>
                                            <Form.Label className="floating-label basicinfolabel">Gender</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput4" className="floating-label-group">
                                            <Form.Control
                                                type="tel"
                                                name="input4"
                                                value={inputValues.input4}
                                                onChange={handleChange}
                                                className="floating-input basicinfoinput"
                                                maxLength="11"
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Phone</Form.Label>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group controlId="formInput5" className="floating-label-group">
                                            <Form.Control
                                                type="email"
                                                name="input5"
                                                value={inputValues.input5}
                                                onChange={handleChange}
                                                className="floating-input basicinfoinput"
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Email</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput6" className="floating-label-group">
                                            <Form.Select
                                                name="input6"
                                                value={inputValues.input6}
                                                onChange={handleChange}
                                                className="floating-input"
                                            >
                                                <option value="">Select Language</option>
                                                <option value="English">English</option>
                                                <option value="Chinese">Chinese</option>
                                            </Form.Select>
                                            <Form.Label className="floating-label basicinfolabel">Language</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput7" className="floating-label-group">
                                            <Form.Control
                                                type="number"
                                                name="input7"
                                                value={inputValues.input7}
                                                onChange={handleChange}
                                                className="floating-input basicinfoinput"
                                                maxLength="8"
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Member ID</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput8" className="floating-label-group">
                                            <Form.Control
                                                type="text"
                                                name="input8"
                                                value={inputValues.input8}
                                                onChange={handleChange}
                                                className="floating-input basicinfoinput"
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Card Ref</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formInput9" className="floating-label-group">
                                            <Form.Control
                                                type="date"
                                                name="input9"
                                                value={inputValues.input9}
                                                onChange={handleChange}
                                                className="floating-input"
                                                max={new Date().toISOString().split("T")[0]} // Prevent future dates
                                            />
                                            <Form.Label className="floating-label basicinfolabel">Joined Date</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mt-5">
                                    <Col>
                                        <Button variant="primary" type="submit" className="me-2 instructorphoto">
                                            Save Changes
                                        </Button>
                                        <Button variant="outline-primary" onClick={() => setInputValues({})} className="canslebtn">
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>

                                {error && <p className="text-danger mt-3">{error}</p>}
                                {successMessage && <p className="text-success mt-3">{successMessage}</p>}
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default BasicInformation;





//---------------------------------------------------------------------------------------------------------------------








// import React, { useState } from "react";
// import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
// import axios from "axios";
// import "./membercss/MemberInfo.css";
// import "./membercss/BasicInformation.css";
// import Avatars from "./Avatars";
// import { useDispatch } from "react-redux";
// import { setBasicInformation } from "app/redux/slices/basicInfoSlice";

// import { useNavigate } from "react-router-dom";
// function BasicInformation() {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const [inputValues, setInputValues] = useState({
//         mem_name: "",
//         mem_dob: "",
//         mem_gender: "",
//         mem_phone_number: "",
//         mem_email: "",
//         mem_prefered_language: "",
//         member_id: "",
//         mem_card_reference: "",
//         mem_created_date: "",
//     });

//     const [mem_age, setMemAge] = useState(""); // Age display in years and months
//     const [error, setError] = useState(null);
//     const [successMessage, setSuccessMessage] = useState(null);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setInputValues({ ...inputValues, [name]: value });

//         if (name === "mem_dob") {
//             calculateAge(value);
//         }
//     };

//     const calculateAge = (dob) => {
//         if (!dob) return;

//         const birthDate = new Date(dob);
//         const today = new Date();
//         let years = today.getFullYear() - birthDate.getFullYear();

//         const isBeforeBirthdayThisYear =
//             today.getMonth() < birthDate.getMonth() ||
//             (today.getMonth() === birthDate.getMonth() &&
//                 today.getDate() < birthDate.getDate());

//         if (isBeforeBirthdayThisYear) {
//             years -= 1;
//         }

//         setMemAge(`${years} ${years !== 1 ? "" : ""}`);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (inputValues.mem_phone_number.length > 12) {
//             setError("Phone number should not exceed 10 digits.");
//             return;
//         }

//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         if (!emailRegex.test(inputValues.mem_email)) {
//             setError("Please enter a valid email address.");
//             return;
//         }

//         const today = new Date().toISOString().split("T")[0];
//         if (inputValues.mem_created_date > today) {
//             setError("Joined Date cannot be a future date.");
//             return;
//         }

//         const formData = {
//             ...inputValues,
//             mem_age, // Assuming mem_age is calculated
//           };


//         try {
//             const response = await axios.post(
//                 process.env.REACT_APP_BASE_API_URL +"/add-basic-info",
//                 {
//                     ...inputValues,
//                     mem_age,
//                 }
//             );
//             setSuccessMessage("Basic information saved successfully!");
//             setError(null);
//             setInputValues({
//                 mem_name: "",
//                 mem_dob: "",
//                 mem_gender: "",
//                 mem_phone_number: "",
//                 mem_email: "",
//                 mem_prefered_language: "",
//                 member_id: "",
//                 mem_card_reference: "",
//                 mem_created_date: "",
//             });
//             setMemAge(""); // Reset age field
//             dispatch(setBasicInformation(formData));
    
//         } catch (error) {
//             setError("Failed to save basic information. Please try again.");
//         }
//     };

//     return (
//         <Container fluid>
//             <Row>
//                 <Col md={12}>
//                     <Card className="mt-0 border-0">
//                         <Card.Body>
//                             <Avatars />
//                             <br />
//                             <br />
//                             <Form onSubmit={handleSubmit}>
//                                 <Row>
//                                     <h5 className="basicinfo">Basic Information</h5>
//                                     <Col md={6}>
//                                         <Form.Group controlId="formMemName" className="floating-label-group">
//                                             <Form.Control
//                                                 type="text"
//                                                 name="mem_name"
//                                                 value={inputValues.mem_name}
//                                                 onChange={handleChange}
//                                                 className="floating-input basicinfoinput"
//                                                 required
//                                             />
//                                             <Form.Label className="floating-label basicinfolabel">Name</Form.Label>
//                                         </Form.Group>

//                                         <Form.Group controlId="formMemDob" className="floating-label-group">
//                                             <Form.Control
//                                                 type="date"
//                                                 name="mem_dob"
//                                                 value={inputValues.mem_dob}
//                                                 onChange={handleChange}
//                                                 className="floating-input"
//                                             />
//                                             <Form.Label className="floating-label basicinfolabel">Date of Birth</Form.Label>
//                                         </Form.Group>

//                                         <Form.Group controlId="formMemAge" className="floating-label-group">
//                                             <Form.Control
//                                                 type="text"
//                                                 value={mem_age}
//                                                 disabled
//                                                 className="floating-input basicinfoinput"
//                                             />
//                                             <Form.Label className="floating-label basicinfolabel">Age</Form.Label>
//                                         </Form.Group>

//                                         <Form.Group controlId="formMemGender" className="floating-label-group">
//                                             <Form.Select
//                                                 name="mem_gender"
//                                                 value={inputValues.mem_gender}
//                                                 onChange={handleChange}
//                                                 className="floating-input"
//                                             >
//                                                 <option value="">Select Gender</option>
//                                                 <option value="Male">Male</option>
//                                                 <option value="Female">Female</option>
//                                             </Form.Select>
//                                             <Form.Label className="floating-label basicinfolabel">Gender</Form.Label>
//                                         </Form.Group>

//                                         <Form.Group controlId="formMemPhoneNumber" className="floating-label-group">
//                                             <Form.Control
//                                                 type="tel"
//                                                 name="mem_phone_number"
//                                                 value={inputValues.mem_phone_number}
//                                                 onChange={handleChange}
//                                                 className="floating-input basicinfoinput"
//                                                 maxLength="11"
//                                             />
//                                             <Form.Label className="floating-label basicinfolabel">Phone</Form.Label>
//                                         </Form.Group>
//                                     </Col>

//                                     <Col md={6}>
//                                         <Form.Group controlId="formMemEmail" className="floating-label-group">
//                                             <Form.Control
//                                                 type="email"
//                                                 name="mem_email"
//                                                 value={inputValues.mem_email}
//                                                 onChange={handleChange}
//                                                 className="floating-input basicinfoinput"
//                                             />
//                                             <Form.Label className="floating-label basicinfolabel">Email</Form.Label>
//                                         </Form.Group>

//                                         <Form.Group controlId="formMemPreferedLanguage" className="floating-label-group">
//                                             <Form.Select
//                                                 name="mem_prefered_language"
//                                                 value={inputValues.mem_prefered_language}
//                                                 onChange={handleChange}
//                                                 className="floating-input"
//                                             >
//                                                 <option value="">Select Language</option>
//                                                 <option value="English">English</option>
//                                                 <option value="Chinese">Chinese</option>
//                                             </Form.Select>
//                                             <Form.Label className="floating-label basicinfolabel">Language</Form.Label>
//                                         </Form.Group>

//                                         <Form.Group controlId="formMemberId" className="floating-label-group">
//                                             <Form.Control
//                                                 type="number"
//                                                 name="member_id"
//                                                 value={inputValues.member_id}
//                                                 onChange={handleChange}
//                                                 className="floating-input basicinfoinput"
//                                                 maxLength="8"
//                                             />
//                                             <Form.Label className="floating-label basicinfolabel">Member ID</Form.Label>
//                                         </Form.Group>

//                                         <Form.Group controlId="formMemCardReference" className="floating-label-group">
//                                             <Form.Control
//                                                 type="text"
//                                                 name="mem_card_reference"
//                                                 value={inputValues.mem_card_reference}
//                                                 onChange={handleChange}
//                                                 className="floating-input basicinfoinput"
//                                             />
//                                             <Form.Label className="floating-label basicinfolabel">Card Ref</Form.Label>
//                                         </Form.Group>

//                                         <Form.Group controlId="formMemCreatedDate" className="floating-label-group">
//                                             <Form.Control
//                                                 type="date"
//                                                 name="mem_created_date"
//                                                 value={inputValues.mem_created_date}
//                                                 onChange={handleChange}
//                                                 className="floating-input"
//                                                 max={new Date().toISOString().split("T")[0]} // Prevent future dates
//                                             />
//                                             <Form.Label className="floating-label basicinfolabel">Joined Date</Form.Label>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>

//                                 <Row className="mt-5">
//                                     <Col>
//                                         <Button variant="primary" type="submit" className="me-2 instructorphoto">
//                                             Save Changes
//                                         </Button>
//                                         <Button
//                                             variant="outline-primary"
//                                             onClick={() =>
//                                                 setInputValues({
//                                                     mem_name: "",
//                                                     mem_dob: "",
//                                                     mem_gender: "",
//                                                     mem_phone_number: "",
//                                                     mem_email: "",
//                                                     mem_prefered_language: "",
//                                                     member_id: "",
//                                                     mem_card_reference: "",
//                                                     mem_created_date: "",
//                                                 })
//                                             }
//                                             className="canslebtn"
//                                         >
//                                             Cancel
//                                         </Button>
//                                     </Col>
//                                 </Row>

//                                 {error && <p className="text-danger mt-3">{error}</p>}
//                                 {successMessage && <p className="text-success mt-3">{successMessage}</p>}
//                             </Form>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>
//         </Container>
//     );
// }

// export default BasicInformation;
