


import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Image, Modal } from "react-bootstrap";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/MemberInfo.css";
import './membercss/BasicInformation.css'
import './membercss/Avatars.css'
function Avatars() {


    const [selectedAvatar, setSelectedAvatar] = useState("/assets/images/avatars/001-man.svg");


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Generate an array of avatar image paths with onClick to select avatar
    const images = Array.from({ length: 42 }).map((_, index) => {
        const imgNumber = String(index + 1).padStart(2, '0');
        const imgSrc = `${process.env.PUBLIC_URL}/images/avatars/Avatar-${imgNumber}.png`;

        return (
            <img
                key={index}
                src={imgSrc}
                alt={`Avatar ${imgNumber}`}
                onClick={() => {
                    setSelectedAvatar(imgSrc);
                    handleClose(); // Close modal after selecting
                }}
                style={{
                    height: '60px',
                    width: '60px',
                    margin: '4px',
                    cursor: 'pointer'
                }}
            />
        );
    });

    return (
        <Container fluid>

            <Card style={{ position: "relative", padding: "0px", height: "348px" }}>
                <div
                    className="cover-pic-wrapper"
                    style={{
                        width: "100%",
                        height: "128px",
                        objectFit: "cover",
                        backgroundColor: "#D9D9D9",
                    }}
                ></div>

                <div className="content-wrapper text-center" style={{ marginTop: "-60px" }}>
                    <div className="avatar-badge mb-3" style={{ position: "relative" }}>
                        <div style={{ position: "relative", display: "inline-block" }}>
                            {/* Avatar image */}
                            <Image
                                src={selectedAvatar}
                                alt="Team Member"
                                roundedCircle
                                width={100}
                                height={100}
                                style={{ border: "5px solid white", display: "block" }}
                                fluid
                            />

                            {/* Camera icon positioned at the bottom-right of the avatar */}
                            <label htmlFor="icon-button-file">
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="icon-button-file"
                                    style={{ display: "none" }}
                                />
                                <Button
                                    variant="light"
                                    className="upload-icon"
                                    onClick={handleShow} // Open modal on click
                                    style={{
                                        position: "absolute",
                                        bottom: "0px",
                                        right: "0px",
                                        borderRadius: "50%",
                                        backgroundColor: "#1976D2",
                                        color: 'white',
                                        padding: "5px",
                                        marginBottom: '18px',
                                        zIndex: "2",
                                    }}
                                >
                                    <CameraAltOutlinedIcon fontSize="small" />
                                </Button>
                            </label>
                        </div>
                    </div>
                </div>
            </Card>





            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="custom-modal"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="avatarhead">Choose Avatar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(7, 66px)',
                            gridGap: '4px',
                            overflow: 'auto',
                            background: '#FFFFFF',
                        }}
                    >
                        {images}
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default Avatars;
