import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Form, Collapse } from 'react-bootstrap';
import { FaMinus } from 'react-icons/fa'; // Import Minus icon from react-icons
import './campaigncss/Lesson.css';

function Lesson() {
    const [showGreenBox, setShowGreenBox] = useState(false); // State to control green box visibility
    const [showForm, setShowForm] = useState(false);         // State to control form visibility

    return (
        <Container fluid>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <h5 className='lessonheading p-2'>Lesson(s) Details</h5>

                    <Card.Body>
                        {/* Green Box Section - Only show if "Add Lesson(s) Details" button is clicked */}
                        {showGreenBox && (
                            <div
                                className="p-3 border border-success rounded d-flex justify-content-between align-items-center mb-3 lessoncard"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowForm(!showForm)} // Toggle the form visibility when clicking the box
                            >
                                <h5 className="mb-0 cardtext">Date :</h5>
                                <FaMinus style={{color:'white'}}/> {/* Minus icon on the right side */}
                            </div>
                        )}

                        {/* Form Section - Only show if green box is clicked */}
                        <Collapse in={showForm}>
                            <div className="p-3 ">

                                <Form>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="introductionChinese" className="floating-label-group">
                                                <Form.Control
                                                    type="text"
                                                    name="introductionChinese"

                                                    className="floating-input"
                                                />
                                                <Form.Label className="floating-label">Introduction (Chinese)</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="introductionChinese" className="floating-label-group">
                                                <Form.Control
                                                    type="text"
                                                    name="introductionChinese"

                                                    className="floating-input"
                                                />
                                                <Form.Label className="floating-label">Introduction (Chinese)</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            {/* Campaign Name Dropdown (Chinese) */}
                                            <Form.Group controlId="campaignNameChinese" className="floating-label-group">
                                                <Form.Select
                                                    name="campaignName"

                                                    className="floating-input"
                                                >
                                                    <option value=""></option>
                                                    <option value="粵曲班">粵曲班</option>
                                                    <option value="綜合太極班">綜合太極班</option>
                                                    <option value="活力排舞初班">活力排舞初班</option>
                                                    <option value="駿伴歷行-做自己的退休策劃師">駿伴歷行 - 做自己的退休策劃師</option>
                                                    <option value="Harmonica閑琴逸趣">Harmonica閑琴逸趣</option>
                                                    <option value="萌薑樂園-我係「金菠蘿」">萌薑樂園 - 我係「金菠蘿」</option>
                                                    <option value="朦朦朧朧無聲體驗活動">朦朦朧朧無聲體驗活動</option>
                                                    <option value="「齡」距離潮流重塑">「齡」距離潮流重塑</option>
                                                    <option value="童心伴長者">童心伴長者</option>
                                                </Form.Select>
                                                <Form.Label className="floating-label">Venue(Chinese)</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            {/* Campaign Name Dropdown (English) */}
                                            <Form.Group controlId="campaignNameEnglish" className="floating-label-group">
                                                <Form.Select
                                                    name="campaignName"


                                                    className="floating-input"
                                                >
                                                    <option value=""></option>
                                                    <option value="Cantonese Opera Class">Cantonese Opera Class</option>
                                                    <option value="Traditional Tai Chi Beginning Class">Traditional Tai Chi Beginning Class</option>
                                                    <option value="Line Dance Beginner Class">Line Dance Beginner Class</option>
                                                    <option value="Be Your Own Retirement Planner">Be Your Own Retirement Planner</option>
                                                    <option value="Leisure Harmonica Class">Leisure Harmonica Class</option>
                                                    <option value="Prize-giving Activities">Prize-giving Activities</option>
                                                    <option value="Hazy Silent Experience Activities">Hazy Silent Experience Activities</option>
                                                    <option value="Fashion & Image Design Class">Fashion & Image Design Class</option>
                                                    <option value="Activities to Accompany the Elderly">Activities to Accompany the Elderly</option>
                                                </Form.Select>
                                                <Form.Label className="floating-label">Venue(English)</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        {/* Additional Form Fields */}
                                        <Col md={2}>
                                            <Form.Group controlId="code" className="floating-label-group">
                                                <Form.Control
                                                    type="date"
                                                    name="Date"
                                                    className="floating-input"

                                                />
                                                <Form.Label className="floating-label">Date</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group controlId="code" className="floating-label-group">
                                                <Form.Control
                                                    type="time"
                                                    name="start-time"
                                                    className="floating-input"

                                                />
                                                <Form.Label className="floating-label">Start Time</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group controlId="code" className="floating-label-group">
                                                <Form.Control
                                                    type="time"
                                                    name="end-time"
                                                    className="floating-input"

                                                />
                                                <Form.Label className="floating-label">End Time</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group controlId="status" className="floating-label-group">
                                                <Form.Select name="status" className="floating-input">
                                                    <option value=""></option>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Delayed">Delayed</option>
                                                    <option value="Cancelled">Cancelled</option>
                                                </Form.Select>
                                                <Form.Label className="floating-label">Status</Form.Label>
                                            </Form.Group>
                                        </Col>


                                    </Row>
                                </Form>
                            </div>
                        </Collapse>

                        {/* Bottom Buttons */}
                        <div className="d-flex justify-content-left mt-4">
                            <Button variant="" className="mx-2 lessoncanslebtn">Cancel</Button>
                            <Button variant="" type="submit" className="mx-2 lessonsavebtn">Save</Button>
                            <Button variant="" className="mx-2 lessonapprovelbtn">Request Approval</Button>
                            <Button
                                variant="success"
                                onClick={() => setShowGreenBox(true)} // Show green box when clicking "Add Lesson(s) Details"
                                className="mx-2 lessonaddbtn"
                            >
                                Add Lesson(s) Details
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
}

export default Lesson;
