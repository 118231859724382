

import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Image } from "react-bootstrap";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/MemberInfo.css";
import axios from 'axios';
import Avatars from './Avatars'
function Interests() {
    const interestsList = [
        "Indoor", "Outdoor", "Free", "Talks",
        "Music", "Sports", "Handmade", "Languages",
        "Reading", "Baking", "Gardening", "Painting",
        "Crocheting", "Photography", "Cooking", "Singing",
        "Pets", "Social", "Charity", "Internet",
        "Movie", "Games", "Fitness", "Retirement Planning"
    ];

    const [selectedInterests, setSelectedInterests] = useState({});

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setSelectedInterests((prevSelected) => ({
            ...prevSelected,
            [name]: checked, // Use interest name as key instead of checkbox ID
        }));
    };



    const handleSave = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        // Prepare the array of selected interests (interest names)
        const interests = Object.keys(selectedInterests).filter((key) => selectedInterests[key]);

        try {
            // API call to save interests
            const response = await axios.post("  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/save-interests", { interests });

            // Check if the response is successful
            if (response.status === 200) {
                alert("Interests saved successfully!");
                setSelectedInterests({}); // Clear selected interests after successful submission
            }
        } catch (error) {
            console.error("Error saving interests:", error);
            alert(error.response?.data?.error || "Failed to save interests.");
        }
    };



    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0" style={{ position: "relative" }}>
                        <Card.Body>
                           
                            <Avatars />
                            <br />

                            <Form onSubmit={handleSave}>
                                <Row>
                                    <h5 className="basicinfo">Interests</h5>
                                    <Col md={9} className="mt-4">
                                        <Row>
                                            {interestsList.map((interest, index) => (
                                                <Col md={3} key={index} className="mb-2">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={`checkbox-${index}`}
                                                        name={interest} // Use interest name as the checkbox name
                                                        label={interest}
                                                        className="checkbtn"
                                                        checked={selectedInterests[interest] || false} // Use interest name for checked state
                                                        onChange={handleCheckboxChange} // Handle checkbox change
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col>
                                        <Button variant="primary" type="submit" className="me-2 instructorphoto">
                                            Save Changes
                                        </Button>
                                        <button className="btn btn-outline-primary canslebtn ">Cancel</button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Interests;
