



// import React, { useState, useEffect } from 'react';
// import { Button, Table, Alert } from 'react-bootstrap';
// import RoleManagementNew from './RoleManagementNew';
// import './rolemanagemntcss/RoleManagement.css';
// import axios from 'axios';
// import { TablePagination } from "@mui/material";
// import { useSelector } from 'react-redux';

// const RoleManagement = () => {
//   const [roles, setRoles] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [modalTitle, setModalTitle] = useState('Add Role');
//   const [selectedRole, setSelectedRole] = useState(null);
//   const [showSuccessPopup, setShowSuccessPopup] = useState(false);
//   const [deleteError, setDeleteError] = useState(""); // State for delete error message
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [rolePermissions, setRolePermissions] = useState(null);
//   const { UserInfo = {} } = useSelector(state => state?.users);

//   // Fetch role permissions based on user info
//   useEffect(() => {
//     const permissions = UserInfo?.permission?.permissions || [];
//     const validPermissions = permissions.filter(({ actions }) =>
//       Object.values(actions).some(Boolean)
//     );
//     const rolePermissions = validPermissions?.find((p) => p?.ModuleName === "Role Management");
//     setRolePermissions(rolePermissions);
//   }, [UserInfo]);

//   // Fetch roles data only once when the component mounts
//   useEffect(() => {
//     fetchRoles();
//   }, []);

//   const fetchRoles = async () => {
//     try {
//       const response = await axios.get(process.env.REACT_APP_BASE_API_URL + "/get-new-roles");
//       const rolesData = response.data.body ? JSON.parse(response.data.body) : [];
//       const filteredRoles = rolesData.filter(role => role.role_name !== "SuperAdmin" && role.role_name !== "Super Admin");
//       setRoles(Array.isArray(filteredRoles) ? filteredRoles : []);
//     } catch (error) {
//       console.error('Error fetching roles:', error);
//       setRoles([]);
//     }
//   };

//   useEffect(() => {
//     fetchRoles();
//     const interval = setInterval(fetchRoles, 5000);
//     return () => clearInterval(interval);
//   }, []);

//   const handleShow = () => {
//     setSelectedRole(null);
//     setModalTitle('Add Role');
//     setShowModal(true);
//   };

//   const handleEdit = (role) => {
//     setSelectedRole(role);
//     setModalTitle("Edit Role");
//     setShowModal(true);
//   };

//   const handleClose = () => setShowModal(false);

//   const handleDelete = async (role) => {
//     const confirmed = window.confirm(`Are you sure you want to delete the role ${role.role_name}?`);
//     if (!confirmed) return;

//     try {
//       const response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/delete-new-roles", {
//         pk_id: role.pk_id,
//         sk_id: role.sk_id
//       });

//       if (response.data?.status === 'error' || response.data?.message === 'The role is assigned to the user and cannot be removed') {
//         setDeleteError("Please remove the role from assigned users before deleting.");
//         setShowSuccessPopup(false);
//       } else {
//         console.log("[DELETE_ROLE]: Role deleted successfully");
//         setDeleteError("");
//         setShowSuccessPopup(true);
//         fetchRoles();
//         setTimeout(() => setShowSuccessPopup(false), 3000);
//       }
//     } catch (error) {
//       console.error("Error deleting role:", error);
//       setDeleteError("An error occurred while attempting to delete the role.");
//     }
//   };

//   const handleChangePage = (event, newPage) => setPage(newPage);

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0); // Reset to the first page
//   };

//   // Calculate the paginated data based on current page and rowsPerPage
//   const paginatedRoles = roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//   return (
//     <div className="container p-5">
//       <div className="row mb-3">
//         <div className="col-10 d-flex align-items-center">
//           <h4 className="firstname">Role Management</h4>
//         </div>
//         <div className="col-2 d-flex justify-content-start">
//           {rolePermissions?.actions?.add && (
//             <Button className="custom-success-btn" variant="success" onClick={handleShow}>
//               Add
//             </Button>
//           )}
//         </div>
//       </div>

//       {showSuccessPopup && (
//         <Alert variant="success" className="mt-3">
//           Role deleted successfully!
//         </Alert>
//       )}

//       {deleteError && (
//         <Alert variant="danger" className="mt-3">
//           {deleteError}
//         </Alert>
//       )}

//       <Table className="role-management-table">
//         <thead>
//           <tr className="tabaleh">
//             <th style={{ width: '74.5%' }} className="tabaleh">Roles</th>
//             <th style={{ width: '23.8%' }} className="tabaleh">Function(s)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {paginatedRoles.length > 0 ? (
//             paginatedRoles.map((role) => (
//               <tr key={role.pk_id}>
//                 <td className="role align-middle">{role.role_name}</td>
//                 <td>
//                   {rolePermissions?.actions?.edit && (
//                     <Button className="custom-success-btn me-2" onClick={() => handleEdit(role)}>
//                       Edit
//                     </Button>
//                   )}
//                   {rolePermissions?.actions?.delete && (
//                     <Button className="custom-danger-btn" onClick={() => handleDelete(role)}>
//                       Delete
//                     </Button>
//                   )}
//                 </td>
//               </tr>
//             ))
//           ) : (
//             <tr>
//               <td colSpan="2" className="text-center">
//                 No roles available.
//               </td>
//             </tr>
//           )}
//         </tbody>
//       </Table>

//       <TablePagination
//         component="div"
//         count={roles.length}
//         page={page}
//         onPageChange={handleChangePage}
//         rowsPerPage={rowsPerPage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//         rowsPerPageOptions={[10, 20, 30]}
//         className='mt-5'
//       />

//       <RoleManagementNew
//         show={showModal}
//         handleClose={handleClose}
//         modalTitle={modalTitle}
//         roleData={selectedRole}
//       />
//     </div>
//   );
// };

// export default RoleManagement;




















import React, { useState, useEffect } from 'react';
import { Button, Table, Alert } from 'react-bootstrap';
import RoleManagementNew from './RoleManagementNew';
import './rolemanagemntcss/RoleManagement.css';
import axios from 'axios';
import { useSelector } from 'react-redux';

const RoleManagement = () => {
  const [roles, setRoles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Add Role');
  const [selectedRole, setSelectedRole] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Show 10 items per page
  const [rolePermissions, setRolePermissions] = useState(null);
  const { UserInfo = {} } = useSelector(state => state?.users);

  useEffect(() => {
    const permissions = UserInfo?.permission?.permissions || [];
    const validPermissions = permissions.filter(({ actions }) =>
      Object.values(actions).some(Boolean)
    );
    const rolePermissions = validPermissions?.find((p) => p?.ModuleName === "Role Management");
    setRolePermissions(rolePermissions);
  }, [UserInfo]);



  const fetchRoles = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_API_URL + "/get-new-roles");
      const rolesData = response.data.body ? JSON.parse(response.data.body) : [];
      const filteredRoles = rolesData.filter(role => role.role_name !== "SuperAdmin" && role.role_name !== "Super Admin");
      setRoles(Array.isArray(filteredRoles) ? filteredRoles : []);
    } catch (error) {
      console.error('Error fetching roles:', error);
      setRoles([]);
    }
  }

  useEffect(() => {
    fetchRoles();

    const interval = setInterval(fetchRoles, 3000);

    return () => clearInterval(interval);
  }, []);




  const handleShow = () => {
    setSelectedRole(null);
    setModalTitle('Add Role');
    setShowModal(true);
  };

  const handleEdit = (role) => {
    setSelectedRole(role);
    setModalTitle("Edit Role");
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const handleDelete = async (role) => {
    const confirmed = window.confirm(`Are you sure you want to delete the role ${role.role_name}?`);
    if (!confirmed) return;

    try {
      const response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/delete-new-roles", {
        pk_id: role.pk_id,
        sk_id: role.sk_id
      });

      if (response.data?.status === 'error' || response.data?.message === 'The role is assigned to the user and cannot be removed') {
        setDeleteError("Please remove the role from assigned users before deleting.");
        setShowSuccessPopup(false);
      } else {
        console.log("[DELETE_ROLE]: Role deleted successfully");
        setDeleteError("");
        setShowSuccessPopup(true);
        fetchRoles();
        setTimeout(() => setShowSuccessPopup(false), 3000);
      }
    } catch (error) {
      console.error("Error deleting role:", error);
      setDeleteError("An error occurred while attempting to delete the role.");
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(roles.length / itemsPerPage);
  const paginatedRoles = roles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container p-5">
      <div className="row mb-3">
        <div className="col-10 d-flex align-items-center">
          <h4 className="firstname">Role Management</h4>
        </div>
        <div className="col-2 d-flex justify-content-start">
          {rolePermissions?.actions?.add && (
            <Button className="custom-success-btn" variant="success" onClick={handleShow}>
              Add
            </Button>
          )}
        </div>
      </div>

      {showSuccessPopup && (
        <Alert variant="success" className="mt-3">
          Role deleted successfully!
        </Alert>
      )}

      {deleteError && (
        <Alert variant="danger" className="mt-3">
          {deleteError}
        </Alert>
      )}

      <Table className="role-management-table">
        <thead>
          <tr className="tabaleh">
            <th style={{ width: '74.5%' }} className="tabaleh">Roles</th>
            <th style={{ width: '23.8%' }} className="tabaleh">Function(s)</th>
          </tr>
        </thead>
        <tbody>
          {paginatedRoles.length > 0 ? (
            paginatedRoles.map((role) => (
              <tr key={role.pk_id}>
                <td className="role align-middle">{role.role_name}</td>
                <td>
                  {rolePermissions?.actions?.edit && (
                    <Button className="custom-success-btn me-2" onClick={() => handleEdit(role)}>
                      Edit
                    </Button>
                  )}
                  {rolePermissions?.actions?.delete && (
                    <Button className="custom-danger-btn" onClick={() => handleDelete(role)}>
                      Delete
                    </Button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" className="text-center">
                No roles available.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <nav aria-label="Page navigation example" className='mt-5'>
        <ul className="pagination justify-content-end">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <li
              key={page}
              className={`page-item ${page === currentPage ? "active" : ""}`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            </li>
          ))}
          <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>

      <RoleManagementNew
        show={showModal}
        handleClose={handleClose}
        modalTitle={modalTitle}
        roleData={selectedRole}
      />
    </div>
  );
};

export default RoleManagement;

