



import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Image, ProgressBar } from "react-bootstrap";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/MemberInfo.css";
import axios from 'axios';
import Avatars from './Avatars'
function MembershipDetails() {
    const [inputValues, setInputValues] = useState({
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",

    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index);
    };

    const handleSave = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        try {
            // API call to save membership details
            const response = await axios.post("  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/add-membership-details", inputValues);

            // Check if the response is successful
            if (response.status === 200) {
                alert("Membership details saved successfully!");
                // Clear input values after successful submission
                setInputValues({
                    input1: "", // Reset input1
                    input2: "", // Reset input2
                    input3: "", // Reset input3
                    input5: "", // Reset input5
                    input6: "", // Reset input6
                });
            }
        } catch (error) {
            console.error("Error saving membership details:", error);
            alert(error.response?.data?.error || "Failed to save membership details.");
        }
    }
    return (
        <Container fluid>
            <Row >
                <Col md={12}>
                    <Card className="mt-0 border-0" style={{ position: "relative" }}>
                        <Card.Body>
                         

                            <Avatars />

                            <br></br>

                            <Form onSubmit={handleSave}>
                                <Row>
                                    <h5 className="basicinfo">Membership Details</h5>
                                    <Col md={6} className="mt-3">

                                        <Form.Group controlId="formSelect1" className="floating-label-group">
                                            <Form.Select
                                                name="input1"
                                                value={inputValues.input1}
                                                onChange={handleChange}
                                                className="floating-input"
                                            >
                                                <option value=""></option>
                                                <option value="free">Free</option>
                                                <option value="paid">Paid</option>
                                            </Form.Select>
                                            <Form.Label className="floating-label">Membership Level</Form.Label>
                                        </Form.Group>

                                        <Form.Group controlId="formSelect2" className="floating-label-group">
                                            <Form.Select
                                                name="input2"
                                                value={inputValues.input2}
                                                onChange={handleChange}
                                                className="floating-input"
                                            >
                                                <option value=""></option>
                                                <option value="active">Active</option>
                                                <option value="suspend">Suspend</option>
                                                <option value="deceased">Deceased</option>
                                            </Form.Select>
                                            <Form.Label className="floating-label">Membership Status</Form.Label>
                                        </Form.Group>


                                        <Form.Group controlId="formSelect3" className="floating-label-group">
                                            <Form.Select
                                                name="input3"
                                                value={inputValues.input3}
                                                onChange={handleChange}
                                                className="floating-input"
                                            >
                                                <option value=""></option>
                                                <option value="you-tsim-ne">You Tsim Neighbourhood Elderly Centre</option>
                                                <option value="chol-hung-ccsc">Choi Hung Community Centre for Senior Citizens</option>
                                                <option value="of-man-ne">Oi Man Neighbourhood Elderly Centre</option>
                                                <option value="elders-ministry">Elders Ministry and Support Centre</option>
                                                <option value="kingsford-terrace">Choi Hung Community Centre for Senior Citizens - Kingsford Terrace Centre</option>
                                            </Form.Select>
                                            <Form.Label className="floating-label">Ops Centre</Form.Label>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} className="mt-3">

                                        <Form.Group controlId="formSelect4" className="floating-label-group">
                                            <Form.Select
                                                name="input5"
                                                value={inputValues.input5}
                                                onChange={handleChange}
                                                className="floating-input"
                                            >
                                                <option value=""></option>
                                                <option value="volunteer">Volunteer</option>
                                                <option value="caregiver">Caregiver</option>
                                            </Form.Select>
                                            <Form.Label className="floating-label">Membership Identity</Form.Label>
                                        </Form.Group>


                                        <Form.Group controlId="formSelect5" className="floating-label-group">
                                            <Form.Select
                                                name="input6"
                                                value={inputValues.input6}
                                                onChange={handleChange}
                                                className="floating-input"
                                            >
                                                <option value=""></option>
                                                <option value="normal">Normal</option>
                                                <option value="instructor">Instructor</option>
                                            </Form.Select>
                                            <Form.Label className="floating-label">Membership Role</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col className="mt-5">
                                        <Button variant="primary" type="submit" className="me-2 instructorphoto">
                                            Save Changes
                                        </Button>
                                        <Button variant="outline-primary" className="canslebtn">Cancel</Button>
                                    </Col>
                                </Row>
                            </Form>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default MembershipDetails
