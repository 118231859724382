




// import React, { useState, useEffect } from "react";
// import { Form, Button, Table, Container, Row, Col, Card } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import TablePagination from '@mui/material/TablePagination';
// import "./membercss/Member.css";
// import { useSelector } from 'react-redux';
// const Member = () => {
//   const navigate = useNavigate();
//   const [members, setMembers] = useState([]);
//   const [searchOption, setSearchOption] = useState("Name");
//   const [searchInput, setSearchInput] = useState("");
//   const [filteredMembers, setFilteredMembers] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);


//   const [memberPermissions, setMemberPermissions] = useState(null);
//   const { UserInfo = {} } = useSelector(state => state?.users);

//   useEffect(() => {
//     const permissions = UserInfo?.permission?.permissions || [];
//     const validPermissions = permissions.filter(({ actions }) =>
//       Object.values(actions).some(Boolean)
//     );
//     const memberPermissions = validPermissions?.find((p) => p?.ModuleName === "Member Management");
//     setMemberPermissions(memberPermissions);
//   }, [UserInfo]);





//   const handleAddMember = () => {
//     navigate("/member/memberinfo");
//   };

//   const handleEditMember = (member) => {
//     navigate("/member/memberinfo", { state: { member } });
//   };


//   useEffect(() => {
//     // const fetchMembers = async () => {
//     //   try {
//     //     const response = await axios.get("  https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/fetch-basic-info");
//     //     setMembers(response.data.basicInfo);
//     //     setFilteredMembers(response.data.basicInfo);
//     //   } catch (error) {
//     //     console.error("Error fetching members:", error);
//     //   }
//     // };

//     const fetchMembers = async () => {
//       try {
//         const response = await axios.get("https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/fetch-basic-info");
//         // Parse the response data since it's in a nested JSON string format
//         const data = JSON.parse(response.data.body);
//         setMembers(data.basicInfo); // Set members to the parsed basicInfo
//         setFilteredMembers(data.basicInfo); // Set filtered members to the same
//       } catch (error) {
//         console.error("Error fetching members:", error);
//       }
//     };


//     fetchMembers();
//   }, []);

//   const handleSearchOptionChange = (e) => {
//     setSearchOption(e.target.value);
//   };

//   const handleSearchInputChange = (e) => {
//     setSearchInput(e.target.value);
//   };

//   const handleSearch = () => {
//     if (searchInput === "") {
//       setFilteredMembers(members);
//     } else {
//       const filtered = members.filter((member) => {
//         switch (searchOption) {
//           case "Name":
//             return member.name.toLowerCase().includes(searchInput.toLowerCase());
//           case "Email":
//             return member.email.toLowerCase().includes(searchInput.toLowerCase());
//           case "Member ID":
//             return member.memberId.toLowerCase().includes(searchInput.toLowerCase());
//           case "Mobile":
//             return member.phone.toLowerCase().includes(searchInput.toLowerCase());
//           case "Card Ref":
//             return member.cardRef.toLowerCase().includes(searchInput.toLowerCase());
//           case "Account ID":
//             return member.accountId.toLowerCase().includes(searchInput.toLowerCase());
//           default:
//             return false;
//         }
//       });
//       setFilteredMembers(filtered);
//     }
//     setPage(0); // Reset to the first page after search
//   };

//   // Handle page change
//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   // Handle rows per page change
//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0); // Reset to the first page when changing rows per page
//   };


//   const currentMembers = (filteredMembers || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


//   return (
//     <Container fluid>
//       <Row className="ps-4">
//         <Col xs={12} md={{ span: 11 }}>
//           <Card className="mt-4 mb-4 custom-shadow">
//             <Card.Body>
//               <Row className="d-flex align-items-center">
//                 <Col md={6}>
//                   <h4 className="search-heading">Search for Members</h4>
//                 </Col>
//                 <Col md={6} className="text-end">


//                   {memberPermissions?.actions?.add && (
//                     <Button
//                       variant="success"
//                       className="custom-success-btn me-2"
//                       onClick={handleAddMember}
//                     >
//                       Add
//                     </Button>
//                   )}


//                 </Col>
//               </Row>

//               <hr />

//               <Row className="d-flex align-items-center mt-3">
//                 <Col md={4}>
//                   <Form.Select
//                     aria-label="Member ID Select"
//                     className="custom-select me-2"
//                     style={{ height: "29px", padding: "0 8px" }}
//                     value={searchOption}
//                     onChange={handleSearchOptionChange}
//                   >
//                     <option value="Name">Name</option>
//                     <option value="Email">Email</option>
//                     <option value="Member ID">Member ID</option>
//                     <option value="Mobile">Mobile</option>
//                     <option value="Card Ref">Card Ref</option>
//                     <option value="Account ID">Account ID</option>
//                   </Form.Select>
//                 </Col>
//                 <Col md={3} className="ps-1 ms-3">
//                   <Form.Control
//                     type="text"
//                     className="custom-search-member me-2"
//                     style={{ height: "29px", padding: "0 8px" }}
//                     value={searchInput}
//                     onChange={handleSearchInputChange}
//                   />
//                 </Col>
//                 <Col md={2} className="text-end">
//                   <Button
//                     variant="success"
//                     className="custom-success-btn me-5"
//                     onClick={handleSearch}
//                   >
//                     Search
//                   </Button>
//                 </Col>
//               </Row>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       <Row className="ps-4 mt-5">
//         <Col xs={12}>
//           <h4 className="member-result">Result</h4>
//         </Col>
//       </Row>
//       <hr />

//       <Row className="ps-4">
//         <Col xs={12} md={{ span: 11 }}>
//           <Table striped hover responsive className="centered-table">
//             <thead>
//               <tr>
//                 <th className="custom-th">Member Name</th>
//                 <th className="custom-th">Joined Date</th>
//                 <th className="custom-th">Mobile</th>
//                 <th className="custom-th">Member ID</th>
//                 <th className="custom-th">Card Ref.</th>
//                 <th className="custom-th">Email</th>
//                 <th className="custom-th">App 1st Login</th>
//                 <th className="custom-th">Member Category</th>
//                 <th className="custom-th">Function(s)</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentMembers.length > 0 ? (
//                 currentMembers.map((member, index) => (
//                   <tr key={index}>
//                     <td className="custom-td">{member.name}</td>
//                     <td className="custom-td">{member.joinedDate}</td>
//                     <td className="custom-td">{member.phone}</td>
//                     <td className="custom-td">{member.memberId}</td>
//                     <td className="custom-td">{member.cardRef}</td>
//                     <td className="custom-td">{member.email}</td>
//                     <td className="custom-td">{member.firstLogin}</td>
//                     <td className="custom-td">Category</td>
//                     <td className="custom-td">
//                       {memberPermissions?.actions?.edit && (
//                         <Button variant="success" className="custom-success-btn me-2" onClick={handleAddMember}>
//                           Edit
//                         </Button>
//                       )}

//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="9" className="text-center">No members found</td>
//                 </tr>
//               )}
//             </tbody>
//           </Table>

//           {/* Pagination */}
//           <TablePagination
//             component="div"
//             // count={filteredMembers.length}
//             count={(filteredMembers || []).length}

//             page={page}
//             onPageChange={handleChangePage}
//             rowsPerPage={rowsPerPage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//             rowsPerPageOptions={[5, 10, 25]}
//             className='mt-5'
//           />
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default Member;




//-------------------------------------------------------------------------------------------------------------------------



import React, { useState, useEffect } from "react";
import { Form, Button, Table, Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./membercss/Member.css";
import { useSelector } from 'react-redux';

const Member = () => {
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [searchOption, setSearchOption] = useState("Name");
  const [searchInput, setSearchInput] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const [memberPermissions, setMemberPermissions] = useState(null);
  const { UserInfo = {} } = useSelector(state => state?.users);

  useEffect(() => {
    const permissions = UserInfo?.permission?.permissions || [];
    const validPermissions = permissions.filter(({ actions }) =>
      Object.values(actions).some(Boolean)
    );
    const memberPermissions = validPermissions?.find((p) => p?.ModuleName === "Member Management");
    setMemberPermissions(memberPermissions);
  }, [UserInfo]);

  const handleAddMember = () => {
    navigate("/member/memberinfo");
  };

  const handleEditMember = (member) => {
    navigate("/member/memberinfo", { state: { member } });
  };

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get("https://bq7yem1vw8.execute-api.ap-east-1.amazonaws.com/dev/fetch-basic-info");
        const data = JSON.parse(response.data.body);
        setMembers(data.basicInfo);
        setFilteredMembers(data.basicInfo);
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };
    fetchMembers();
  }, []);

  const handleSearchOptionChange = (e) => {
    setSearchOption(e.target.value);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearch = () => {
    if (searchInput === "") {
      setFilteredMembers(members);
    } else {
      const filtered = members.filter((member) => {
        switch (searchOption) {
          case "Name":
            return member.name.toLowerCase().includes(searchInput.toLowerCase());
          case "Email":
            return member.email.toLowerCase().includes(searchInput.toLowerCase());
          case "Member ID":
            return member.memberId.toLowerCase().includes(searchInput.toLowerCase());
          case "Mobile":
            return member.phone.toLowerCase().includes(searchInput.toLowerCase());
          case "Card Ref":
            return member.cardRef.toLowerCase().includes(searchInput.toLowerCase());
          case "Account ID":
            return member.accountId.toLowerCase().includes(searchInput.toLowerCase());
          default:
            return false;
        }
      });
      setFilteredMembers(filtered);
    }
    setPage(0);
  };

  const handlePaginationClick = (index) => {
    setPage(index);
  };

  const currentMembers = filteredMembers.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  const totalPages = Math.ceil(filteredMembers.length / rowsPerPage);

  return (
    <Container fluid>
      <Row className="ps-4">
        <Col xs={12} md={{ span: 11 }}>
          <Card className="mt-4 mb-4 custom-shadow">
            <Card.Body>
              <Row className="d-flex align-items-center">
                <Col md={6}>
                  <h4 className="search-heading">Search for Members</h4>
                </Col>
                <Col md={6} className="text-end">
                  {memberPermissions?.actions?.add && (
                    <Button
                      variant="success"
                      className="custom-success-btn me-2"
                      onClick={handleAddMember}
                    >
                      Add
                    </Button>
                  )}
                </Col>
              </Row>

              <hr />

              <Row className="d-flex align-items-center mt-3">
                <Col md={4}>
                  <Form.Select
                    aria-label="Member ID Select"
                    className="custom-select me-2"
                    style={{ height: "29px", padding: "0 8px" }}
                    value={searchOption}
                    onChange={handleSearchOptionChange}
                  >
                    <option value="Name">Name</option>
                    <option value="Email">Email</option>
                    <option value="Member ID">Member ID</option>
                    <option value="Mobile">Mobile</option>
                    <option value="Card Ref">Card Ref</option>
                    <option value="Account ID">Account ID</option>
                  </Form.Select>
                </Col>
                <Col md={3} className="ps-1 ms-3">
                  <Form.Control
                    type="text"
                    className="custom-search-member me-2"
                    style={{ height: "29px", padding: "0 8px" }}
                    value={searchInput}
                    onChange={handleSearchInputChange}
                  />
                </Col>
                <Col md={2} className="text-end">
                  <Button
                    variant="success"
                    className="custom-success-btn me-5"
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="ps-4 mt-5">
        <Col xs={12}>
          <h4 className="member-result">Result</h4>
        </Col>
      </Row>
      <hr />

      <Row className="ps-4">
        <Col xs={12} md={{ span: 11 }}>
          <Table striped hover responsive className="centered-table">
            <thead>
              <tr>
                <th className="custom-th">Member Name</th>
                <th className="custom-th">Joined Date</th>
                <th className="custom-th">Mobile</th>
                <th className="custom-th">Member ID</th>
                <th className="custom-th">Card Ref.</th>
                <th className="custom-th">Email</th>
                <th className="custom-th">App 1st Login</th>
                <th className="custom-th">Member Category</th>
                <th className="custom-th">Function(s)</th>
              </tr>
            </thead>
            <tbody>
              {currentMembers.length > 0 ? (
                currentMembers.map((member, index) => (
                  <tr key={index}>
                    <td className="custom-td">{member.name}</td>
                    <td className="custom-td">{member.joinedDate}</td>
                    <td className="custom-td">{member.phone}</td>
                    <td className="custom-td">{member.memberId}</td>
                    <td className="custom-td">{member.cardRef}</td>
                    <td className="custom-td">{member.email}</td>
                    <td className="custom-td">{member.firstLogin}</td>
                    <td className="custom-td">Category</td>
                    <td className="custom-td">
                      {memberPermissions?.actions?.edit && (
                        <Button
                          variant="success"
                          className="custom-success-btn me-2"
                          onClick={() => handleEditMember(member)}
                        >
                          Edit
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">No members found</td>
                </tr>
              )}
            </tbody>
          </Table>

          <nav aria-label="Page navigation example" className="mt-5">
            <ul className="pagination justify-content-end">
              <li className={`page-item ${page === 0 ? "disabled" : ""}`}>
                <a
                  className="page-link"
                  href="#"
                  onClick={() => handlePaginationClick(page - 1)}
                  aria-disabled={page === 0}
                >
                  Previous
                </a>
              </li>
              {Array.from({ length: totalPages }, (_, idx) => (
                <li
                  key={idx}
                  className={`page-item ${idx === page ? "active" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePaginationClick(idx)}
                  >
                    {idx + 1}
                  </a>
                </li>
              ))}
              <li
                className={`page-item ${page === totalPages - 1 ? "disabled" : ""
                  }`}
              >
                <a
                  className="page-link"
                  href="#"
                  onClick={() => handlePaginationClick(page + 1)}
                  aria-disabled={page === totalPages - 1}
                >
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </Col>
      </Row>
    </Container>
  );
};

export default Member;
