


import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./rolemanagemntcss/RoleManagementNew.css";
import * as uuid from "uuid";
import axios from "axios"; // Axios for making HTTP requests

const RoleManagementNew = ({ show, handleClose, modalTitle, roleData, fetchRoles }) => {
  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState({
    "Role Management": { read: false, add: false, edit: false, delete: false },
    "User Management": { read: false, add: false, edit: false, delete: false },
    "Member Management": { read: false, add: false, edit: false, delete: false },
    "User Defined Categories": { read: false, add: false, edit: false, delete: false },
    "Push Notification": { read: false, edit: false, create: false, delete: false, manage: false },
    "Campaign Management": { read: false, edit: false, create: false, delete: false, manage: false },
    "Reports": { read: false, download: false }
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const convertToObject = (permissionsArray) => {
    const result = {};
    permissionsArray.forEach(({ ModuleName, actions }) => {
      result[ModuleName] = actions;
    });
    return result;
  };

  useEffect(() => {
    if (roleData) {
      setRoleName(roleData.role_name);
      setPermissions(convertToObject(roleData.permissions) || {});
    } else {
      resetFields();
    }
  }, [roleData]);

  const resetFields = () => {
    setRoleName("");
    setPermissions({
      "Role Management": { read: false, add: false, edit: false, delete: false },
      "User Management": { read: false, add: false, edit: false, delete: false },
      "Member Management": { read: false, add: false, edit: false, delete: false },
      "User Defined Categories": { read: false, add: false, edit: false, delete: false },
      "Push Notification": { read: false, edit: false, create: false, delete: false, manage: false },
      "Campaign Management": { read: false, edit: false, create: false, delete: false, manage: false },
      "Reports": { read: false, download: false }
    });
  };

  const handleChange = (category, e) => {
    const { name, checked } = e.target;

    if (name === "delete" && checked && !["Push Notification", "Campaign Management"].includes(category)) {
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        [category]: {
          ...prevPermissions[category],
          delete: true,
          read: true,
          add: true,
          edit: true
        }
      }));
    } else if (name === "delete" && !checked) {
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        [category]: {
          ...prevPermissions[category],
          delete: false
        }
      }));
    } else if (name === "manage" && checked && ["Push Notification", "Campaign Management"].includes(category)) {
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        [category]: {
          ...prevPermissions[category],
          manage: true,
          read: true,
          edit: true,
          create: true,
          delete: true
        }
      }));
    } else if (name === "manage" && !checked && ["Push Notification", "Campaign Management"].includes(category)) {
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        [category]: {
          ...prevPermissions[category],
          manage: false
        }
      }));
    } else {
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        [category]: {
          ...prevPermissions[category],
          [name]: checked
        }
      }));
    }
  };



  // const handleSave = async () => {
  //   setErrorMessage(""); // Clear any previous error
  //   setSuccessMessage(""); // Clear any previous success message

  //   const payload = {
  //     pk_id: `us:ROLE001`,
  //     role_display_name: roleName,
  //     role_name: roleName,
  //     is_active: true,
  //     is_super: false,
  //     role_id: `${uuid.v4()}`,
  //     permissions: Object.entries(permissions).map(([moduleName, actions], index) => ({
  //       actions,
  //       ModuleId: `Mo:MOD${String(index + 1).padStart(3, '0')}`, // Generate ModuleId with unique number
  //       ModuleName: moduleName
  //     })),
  //     user_id: null
  //   };

  //   try {
  //     if (roleData) {
  //       // Update existing role
  //       const update_payload = {
  //         pk_id: roleData.pk_id,
  //         sk_id: roleData.sk_id,
  //         role_display_name: roleName,
  //         role_name: roleName,
  //         is_active: roleData.is_active ?? true,
  //         is_super: false,
  //         permissions: Object.entries(permissions).map(([moduleName, actions], index) => ({
  //           actions,
  //           ModuleId: `Mo:MOD${String(index + 1).padStart(3, '0')}`,
  //           ModuleName: moduleName
  //         })),
  //       };
  //       await axios.post(process.env.REACT_APP_BASE_API_URL + "/edit-new-roles", update_payload);
  //       fetchRoles && fetchRoles();
  //       setSuccessMessage("Role updated successfully!");
  //       resetFields();
  //       handleClose();
  //     } else {
  //       // Add new role
  //       await axios.post(process.env.REACT_APP_BASE_API_URL + "/add-new-role",
  //         payload
  //       );
  //       fetchRoles && fetchRoles();

  //       if (!errorMessage) { // Success message only if no error
  //         setSuccessMessage("Role added successfully!");

  //       }

  //       resetFields();
  //       handleClose();
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 409) {
  //       setErrorMessage("Role already exists."); // Set duplicate role error
  //       setSuccessMessage(""); // Clear success if duplicate found
  //     } else {
  //       console.error("Error saving role:", error);
  //       setErrorMessage("An error occurred while saving the role.");
  //     }
  //   }
  // };

  const handleSave = async () => {
    setErrorMessage(""); // Clear any previous error
    setSuccessMessage(""); // Clear any previous success message

    const payload = {
      pk_id: `us:ROLE001`,
      role_display_name: roleName,
      role_name: roleName,
      is_active: true,
      is_super: false,
      role_id: `${uuid.v4()}`,
      permissions: Object.entries(permissions).map(([moduleName, actions], index) => ({
        actions,
        ModuleId: `Mo:MOD${String(index + 1).padStart(3, '0')}`, // Generate ModuleId with unique number
        ModuleName: moduleName,
      })),
      user_id: null,
    };

    try {
      if (roleData) {
        // Update existing role
        const update_payload = {
          pk_id: roleData.pk_id,
          sk_id: roleData.sk_id,
          role_display_name: roleName,
          role_name: roleName,
          is_active: roleData.is_active ?? true,
          is_super: false,
          permissions: Object.entries(permissions).map(([moduleName, actions], index) => ({
            actions,
            ModuleId: `Mo:MOD${String(index + 1).padStart(3, '0')}`,
            ModuleName: moduleName,
          })),
        };
        await axios.post(process.env.REACT_APP_BASE_API_URL + "/edit-new-roles", update_payload);
        fetchRoles && fetchRoles();
        setSuccessMessage("Role updated successfully!");
        setTimeout(() => setSuccessMessage(""), 2000); // Automatically hide success message
        resetFields();
        handleClose();
      } else {
        // Add new role
        await axios.post(process.env.REACT_APP_BASE_API_URL + "/add-new-role", payload);
        fetchRoles && fetchRoles();

        if (!errorMessage) { // Success message only if no error
          setSuccessMessage("Role added successfully!");
          setTimeout(() => setSuccessMessage(""), 2000); // Automatically hide success message
        }

        resetFields();
        handleClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setErrorMessage("Role already exists."); // Set duplicate role error
        setSuccessMessage(""); // Clear success if duplicate found
      } else {
        console.error("Error saving role:", error);
        setErrorMessage("An error occurred while saving the role.");
      }
    }
  };


  const handleModalClose = () => {
    resetFields();
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleModalClose} centered size="md" className="custom-modal">
        <Modal.Body>
          <Form>
            <h4 className="maintitle">{modalTitle}</h4>

            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

            <Form.Group controlId="formRoleName" className="floating-label-group">
              <Form.Control
                type="text"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                className="floating-input"
                required
              />
              <Form.Label className="floating-label">Role Name</Form.Label>
            </Form.Group>

            {Object.keys(permissions).map((category) => (
              <div key={category} className="mt-4">
                <Form.Label className="lablecheck">{category}</Form.Label>
                <div className="checkbox-group">
                  {Object.keys(permissions[category]).map((permission) => (
                    <Form.Check
                      key={permission}
                      type="checkbox"
                      label={permission.charAt(0).toUpperCase() + permission.slice(1)}
                      name={permission}
                      className="checkbtn"
                      checked={permissions[category][permission] || false}
                      onChange={(e) => handleChange(category, e)}
                    />
                  ))}
                </div>
              </div>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {successMessage && (
        <div className="success-popup">
          <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
            {successMessage}
          </Alert>
        </div>
      )}
    </>
  );
};

export default RoleManagementNew;
