



// import React, { useState, useEffect } from 'react';
// import { Modal, Button, Form, Alert } from 'react-bootstrap';
// import axios from 'axios'; // Import axios
// import * as uuid from "uuid";
// import 'bootstrap/dist/css/bootstrap.min.css';

// const UserManagementNew = ({ show, handleClose, modalTitle, userData, fetchUsers }) => {
//   const [accountName, setAccountName] = useState('');
//   const [login, setLogin] = useState('');
//   const [password, setPassword] = useState('');
//   const [role, setRole] = useState('');
//   const [pk_id, setPkId] = useState(''); // State for pk_id
//   const [sk_id, setSkId] = useState(''); // State for sk_id
//   const [errorMessage, setErrorMessage] = useState(''); // State for error message
//   const [successMessage, setSuccessMessage] = useState(''); // State for success message
//   const [roles, setRoles] = useState([]); // State for roles from API

//   useEffect(() => {
//     if (userData) {
//       setAccountName(userData.user_name);
//       setLogin(userData.user_login);
//       setPassword(userData.user_password);
//       setRole(userData.role_name);
//       setPkId(userData.pk_id); // Set pk_id for editing
//       setSkId(userData.sk_id); // Set sk_id for editing
//     } else {
//       // Reset the form fields if no user is selected
//       setAccountName('');
//       setLogin('');
//       setPassword('');
//       setRole('');
//       setPkId('');
//       setSkId('');
//     }
//   }, [userData]);


//   const fetchRoles = async () => {
//     try {
//       const response = await axios.get(process.env.REACT_APP_BASE_API_URL + "/get-new-roles");
//       const rolesData = response.data.body ? JSON.parse(response.data.body) : [];
//       const filteredRoles = rolesData.filter(role => role.role_name !== "SuperAdmin" && role.role_name !== "Super Admin");
//       setRoles(Array.isArray(filteredRoles) ? filteredRoles : []);
//     } catch (error) {
//       console.error('Error fetching roles:', error);
//       setRoles([]);
//     }
//   };


//   useEffect(() => {
//     fetchRoles();
//     const interval = setInterval(fetchRoles, 5000); // Fetch every 5 seconds
//     return () => clearInterval(interval);
//   }, []);


//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format regex
//     return emailRegex.test(email);
//   };

//   const handleSave = async () => {
//     // Validate email
//     if (!validateEmail(login)) {
//       setErrorMessage('Please enter a valid email address.');
//       return; // Prevent form submission if validation fails
//     }

//     // Validate password
//     const validationMessage = validatePassword();
//     if (validationMessage) {
//       setErrorMessage(validationMessage);
//       return; // Prevent form submission if validation fails
//     }


//     try {
//       if (pk_id && sk_id) {
//         const edit_user_payload = {
//           pk_id: pk_id,
//           sk_id: sk_id,
//           admin_login: "N/A",
//           admin_name: null,
//           admin_password: null,
//           is_admin: false,
//           role_id: roles.find(r => r.role_name === role)?.role_id,
//           role_name: role,
//           user_is_active: userData?.user_is_active ?? true,
//           user_login: login,
//           user_name: accountName,
//           user_password: password
//         };
//         // If editing, send POST request for editing the user
//         await axios.post(process.env.REACT_APP_BASE_API_URL + "/edit-new-user", edit_user_payload);
//         console.log("User updated successfully");
//         setSuccessMessage('User updated successfully!');
//       } else {
//         // If creating, send POST request for adding the user
//         const payload = {
//           pk_id: `us:USER001`,
//           admin_login: "N/A",
//           admin_name: null,
//           admin_password: null,
//           is_admin: false,
//           role_id: roles.find(r => r.role_name === role)?.role_id,
//           role_name: role,
//           user_id: `${uuid.v4()}`,
//           user_is_active: true,
//           user_login: login,
//           user_name: accountName,
//           user_password: password,
//           user_id: null
//         }
//         const { data } = await axios.post(process.env.REACT_APP_BASE_API_URL + "/add-new-user", payload);
//         if (data && data.statusCode === 409) {
//           return alert("User already exists");
//         }
//         console.log("User added successfully");
//         setSuccessMessage('User added successfully!');
//       }

//       fetchUsers && fetchUsers(); // Refresh the user list

//       // Show success message and clear form
//       setTimeout(() => setSuccessMessage(''), 5000); // Hide after 5 seconds
//       handleClose(); // Close modal on successful save
//       setAccountName('');
//       setLogin('');
//       setPassword('');
//       setRole('');
//       setErrorMessage(''); // Clear error message on success
//     } catch (error) {
//       console.error('Error saving account:', error);
//     }
//   };

//   const validatePassword = () => {
//     // Regex to check if password contains at least 1 lowercase, 1 uppercase, and 1 number
//     const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
//     const emailPrefix = login.split('@')[0]; // Extract the email prefix before '@'

//     if (!passwordRegex.test(password)) {
//       return 'Password must be at least 8 characters long and include at least 1 lowercase letter, 1 uppercase letter, and 1 number.';
//     }

//     if (password === emailPrefix) {
//       return 'Password cannot be the same as the email prefix.';
//     }

//     return ''; // Return an empty string if validation passes
//   };

//   return (
//     <>
//       <Modal show={show} onHide={handleClose} centered size="md" className="custom-modal">
//         <Modal.Body>
//           <Form>
//             <h4 className="maintitle">{modalTitle}</h4>

//             {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} {/* Show error message */}
//             {successMessage && <Alert variant="success">{successMessage}</Alert>} {/* Show success message */}

//             <Form.Group controlId="formAccountName" className="floating-label-group">
//               <Form.Control
//                 type="text"
//                 value={accountName}
//                 onChange={(e) => setAccountName(e.target.value)}
//                 className="floating-input"
//                 required
//               />
//               <Form.Label className="floating-label">Account Name</Form.Label>
//             </Form.Group>
//             {/*             
//             <Form.Group controlId="formLogin" className="floating-label-group">
//               <Form.Control
//                 type="email"
//                 value={login}
//                 onChange={(e) => setLogin(e.target.value)}
//                 className="floating-input"
//                 required
//               />
//               <Form.Label className="floating-label">Login</Form.Label>
//             </Form.Group> */}

//             <Form.Group controlId="formLogin" className="floating-label-group">
//               <Form.Control
//                 type="email"
//                 value={login}
//                 onChange={(e) => setLogin(e.target.value)}
//                 className="floating-input"
//                 required
//                 disabled={!!pk_id && !!sk_id} // Disable email input when editing an existing user
//               />
//               <Form.Label className="floating-label">Login</Form.Label>
//             </Form.Group>


//             <Form.Group controlId="formPassword" className="floating-label-group">
//               <Form.Control
//                 type="password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 className="floating-input"
//                 required
//               // disabled={!!pk_id && !!sk_id}
//               />
//               <Form.Label className="floating-label">Password</Form.Label>
//             </Form.Group>
//             <Form.Group controlId="formRole" className="floating-label-group">
//               <Form.Select
//                 value={role}
//                 onChange={(e) => setRole(e.target.value)}
//                 className="floating-input"
//                 required
//               >
//                 <option value="">Select Role</option>
//                 {roles.map((roleItem) => (
//                   <option key={roleItem.pk_id} value={roleItem.role_name}>
//                     {roleItem.role_name}
//                   </option>
//                 ))}
//               </Form.Select>
//               <Form.Label className="floating-label">Role</Form.Label>
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Cancel
//           </Button>
//           <Button variant="primary" onClick={handleSave}>
//             Save
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default UserManagementNew;



















import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios'; // Import axios
import * as uuid from "uuid";
import 'bootstrap/dist/css/bootstrap.min.css';

const UserManagementNew = ({ show, handleClose, modalTitle, userData, fetchUsers }) => {
  const [accountName, setAccountName] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [pk_id, setPkId] = useState(''); // State for pk_id
  const [sk_id, setSkId] = useState(''); // State for sk_id
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const [roles, setRoles] = useState([]); // State for roles from API
  const [isSaving, setIsSaving] = useState(false); // State to track saving status

  useEffect(() => {
    if (userData) {
      setAccountName(userData.user_name);
      setLogin(userData.user_login);
      setPassword(userData.user_password);
      setRole(userData.role_name);
      setPkId(userData.pk_id); // Set pk_id for editing
      setSkId(userData.sk_id); // Set sk_id for editing
    } else {
      // Reset the form fields if no user is selected
      setAccountName('');
      setLogin('');
      setPassword('');
      setRole('');
      setPkId('');
      setSkId('');
    }
  }, [userData]);

  const fetchRoles = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_API_URL + "/get-new-roles");
      const rolesData = response.data.body ? JSON.parse(response.data.body) : [];
      const filteredRoles = rolesData.filter(role => role.role_name !== "SuperAdmin" && role.role_name !== "Super Admin");
      setRoles(Array.isArray(filteredRoles) ? filteredRoles : []);
    } catch (error) {
      console.error('Error fetching roles:', error);
      setRoles([]);
    }
  };

  useEffect(() => {
    fetchRoles();
    const interval = setInterval(fetchRoles, 5000); // Fetch every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format regex
    return emailRegex.test(email);
  };

  const validatePassword = () => {
    // Regex to check if password contains at least 1 lowercase, 1 uppercase, and 1 number
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const emailPrefix = login.split('@')[0]; // Extract the email prefix before '@'

    if (!passwordRegex.test(password)) {
      return 'Password must be at least 8 characters long and include at least 1 lowercase letter, 1 uppercase letter, and 1 number.';
    }

    if (password === emailPrefix) {
      return 'Password cannot be the same as the email prefix.';
    }

    return ''; // Return an empty string if validation passes
  };

  // const handleSave = async () => {
  //   if (isSaving) return; // Prevent multiple submissions

  //   // Validate email
  //   if (!validateEmail(login)) {
  //     setErrorMessage('Please enter a valid email address.');
  //     return; // Prevent form submission if validation fails
  //   }

  //   // Validate password
  //   const validationMessage = validatePassword();
  //   if (validationMessage) {
  //     setErrorMessage(validationMessage);
  //     return; // Prevent form submission if validation fails
  //   }

  //   setIsSaving(true); // Set saving state to true

  //   try {
  //     if (pk_id && sk_id) {
  //       const edit_user_payload = {
  //         pk_id: pk_id,
  //         sk_id: sk_id,
  //         admin_login: "N/A",
  //         admin_name: null,
  //         admin_password: null,
  //         is_admin: false,
  //         role_id: roles.find(r => r.role_name === role)?.role_id,
  //         role_name: role,
  //         user_is_active: userData?.user_is_active ?? true,
  //         user_login: login,
  //         user_name: accountName,
  //         user_password: password
  //       };
  //       await axios.post(process.env.REACT_APP_BASE_API_URL + "/edit-new-user", edit_user_payload);
  //       setSuccessMessage('User updated successfully!');
  //     } else {
  //       const payload = {
  //         pk_id: `us:USER001`,
  //         admin_login: "N/A",
  //         admin_name: null,
  //         admin_password: null,
  //         is_admin: false,
  //         role_id: roles.find(r => r.role_name === role)?.role_id,
  //         role_name: role,
  //         user_id: `${uuid.v4()}`,
  //         user_is_active: true,
  //         user_login: login,
  //         user_name: accountName,
  //         user_password: password
  //       };
  //       const { data } = await axios.post(process.env.REACT_APP_BASE_API_URL + "/add-new-user", payload);
  //       if (data && data.statusCode === 409) {
  //         setErrorMessage("User already exists");
  //         return;
  //       }
  //       setSuccessMessage('User added successfully!');
  //     }

  //     fetchUsers && fetchUsers(); // Refresh the user list
  //     setTimeout(() => setSuccessMessage(''), 5000); // Hide success message after 5 seconds
  //     handleClose(); // Close modal on successful save
  //     setAccountName('');
  //     setLogin('');
  //     setPassword('');
  //     setRole('');
  //     setErrorMessage(''); // Clear error message on success
  //   } catch (error) {
  //     console.error('Error saving account:', error);
  //     setErrorMessage('Failed to save user. Please try again.');
  //   } finally {
  //     setIsSaving(false); // Reset saving state
  //   }
  // };


  const handleSave = async () => {
    if (isSaving) return; // Prevent multiple submissions

    // Validate account name
    if (!accountName.trim()) {
      setErrorMessage('Account Name is required.');
      return; // Prevent form submission if validation fails
    }

    // Validate email
    if (!validateEmail(login)) {
      setErrorMessage('Please enter a valid email address.');
      return; // Prevent form submission if validation fails
    }

    // Validate password
    const validationMessage = validatePassword();
    if (validationMessage) {
      setErrorMessage(validationMessage);
      return; // Prevent form submission if validation fails
    }

    setIsSaving(true); // Set saving state to true

    try {
      if (pk_id && sk_id) {
        const edit_user_payload = {
          pk_id: pk_id,
          sk_id: sk_id,
          admin_login: "N/A",
          admin_name: null,
          admin_password: null,
          is_admin: false,
          role_id: roles.find(r => r.role_name === role)?.role_id,
          role_name: role,
          user_is_active: userData?.user_is_active ?? true,
          user_login: login,
          user_name: accountName,
          user_password: password
        };
        await axios.post(process.env.REACT_APP_BASE_API_URL + "/edit-new-user", edit_user_payload);
        setSuccessMessage('User updated successfully!');
      } else {
        const payload = {
          pk_id: `us:USER001`,
          admin_login: "N/A",
          admin_name: null,
          admin_password: null,
          is_admin: false,
          role_id: roles.find(r => r.role_name === role)?.role_id,
          role_name: role,
          user_id: `${uuid.v4()}`,
          user_is_active: true,
          user_login: login,
          user_name: accountName,
          user_password: password
        };
        const { data } = await axios.post(process.env.REACT_APP_BASE_API_URL + "/add-new-user", payload);
        if (data && data.statusCode === 409) {
          setErrorMessage("User already exists");
          return;
        }
        setSuccessMessage('User added successfully!');
      }

      fetchUsers && fetchUsers(); // Refresh the user list
      setTimeout(() => setSuccessMessage(''), 5000); // Hide success message after 5 seconds
      handleClose(); // Close modal on successful save
      setAccountName('');
      setLogin('');
      setPassword('');
      setRole('');
      setErrorMessage(''); // Clear error message on success
    } catch (error) {
      console.error('Error saving account:', error);
      setErrorMessage('Failed to save user. Please try again.');
    } finally {
      setIsSaving(false); // Reset saving state
    }
  };











  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="md" className="custom-modal">
        <Modal.Body>
          <Form>
            <h4 className="maintitle">{modalTitle}</h4>

            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} {/* Show error message */}
            {successMessage && <Alert variant="success">{successMessage}</Alert>} {/* Show success message */}

            <Form.Group controlId="formAccountName" className="floating-label-group">
              <Form.Control
                type="text"
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                className="floating-input"
                required
              />
              <Form.Label className="floating-label">Account Name</Form.Label>
            </Form.Group>

            <Form.Group controlId="formLogin" className="floating-label-group">
              <Form.Control
                type="email"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                className="floating-input"
                required
                disabled={!!pk_id && !!sk_id} // Disable email input when editing an existing user
              />
              <Form.Label className="floating-label">Login</Form.Label>
            </Form.Group>

            <Form.Group controlId="formPassword" className="floating-label-group">
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="floating-input"
                required
              />
              <Form.Label className="floating-label">Password</Form.Label>
            </Form.Group>

            <Form.Group controlId="formRole" className="floating-label-group">
              <Form.Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="floating-input"
                required
              >
                <option value="">Select Role</option>
                {roles.map((roleItem) => (
                  <option key={roleItem.pk_id} value={roleItem.role_name}>
                    {roleItem.role_name}
                  </option>
                ))}
              </Form.Select>
              <Form.Label className="floating-label">Role</Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserManagementNew;
