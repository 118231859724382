import React, { useState } from "react";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import './campaigncss/BasicInfo.css'

function BasicInfo() {
    const [inputValues, setInputValues] = useState({
        campaignName: "", // For the Campaign Name dropdowns
        subsidiaryNameChinese: "一起來吧！",  // Provided value for Subsidiary Name (Chinese)
        subsidiaryNameEnglish: "Join Now!",   // Provided value for Subsidiary Name (English)
        introductionChinese: "學員會在導師指導下讓學員練唱，以提升學習水平。", // Provided value for Introduction (Chinese)
        introductionEnglish: "Members will be asked to practice singing under the guidance of instructors to improve their learning level." // Provided value for Introduction (English)
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    return (
        <Container fluid>
            <Row>

                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Card.Body>
                        <Form>
                            <Row>
                                <h5 className="basicheading">Basic Information</h5>
                                <Col md={6}>
                                    {/* Campaign Name Dropdown (Chinese) */}
                                    <Form.Group controlId="campaignNameChinese" className="floating-label-group">
                                        <Form.Select
                                            name="campaignName"
                                            value={inputValues.campaignName}
                                            onChange={handleChange}
                                            className="floating-input"
                                        >
                                            <option value="粵曲班">粵曲班</option>
                                            <option value="綜合太極班">綜合太極班</option>
                                            <option value="活力排舞初班">活力排舞初班</option>
                                            <option value="駿伴歷行-做自己的退休策劃師">駿伴歷行 - 做自己的退休策劃師</option>
                                            <option value="Harmonica閑琴逸趣">Harmonica閑琴逸趣</option>
                                            <option value="萌薑樂園-我係「金菠蘿」">萌薑樂園 - 我係「金菠蘿」</option>
                                            <option value="朦朦朧朧無聲體驗活動">朦朦朧朧無聲體驗活動</option>
                                            <option value="「齡」距離潮流重塑">「齡」距離潮流重塑</option>
                                            <option value="童心伴長者">童心伴長者</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">Campaign Name (Chinese)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    {/* Campaign Name Dropdown (English) */}
                                    <Form.Group controlId="campaignNameEnglish" className="floating-label-group">
                                        <Form.Select
                                            name="campaignName"
                                            value={inputValues.campaignName}
                                            onChange={handleChange}
                                            className="floating-input"
                                        >
                                            <option value="Cantonese Opera Class">Cantonese Opera Class</option>
                                            <option value="Traditional Tai Chi Beginning Class">Traditional Tai Chi Beginning Class</option>
                                            <option value="Line Dance Beginner Class">Line Dance Beginner Class</option>
                                            <option value="Be Your Own Retirement Planner">Be Your Own Retirement Planner</option>
                                            <option value="Leisure Harmonica Class">Leisure Harmonica Class</option>
                                            <option value="Prize-giving Activities">Prize-giving Activities</option>
                                            <option value="Hazy Silent Experience Activities">Hazy Silent Experience Activities</option>
                                            <option value="Fashion & Image Design Class">Fashion & Image Design Class</option>
                                            <option value="Activities to Accompany the Elderly">Activities to Accompany the Elderly</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">Campaign Name (English)</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    {/* Subsidiary Name (Chinese) */}
                                    <Form.Group controlId="subsidiaryNameChinese" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="subsidiaryNameChinese"
                                            value={inputValues.subsidiaryNameChinese}
                                            onChange={handleChange}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">Subsidiary Name (Chinese)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    {/* Subsidiary Name (English) */}
                                    <Form.Group controlId="subsidiaryNameEnglish" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="subsidiaryNameEnglish"
                                            value={inputValues.subsidiaryNameEnglish}
                                            onChange={handleChange}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">Subsidiary Name (English)</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    {/* Introduction (Chinese) */}
                                    <Form.Group controlId="introductionChinese" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="introductionChinese"
                                            value={inputValues.introductionChinese}
                                            onChange={handleChange}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">Introduction (Chinese)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    {/* Introduction (English) */}
                                    <Form.Group controlId="introductionEnglish" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="introductionEnglish"
                                            value={inputValues.introductionEnglish}
                                            onChange={handleChange}
                                            className="floating-input"
                                        />
                                        <Form.Label className="floating-label">Introduction (English)</Form.Label>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    {/* Campaign Name Dropdown (Chinese) */}
                                    <Form.Group controlId="campaignNameChinese" className="floating-label-group">
                                        <Form.Select
                                            name="campaignName"
                                            value={inputValues.campaignName}
                                            onChange={handleChange}
                                            className="floating-input"
                                        >
                                            <option value=""></option>
                                            <option value="粵曲班">粵曲班</option>
                                            <option value="綜合太極班">綜合太極班</option>
                                            <option value="活力排舞初班">活力排舞初班</option>
                                            <option value="駿伴歷行-做自己的退休策劃師">駿伴歷行 - 做自己的退休策劃師</option>
                                            <option value="Harmonica閑琴逸趣">Harmonica閑琴逸趣</option>
                                            <option value="萌薑樂園-我係「金菠蘿」">萌薑樂園 - 我係「金菠蘿」</option>
                                            <option value="朦朦朧朧無聲體驗活動">朦朦朧朧無聲體驗活動</option>
                                            <option value="「齡」距離潮流重塑">「齡」距離潮流重塑</option>
                                            <option value="童心伴長者">童心伴長者</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">Venue(Chinese)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    {/* Campaign Name Dropdown (English) */}
                                    <Form.Group controlId="campaignNameEnglish" className="floating-label-group">
                                        <Form.Select
                                            name="campaignName"
                                            value={inputValues.campaignName}
                                            onChange={handleChange}
                                            className="floating-input"
                                        >
                                            <option value=""></option>
                                            <option value="Cantonese Opera Class">Cantonese Opera Class</option>
                                            <option value="Traditional Tai Chi Beginning Class">Traditional Tai Chi Beginning Class</option>
                                            <option value="Line Dance Beginner Class">Line Dance Beginner Class</option>
                                            <option value="Be Your Own Retirement Planner">Be Your Own Retirement Planner</option>
                                            <option value="Leisure Harmonica Class">Leisure Harmonica Class</option>
                                            <option value="Prize-giving Activities">Prize-giving Activities</option>
                                            <option value="Hazy Silent Experience Activities">Hazy Silent Experience Activities</option>
                                            <option value="Fashion & Image Design Class">Fashion & Image Design Class</option>
                                            <option value="Activities to Accompany the Elderly">Activities to Accompany the Elderly</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">Venue(English)</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>



                            <Row>

                                {/* Type Dropdown */}
                                <Col md={2}>
                                    <Form.Group controlId="type" className="floating-label-group">
                                        <Form.Select name="type" className="floating-input">
                                            <option value="E">E</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                            <option value="H">H</option>
                                            <option value="J">J</option>
                                            <option value="K">K</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">Type</Form.Label>
                                    </Form.Group>
                                </Col>

                                {/* Code Input */}
                                <Col md={2}>
                                    {/* Code Input holding 240703 */}
                                    <Form.Group controlId="code" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="code"
                                            className="floating-input"
                                            value="240703" // Holding value for code
                                        />
                                        <Form.Label className="floating-label">Code</Form.Label>
                                    </Form.Group>
                                </Col>

                                {/* District Dropdown */}
                                <Col md={3}>
                                    <Form.Group controlId="district" className="floating-label-group">
                                        <Form.Select name="district" className="floating-input">
                                            <option value=""></option>
                                            <option value="First-come-first-served">First-come-first-served</option>
                                            <option value="Lottery">Lottery Drawing</option>

                                        </Form.Select>
                                        <Form.Label className="floating-label">Form</Form.Label>
                                    </Form.Group>
                                </Col>




                                {/* Quota Input */}
                                <Col md={2}>
                                    {/* Quota Input holding value 40 */}
                                    <Form.Group controlId="quota" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="quota"
                                            className="floating-input"
                                        // Holding value for quota
                                        />
                                        <Form.Label className="floating-label">Quota</Form.Label>
                                    </Form.Group>
                                </Col>
                                {/* Inquiry Dropdown */}
                                <Col md={3}>
                                    <Form.Group controlId="inquiry" className="floating-label-group">
                                        <Form.Select name="inquiry" className="floating-input">
                                            <option value="李彩兒">李彩兒</option>
                                            <option value="Ling">Ling</option>
                                            <option value="Alex Law">Alex Law</option>
                                            <option value="Chan Siu Ming">Chan Siu Ming</option>
                                        </Form.Select>
                                        <Form.Label className="floating-label">Inquiry</Form.Label>
                                    </Form.Group>
                                </Col>

                                {/* Form Type Dropdown */}
                                {/* <Col md={3}>
                                    <Form.Group controlId="formType" className="floating-label-group">
                                        <Form.Select name="formType" className="floating-input">
                                            <option value="First-come-first-served">First-come-first-served</option>
                                            <option value="Lottery Drawing">Lottery Drawing</option>

                                        </Form.Select>
                                        <Form.Label className="floating-label">Form Type</Form.Label>
                                    </Form.Group>
                                </Col> */}
                            </Row>
                            <br></br>
                            <br></br>

                            <Row>
                                <h5 className="targetheading">
                                    Target Audience & Price
                                </h5>
                                <br></br>
                                <Col md={2} className="mt-2">
                                    <Form.Group controlId="inquiry" className="floating-label-group">
                                        <Form.Select name="inquiry" className="floating-input">
                                            <option value="長者會員">長者會員</option>
                                            <option value="Ling">Senior</option>
                                            <option value="Alex Law">Pre_Senior</option>

                                        </Form.Select>
                                        <Form.Label className="floating-label">Audience (Chinese)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={2} className="mt-2">
                                    <Form.Group controlId="inquiry" className="floating-label-group">
                                        <Form.Select name="inquiry" className="floating-input">

                                            <option value="Ling">Senior</option>
                                            <option value="Alex Law">Pre_Senior</option>

                                        </Form.Select>
                                        <Form.Label className="floating-label">Audience (English)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={2} className="mt-2">
                                    <Form.Group controlId="code" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="code"
                                            className="floating-input"
                                            value="$275" // Holding value for code
                                        />
                                        <Form.Label className="floating-label">Price</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={1} className="d-flex justify-content-center align-items-center">
                                    <Button
                                        style={{
                                            backgroundColor: '#FF4694',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '0', // Ensures no extra space around the text
                                        }}
                                    >
                                        --
                                    </Button>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={2}>
                                    <Form.Group controlId="inquiry" className="floating-label-group">
                                        <Form.Select name="inquiry" className="floating-input">
                                            <option value="駿薈會員">駿薈會員</option>
                                            <option value="Ling">Senior</option>
                                            <option value="Alex Law">Pre_Senior</option>

                                        </Form.Select>
                                        <Form.Label className="floating-label">Audience (Chinese)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group controlId="inquiry" className="floating-label-group">
                                        <Form.Select name="inquiry" className="floating-input">

                                            <option value="Ling"> Pre_Senior</option>
                                            <option value="Alex Law">Senior</option>

                                        </Form.Select>
                                        <Form.Label className="floating-label">Audience (English)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group controlId="code" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="code"
                                            className="floating-input"
                                            value="$316" // Holding value for code
                                        />
                                        <Form.Label className="floating-label">Price</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={1} className="d-flex justify-content-center align-items-center">
                                    <Button
                                        style={{
                                            backgroundColor: '#FF4694',
                                            border: 'none',
                                            height: '24px',
                                            width: '24px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '0', // Ensures no extra space around the text
                                        }}
                                    >
                                        --
                                    </Button>
                                </Col>

                            </Row>
                            <br></br><br></br>
                            <Row>
                                <h5 className="targetheading">Publish  <FormControlLabel control={<Switch value="true" />} label="" /></h5>                               <p className="publictext">Enable “Publish” in the Portal, and members can view and join the campaign on the mobile app.</p>
                            </Row>
                            <Button style={{ backgroundColor: 'white', border: '#1976D2 1px solid', color: '#1976D2' }} className="custom-success-btn me-2">
                                Cancel
                            </Button>
                            <Button style={{ backgroundColor: '#9B9B9B', border: 'none' }} className="custom-success-btn me-2">
                                Save
                            </Button >
                            <Button style={{ backgroundColor: '#9B9B9B', border: 'none', width: '145px' }} className="custom-success-btn me-2">
                                Request Approve
                            </Button>
                            <Button variant="success" className="custom-success-btn me-2" style={{ width: '160px' }}>
                                Add Price
                            </Button>
                            <Button style={{ backgroundColor: '#E27531', border: 'none' }} className="custom-success-btn me-2" >
                                Approve
                            </Button>


                        </Form>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
}

export default BasicInfo;
