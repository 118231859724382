



// import React, { useState, useEffect } from "react";
// import { Button, Table, Alert } from "react-bootstrap";
// import axios from 'axios';
// import UserManagementNew from "./UserManagementNew";
// import { TablePagination } from "@mui/material";
// import { useSelector } from "react-redux";
// import './usercss/UserManagement.css'
// const UserManagement = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [users, setUsers] = useState([]);
//   const [successMessage, setSuccessMessage] = useState('');
//   // Pagination state
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10); // Set default to 10
//   const [userPermissions, setUserPermissions] = useState(null);
//   const { UserInfo = {} } = useSelector(state => state?.users);

//   useEffect(() => {
//     const permissions = UserInfo?.permission?.permissions || [];
//     const validPermissions = permissions.filter(({ actions }) =>
//       Object.values(actions).some(Boolean)
//     );
//     const userPermissions = validPermissions?.find((p) => p?.ModuleName === "User Management");
//     setUserPermissions(userPermissions);
//   }, [UserInfo]);



//   const fetchUsers = async () => {
//     try {
//       const response = await axios.get(process.env.REACT_APP_BASE_API_URL + "/get-new-user");
//       console.log("Full API response:", response.data);
//       const accounts = response.data.body ? JSON.parse(response.data.body) : [];
//       const usersData = Array.isArray(accounts)
//         ? accounts.filter(user => user.user_name) // Filter out users without a user_name
//         : [];

//       setUsers(usersData);
//     } catch (error) {
//       console.error("Error fetching users:", error);
//     }
//   };






//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const handleAddUser = () => {
//     setSelectedUser(null);
//     setShowModal(true);
//   };

//   const handleEditUser = (user) => {
//     setSelectedUser(user);
//     setShowModal(true);
//   };

//   const handleDeleteUser = async (user) => {
//     if (window.confirm(`Are you sure you want to delete user ${user.user_name || 'Unnamed User'}?`)) {
//       try {
//         await axios.post(process.env.REACT_APP_BASE_API_URL + "/delete-new-user", {
//           pk_id: user.pk_id,
//           sk_id: user.sk_id,
//         });
//         setSuccessMessage(`User ${user.user_name || 'Unnamed User'} deleted successfully!`);
//         fetchUsers();

//         setTimeout(() => setSuccessMessage(''), 2000);
//       } catch (error) {
//         console.error("Error deleting user:", error);
//       }
//     }
//   };

//   const handleCloseModal = () => setShowModal(false);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   return (
//     <div className="container p-5">
//       <div className="row mb-3">
//         <div className="col-10 d-flex align-items-center">
//           <h4 className="firstname">User Management</h4>
//         </div>
//         <div className="col-2 d-flex justify-content-start">
//           {userPermissions?.actions?.add && (
//             <Button className="custom-success-btn" variant="success" onClick={handleAddUser}>
//               Add
//             </Button>
//           )}
//         </div>
//       </div>

//       {/* Success Message */}
//       {successMessage && (
//         <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
//           {successMessage}
//         </Alert>
//       )}

//       <Table className="role-management-table">
//         <thead>
//           <tr className="tabaleh">
//             <th style={{ width: '74.5%' }} className="tabaleh">Name</th>
//             <th style={{ width: '23.8%' }} className="tabaleh">Function(s)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {users.length > 0 ? (
//             users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
//               <tr key={user.pk_id || index}>
//                 <td className="role align-middle">{user.user_name || "No Name Available"}</td>
//                 <td>
//                   {userPermissions?.actions?.edit && (
//                     <Button className="custom-success-btn me-2" onClick={() => handleEditUser(user)}>Edit</Button>
//                   )}
//                   {userPermissions?.actions?.delete && (
//                     <Button className="custom-danger-btn" onClick={() => handleDeleteUser(user)}>Delete</Button>
//                   )}
//                 </td>
//               </tr>
//             ))
//           ) : (
//             <tr>
//               <td colSpan="2" className="text-center">No users available.</td>
//             </tr>
//           )}
//         </tbody>
//       </Table>

//       <TablePagination
//         component="div"
//         count={users.length}
//         page={page}
//         onPageChange={handleChangePage}
//         rowsPerPage={rowsPerPage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//         rowsPerPageOptions={[10, 20, 30]} // Pagination options in increments of 10
//         className='mt-5'
//       />

//       <UserManagementNew
//         show={showModal}
//         handleClose={handleCloseModal}
//         modalTitle={selectedUser ? "Edit User" : "Add User"}
//         userData={selectedUser}
//         fetchUsers={fetchUsers}
//       />
//     </div>
//   );
// };

// export default UserManagement;






import React, { useState, useEffect } from "react";
import { Button, Table, Alert } from "react-bootstrap";
import axios from 'axios';
import UserManagementNew from "./UserManagementNew";
import { useSelector } from "react-redux";
import './usercss/UserManagement.css'

const UserManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // Default rows per page
  const [userPermissions, setUserPermissions] = useState(null);
  const { UserInfo = {} } = useSelector(state => state?.users);

  useEffect(() => {
    const permissions = UserInfo?.permission?.permissions || [];
    const validPermissions = permissions.filter(({ actions }) =>
      Object.values(actions).some(Boolean)
    );
    const userPermissions = validPermissions?.find((p) => p?.ModuleName === "User Management");
    setUserPermissions(userPermissions);
  }, [UserInfo]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_API_URL + "/get-new-user");
      console.log("Full API response:", response.data);
      const accounts = response.data.body ? JSON.parse(response.data.body) : [];
      const usersData = Array.isArray(accounts)
        ? accounts.filter(user => user.user_name) // Filter out users without a user_name
        : [];

      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAddUser = () => {
    setSelectedUser(null);
    setShowModal(true);
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleDeleteUser = async (user) => {
    if (window.confirm(`Are you sure you want to delete user ${user.user_name || 'Unnamed User'}?`)) {
      try {
        await axios.post(process.env.REACT_APP_BASE_API_URL + "/delete-new-user", {
          pk_id: user.pk_id,
          sk_id: user.sk_id,
        });
        setSuccessMessage(`User ${user.user_name || 'Unnamed User'} deleted successfully!`);
        fetchUsers();

        setTimeout(() => setSuccessMessage(''), 2000);
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const handleCloseModal = () => setShowModal(false);

  // Pagination calculation
  const totalPages = Math.ceil(users.length / rowsPerPage);
  const paginatedUsers = users.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container p-5">
      <div className="row mb-3">
        <div className="col-10 d-flex align-items-center">
          <h4 className="firstname">User Management</h4>
        </div>
        <div className="col-2 d-flex justify-content-start">
          {userPermissions?.actions?.add && (
            <Button className="custom-success-btn" variant="success" onClick={handleAddUser}>
              Add
            </Button>
          )}
        </div>
      </div>

      {/* Success Message */}
      {successMessage && (
        <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
          {successMessage}
        </Alert>
      )}

      <Table className="role-management-table">
        <thead>
          <tr className="tabaleh">
            <th style={{ width: '74.5%' }} className="tabaleh">Name</th>
            <th style={{ width: '23.8%' }} className="tabaleh">Function(s)</th>
          </tr>
        </thead>
        <tbody>
          {paginatedUsers.length > 0 ? (
            paginatedUsers.map((user, index) => (
              <tr key={user.pk_id || index}>
                <td className="role align-middle">{user.user_name || "No Name Available"}</td>
                <td>
                  {userPermissions?.actions?.edit && (
                    <Button className="custom-success-btn me-2" onClick={() => handleEditUser(user)}>Edit</Button>
                  )}
                  {userPermissions?.actions?.delete && (
                    <Button className="custom-danger-btn" onClick={() => handleDeleteUser(user)}>Delete</Button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" className="text-center">No users available.</td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Bootstrap Pagination */}
      <nav aria-label="Page navigation example" className="mt-5">
        <ul className="pagination justify-content-end">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </button>
          </li>
          {[...Array(totalPages).keys()].map(page => (
            <li key={page} className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(page + 1)}>
                {page + 1}
              </button>
            </li>
          ))}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              Next
            </button>
          </li>
        </ul>
      </nav>

      <UserManagementNew
        show={showModal}
        handleClose={handleCloseModal}
        modalTitle={selectedUser ? "Edit User" : "Add User"}
        userData={selectedUser}
        fetchUsers={fetchUsers}
      />
    </div>
  );
};

export default UserManagement;
