import React, { useState } from 'react';
import { Container, Card, Button, Row, Col, Form } from 'react-bootstrap';

function DeleteAccount() {
    const [isChecked, setIsChecked] = useState(false);

    const handleDelete = () => {
        if (isChecked) {

            console.log("Account deleted.");
        } else {
            console.log("Please confirm before deleting your account.");
        }
    };

    const handleCancel = () => {

        console.log("Deletion cancelled.");
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    return (
        <Container fluid style={{ backgroundColor: 'white' }}>
            <Row>
                <Col md={8}>
                    <Card style={{ border: 'none' }}>
                        <Card.Body>
                            <Card.Title>Delete Your Account</Card.Title>
                            <Card.Text>
                                When you delete your account, you lose access to Front account services, and we permanently delete your personal data.
                                You can cancel the deletion for 14 days.
                            </Card.Text>


                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                </Col>
                <Card style={{ border: 'none' }}>
                    <Card.Body>
                        {/* Horizontal line */}
                        <hr style={{ borderTop: '1px solid #ccc', margin: '0 -15px' }} />
                        <br></br>

                        {/* Checkbox */}
                        <Form.Check
                            type="checkbox"
                            id="confirm-delete"
                            label="Confirm that I want to delete my account."
                            onChange={handleCheckboxChange}
                            className="mb-3"
                        />

                        <div className="mt-4">
                            <Button variant="danger" onClick={handleDelete} className="me-2 deletebtn" >
                                Delete
                            </Button>
                            <Button variant="outline-secondary" className="canslebtn" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
}

export default DeleteAccount;
