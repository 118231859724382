// import React, { useState } from 'react';
// import { Container, Row, Col, Form, Card, Button, Modal } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { Calendar } from 'react-calendar';
// import "react-calendar/dist/Calendar.css";
// import "react-datepicker/dist/react-datepicker.css";
// import './campaigncss/Datepage.css'
// function Datepage() {
//     const [startDate, setStartDate] = useState(new Date("2024-07-19"));
//     const [endDate, setEndDate] = useState(new Date("2024-09-20"));
//     const [enrollmenStartDateTime, setEnrollmentStartDateTime] = useState(new Date("2024-07-01T10:00"));
//     const [enrollmentEndDateTime, setEnrollmentEndDateTime] = useState(new Date("2024-07-15T12:00"));
//     const [displayStartDateTime, setDisplayStartDateTime] = useState(new Date("2024-07-01T10:00"));
//     const [displayEndDateTime, setDisplayEndDateTime] = useState(new Date("2024-07-15T12:00"));

//     const [showModal, setShowModal] = useState(false);
//     const [modalDate, setModalDate] = useState(new Date());

//     const handleShowModal = (date) => {
//         setModalDate(date);
//         setShowModal(true);
//     };

//     const handleCloseModal = () => {
//         setShowModal(false);
//     };

//     const handleDateChange = (date) => {
//         setModalDate(date);
//         handleCloseModal();
//     };

//     const handleSave = () => {
//         // Handle save logic here
//         console.log("Saved date:", modalDate);
//         // Example: setStartDate(modalDate); // Update state with the new date
//     };

//     // Get the current month index and year
//     const currentMonth = modalDate.getMonth();
//     const currentYear = modalDate.getFullYear();

//     return (
//         <Container fluid>
//             <Row>
//                 <Card className="mt-0 border-0" style={{ position: "relative" }}>
//                     <Card.Body>
//                         <Form>
//                             <h5 className='dateheading ms-2'>Date(s)</h5>
//                             <Row>
//                                 <Col md={5}>
//                                     <Form.Group controlId="campaignStartDate" className="floating-label-group">
//                                         <Form.Control
//                                             className="floating-input"
//                                             type="text"
//                                             value={startDate.toLocaleDateString()}
//                                             onClick={() => handleShowModal(startDate)}
//                                             readOnly
//                                         />
//                                         <Form.Label className="floating-label">Campaign Start Date</Form.Label>
//                                     </Form.Group>
//                                 </Col>
//                                 <Col md={5}>
//                                     <Form.Group controlId="campaignEndDate" className="floating-label-group">
//                                         <Form.Control
//                                             className="floating-input"
//                                             type="text"
//                                             value={endDate.toLocaleDateString()}
//                                             onClick={() => handleShowModal(endDate)}
//                                             readOnly
//                                         />
//                                         <Form.Label className="floating-label">Campaign End Date</Form.Label>
//                                     </Form.Group>
//                                 </Col>
//                             </Row>

//                             <Row>
//                                 <Col md={5}>
//                                     <Form.Group controlId="code" className="floating-label-group">
//                                         <Form.Control
//                                             type="text"
//                                             name="code"
//                                             className="floating-input"
//                                             value="逢星期五" // Holding value for code
//                                         />
//                                         <Form.Label className="floating-label">Recurrence (Chinese)</Form.Label>
//                                     </Form.Group>
//                                 </Col>
//                                 <Col md={5}>
//                                     <Form.Group controlId="code" className="floating-label-group">
//                                         <Form.Control
//                                             type="text"
//                                             name="code"
//                                             className="floating-input"
//                                             value="every Friday" // Holding value for code
//                                         />
//                                         <Form.Label className="floating-label">Recurrence (English)</Form.Label>
//                                     </Form.Group>
//                                 </Col>
//                             </Row>

//                             <Row>
//                                 <Col md={5}>
//                                     <Form.Group controlId="enrollmentDateTime" className="floating-label-group">
//                                         <Form.Control
//                                             className="floating-input"
//                                             type="text"
//                                             value={enrollmenStartDateTime.toLocaleString()}
//                                             onClick={() => handleShowModal(enrollmenStartDateTime)}
//                                             readOnly
//                                         />
//                                         <Form.Label className="floating-label">Enrollment Start Date & Time</Form.Label>
//                                     </Form.Group>
//                                 </Col>
//                                 <Col md={5}>
//                                     <Form.Group controlId="enrollmentDateTime" className="floating-label-group">
//                                         <Form.Control
//                                             className="floating-input"
//                                             type="text"
//                                             value={enrollmentEndDateTime.toLocaleString()}
//                                             onClick={() => handleShowModal(enrollmentEndDateTime)}
//                                             readOnly
//                                         />
//                                         <Form.Label className="floating-label">Enrollment End Date & Time</Form.Label>
//                                     </Form.Group>
//                                 </Col>

//                             </Row>
//                             <Row>
//                                 <Col md={5}>
//                                     <Form.Group controlId="displayStartDateTime" className="floating-label-group">
//                                         <Form.Control
//                                             className="floating-input"
//                                             type="text"
//                                             value={displayStartDateTime.toLocaleString()}
//                                             onClick={() => handleShowModal(displayStartDateTime)}
//                                             readOnly
//                                         />
//                                         <Form.Label className="floating-label">Display Start Date & Time</Form.Label>
//                                     </Form.Group>
//                                 </Col>
//                                 <Col md={5}>
//                                     <Form.Group controlId="displayEndDateTime" className="floating-label-group">
//                                         <Form.Control
//                                             className="floating-input"
//                                             type="text"
//                                             value={displayEndDateTime.toLocaleString()}
//                                             onClick={() => handleShowModal(displayEndDateTime)}
//                                             readOnly
//                                         />
//                                         <Form.Label className="floating-label">Display End Date & Time</Form.Label>
//                                     </Form.Group>
//                                 </Col>
//                             </Row>

//                             <Row className='mt-5'>
//                                 <Button style={{ backgroundColor: 'white', border: '#1976D2 1px solid', color: '#1976D2' }} className="custom-success-btn me-2 mt-5">
//                                     Cancel
//                                 </Button>
//                                 <Button style={{ backgroundColor: '#9B9B9B', border: 'none' }} className="custom-success-btn me-2 mt-5">
//                                     Save
//                                 </Button >
//                                 <Button style={{ backgroundColor: '#9B9B9B', border: 'none', width: '145px' }} className="custom-success-btn me-2 mt-5">
//                                     Request Approve
//                                 </Button>
//                             </Row>
//                         </Form>
//                     </Card.Body>
//                 </Card>
//             </Row>

//             {/* Modal for Yearly Calendar */}
//             <Modal show={showModal} onHide={handleCloseModal} centered size="xl">
//                 <Modal.Header closeButton>
//                     <Modal.Title>Select Date</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body style={{ height: '800px', overflowY: 'auto' }}>
//                     <div className="year-calendar">
//                         {/* Create a scrollable view for the month */}
//                         <Row>
//                             {Array.from({ length: 12 }).map((_, monthIndex) => {
//                                 const month = new Date(currentYear, monthIndex, 1);
//                                 return (
//                                     <Col key={monthIndex} xs={4} className="d-flex flex-column align-items-center">
//                                         <h6>{month.toLocaleString('default', { month: 'long' })}</h6>
//                                         <Calendar
//                                             onChange={handleDateChange}
//                                             value={modalDate}
//                                             tileClassName={({ date }) => (date.toDateString() === modalDate.toDateString() ? 'selected' : '')}
//                                             tileDisabled={({ date }) => date.getMonth() !== monthIndex}
//                                             view="month"
//                                             showNeighboringMonth={false}
//                                             onClickDay={(date) => {
//                                                 setModalDate(date);
//                                             }}
//                                         />
//                                     </Col>
//                                 );
//                             })}
//                         </Row>
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleCloseModal}>
//                         Close
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </Container>
//     );
// }

// export default Datepage;










import React, { useState } from 'react';
import { Container, Row, Col, Form, Card, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Calendar } from 'react-calendar';
import "react-calendar/dist/Calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import './campaigncss/Datepage.css'
function Datepage() {
    const [startDate, setStartDate] = useState(new Date("2024-07-19"));
    const [endDate, setEndDate] = useState(new Date("2024-09-20"));
    const [enrollmenStartDateTime, setEnrollmentStartDateTime] = useState(new Date("2024-07-01T10:00"));
    const [enrollmentEndDateTime, setEnrollmentEndDateTime] = useState(new Date("2024-07-15T12:00"));
    const [displayStartDateTime, setDisplayStartDateTime] = useState(new Date("2024-07-01T10:00"));
    const [displayEndDateTime, setDisplayEndDateTime] = useState(new Date("2024-07-15T12:00"));

    const [showModal, setShowModal] = useState(false);
    const [modalDate, setModalDate] = useState(new Date());

    const handleShowModal = (date) => {
        setModalDate(date);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleDateChange = (date) => {
        setModalDate(date);
        handleCloseModal();
    };

    const handleSave = () => {
        // Handle save logic here
        console.log("Saved date:", modalDate);
        // Example: setStartDate(modalDate); // Update state with the new date
    };

    // Get the current month index and year
    const currentMonth = modalDate.getMonth();
    const currentYear = modalDate.getFullYear();

    return (
        <Container fluid>
            <Row>
                <Card className="mt-0 border-0" style={{ position: "relative" }}>
                    <Card.Body>
                        <Form>
                            <h5 className='dateheading ms-2'>Date</h5>
                            <Row>
                                <Col md={5}>
                                    <Form.Group controlId="campaignStartDate" className="floating-label-group">
                                        <Form.Control
                                            className="floating-input"
                                            type="date"
                                            // value={startDate.toLocaleDateString()}
                                            // onClick={() => handleShowModal(startDate)}
                                            // readOnly
                                        />
                                        <Form.Label className="floating-label">Campaign Start Date</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="campaignEndDate" className="floating-label-group">
                                        <Form.Control
                                            className="floating-input"
                                            type="date"
                                            // value={endDate.toLocaleDateString()}
                                            // onClick={() => handleShowModal(endDate)}
                                            // readOnly
                                        />
                                        <Form.Label className="floating-label">Campaign End Date</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={5}>
                                    <Form.Group controlId="code" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="code"
                                            className="floating-input"
                                            value="逢星期五" // Holding value for code
                                        />
                                        <Form.Label className="floating-label">Recurrence (Chinese)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="code" className="floating-label-group">
                                        <Form.Control
                                            type="text"
                                            name="code"
                                            className="floating-input"
                                            value="every Friday" // Holding value for code
                                        />
                                        <Form.Label className="floating-label">Recurrence (English)</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={5}>
                                    <Form.Group controlId="enrollmentDateTime" className="floating-label-group">
                                        <Form.Control
                                            className="floating-input"
                                            type="datetime-local"
                                            
                                        />
                                        <Form.Label className="floating-label">Enrollment Start Date & Time</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="enrollmentDateTime" className="floating-label-group">
                                        <Form.Control
                                            className="floating-input"
                                            type="datetime-local"
                                           
                                        />
                                        <Form.Label className="floating-label">Enrollment End Date & Time</Form.Label>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={5}>
                                    <Form.Group controlId="displayStartDateTime" className="floating-label-group">
                                        <Form.Control
                                            className="floating-input"
                                            type="datetime-local"
                                            
                                        />
                                        <Form.Label className="floating-label">Display Start Date & Time</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="displayEndDateTime" className="floating-label-group">
                                        <Form.Control
                                            className="floating-input"
                                            type="datetime-local"
                                            
                                        />
                                        <Form.Label className="floating-label">Display End Date & Time</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className='mt-5'>
                                <Button style={{ backgroundColor: 'white', border: '#1976D2 1px solid', color: '#1976D2' }} className="custom-success-btn me-2 mt-5">
                                    Cancel
                                </Button>
                                <Button style={{ backgroundColor: '#9B9B9B', border: 'none' }} className="custom-success-btn me-2 mt-5">
                                    Save
                                </Button >
                                <Button style={{ backgroundColor: '#9B9B9B', border: 'none', width: '145px' }} className="custom-success-btn me-2 mt-5">
                                    Request Approve
                                </Button>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>

            {/* Modal for Yearly Calendar */}
            <Modal show={showModal} onHide={handleCloseModal} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Select Date</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '800px', overflowY: 'auto' }}>
                    <div className="year-calendar">
                        {/* Create a scrollable view for the month */}
                        <Row>
                            {Array.from({ length: 12 }).map((_, monthIndex) => {
                                const month = new Date(currentYear, monthIndex, 1);
                                return (
                                    <Col key={monthIndex} xs={4} className="d-flex flex-column align-items-center">
                                        <h6>{month.toLocaleString('default', { month: 'long' })}</h6>
                                        <Calendar
                                            onChange={handleDateChange}
                                            value={modalDate}
                                            tileClassName={({ date }) => (date.toDateString() === modalDate.toDateString() ? 'selected' : '')}
                                            tileDisabled={({ date }) => date.getMonth() !== monthIndex}
                                            view="month"
                                            showNeighboringMonth={false}
                                            onClickDay={(date) => {
                                                setModalDate(date);
                                            }}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Datepage;
