// import React, { useState } from 'react';
// import { Card, Container, Row, Button, Modal, Form, Table, InputGroup } from 'react-bootstrap';
// import { FaSearch } from 'react-icons/fa'; // Import FontAwesome search icon
// import './campaigncss/Enrollement.css'
// function Enrollnment() {
//     const [showModal, setShowModal] = useState(false);
//     const [members, setMembers] = useState([]);
//     const [isEditing, setIsEditing] = useState(false);
//     const [editIndex, setEditIndex] = useState(null);

//     // Initial member state
//     const [newMember, setNewMember] = useState({
//         memberId: 'Q000006',
//         memberName: 'Lily Kong',
//         mobile: '98370232',
//         status: 'approve',
//         lastUpdated: new Date().toLocaleString()
//     });

//     // Show modal for adding or editing
//     const handleShowModal = () => setShowModal(true);
//     const handleCloseModal = () => {
//         setShowModal(false);
//         setIsEditing(false);
//         setNewMember({
//             memberId: '',
//             memberName: '',
//             mobile: '',
//             status: 'approve',
//             lastUpdated: ''
//         }); // Reset form after close
//     };

//     // Handle form input change
//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setNewMember({ ...newMember, [name]: value });
//     };

//     // Save new or updated member
//     const handleSaveMember = () => {
//         if (isEditing && editIndex !== null) {
//             // Update existing member
//             const updatedMembers = members.map((member, index) =>
//                 index === editIndex ? { ...newMember, lastUpdated: new Date().toLocaleString() } : member
//             );
//             setMembers(updatedMembers);
//         } else {
//             // Add new member
//             setMembers([...members, { ...newMember, lastUpdated: new Date().toLocaleString() }]);
//         }
//         handleCloseModal(); // Close modal after saving
//     };

//     // Handle edit button click
//     const handleEditMember = (index) => {
//         const memberToEdit = members[index];
//         setNewMember(memberToEdit); // Fill the form with selected member data
//         setIsEditing(true);
//         setEditIndex(index);
//         handleShowModal(); // Open the modal for editing
//     };

//     return (
//         <Container>
//             {/* <Row>
//                 <Card className="mt-0 border-0" style={{ position: 'relative' }}>
//                     <Card.Body>
//                         <h5>Enrollnment Status</h5>
//                         <div className="d-flex justify-content-end">
//                             <Button variant="success" className="custom-success-btn me-2" style={{ width: '160px' }} onClick={handleShowModal}>
//                                 Add Member
//                             </Button>
//                             <Button variant="success" className="custom-success-btn me-2" style={{ width: '160px' }}>
//                                 Add Group
//                             </Button>
//                         </div>
//                     </Card.Body>
//                 </Card>
//             </Row> */}

//             <Row>
//                 <Card className="mt-0 border-0" style={{ position: 'relative' }}>
//                     <Card.Body>
//                         <div className="d-flex justify-content-between align-items-center">
//                             <h5 className='enrollementhead'>Enrollment Status</h5>
//                             <div className="d-flex">
//                                 <Button
//                                     variant="success"
//                                     className="custom-success-btn me-2"
//                                     style={{ width: '160px' }}
//                                     onClick={handleShowModal}
//                                 >
//                                     Add Member
//                                 </Button>
//                                 <Button
//                                     variant="success"
//                                     className="custom-success-btn"
//                                     style={{ width: '160px' }}
//                                 >
//                                     Add Group
//                                 </Button>
//                             </div>
//                         </div>
//                     </Card.Body>
//                 </Card>
//             </Row>


//             {/* Modal for adding or editing a member */}
//             <Modal show={showModal} onHide={handleCloseModal} centered size="md" className="custom-modal">

//                 <Modal.Body>
//                     <Form>
//                         <h4 className="maintitle">{isEditing ? 'Edit Member' : 'Add Member'}</h4>
//                         {/* Member ID with search icon */}
//                         <Form.Group controlId="formMemberId" className="floating-label-group">
//                             <InputGroup>
//                                 <Form.Control
//                                     type="text"
//                                     name="memberId"
//                                     value={newMember.memberId}
//                                     onChange={handleInputChange}
//                                     className="floating-input"
//                                     placeholder=" " // Necessary for the floating label effect
//                                 />
//                                 <InputGroup.Text>
//                                     <FaSearch />
//                                 </InputGroup.Text>
//                             </InputGroup>
//                             <Form.Label className="floating-label">Member ID</Form.Label>
//                         </Form.Group>

//                         {/* Member Name */}
//                         <Form.Group controlId="formMemberName" className="floating-label-group mt-3">
//                             <Form.Control
//                                 type="text"
//                                 name="memberName"
//                                 value={newMember.memberName}
//                                 onChange={handleInputChange}
//                                 className="floating-input"
//                                 placeholder=" " // Necessary for the floating label effect
//                             />
//                             <Form.Label className="floating-label">Member Name</Form.Label>
//                         </Form.Group>

//                         {/* Mobile */}
//                         <Form.Group controlId="formMobile" className="floating-label-group mt-3">
//                             <Form.Control
//                                 type="text"
//                                 name="mobile"
//                                 value={newMember.mobile}
//                                 onChange={handleInputChange}
//                                 className="floating-input"
//                                 placeholder=" " // Necessary for the floating label effect
//                             />
//                             <Form.Label className="floating-label">Mobile</Form.Label>
//                         </Form.Group>

//                         {/* Status (Approve/Reject) */}
//                         <Form.Group controlId="formStatus" className="floating-label-group mt-3">
//                             <Form.Label>Status</Form.Label>
//                             <div>
//                                 <Form.Check
//                                     type="radio"
//                                     label="Approve"
//                                     name="status"
//                                     value="approve"
//                                     checked={newMember.status === 'approve'}
//                                     onChange={handleInputChange}
//                                 />
//                                 <Form.Check
//                                     type="radio"
//                                     label="Reject"
//                                     name="status"
//                                     value="reject"
//                                     checked={newMember.status === 'reject'}
//                                     onChange={handleInputChange}
//                                 />
//                             </div>
//                         </Form.Group>
//                     </Form>
//                 </Modal.Body>

//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleCloseModal}>
//                         Cancel
//                     </Button>
//                     <Button variant="primary" onClick={handleSaveMember}>
//                         {isEditing ? 'Update' : 'Save'}
//                     </Button>
//                 </Modal.Footer>
//             </Modal>

//             {/* Display saved members in a table */}
//             {members.length > 0 && (
//                 <Table striped hover responsive className="centered-table">
//                     <thead>
//                         <tr>
//                             <th>Member ID</th>
//                             <th>Member Name</th>
//                             <th>Mobile</th>
//                             <th>Approver Status</th>
//                             <th>Last Updated</th>
//                             <th>Function(s)</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {members.map((member, index) => (
//                             <tr key={index}>
//                                 <td>{member.memberId}</td>
//                                 <td>{member.memberName}</td>
//                                 <td>{member.mobile}</td>
//                                 <td>{member.status === 'approve' ? 'Approved' : 'Rejected'}</td>
//                                 <td>{member.lastUpdated}</td>
//                                 <td>

//                                     <Button variant="success" className="custom-success-btn me-2" onClick={() => handleEditMember(index)}>Edit</Button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </Table>
//             )}
//         </Container>
//     );
// }

// export default Enrollnment;




import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal, Form, Table, InputGroup, Card } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import './campaigncss/Enrollement.css'

function Enrollment() {
    const [showMemberModal, setShowMemberModal] = useState(false);
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [members, setMembers] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(null);

    // Initial member state
    const [newMember, setNewMember] = useState({
        memberId: '',
        memberName: '',
        mobile: '',
        status: 'approve',
        lastUpdated: ''
    });

    // Show modals for adding or editing
    const handleShowMemberModal = () => setShowMemberModal(true);
    const handleCloseMemberModal = () => {
        setShowMemberModal(false);
        setIsEditing(false);
        setNewMember({
            memberId: '',
            memberName: '',
            mobile: '',
            status: 'approve',
            lastUpdated: ''
        });
    };

    const handleShowGroupModal = () => setShowGroupModal(true);
    const handleCloseGroupModal = () => setShowGroupModal(false);

    // Handle form input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewMember({ ...newMember, [name]: value });
    };

    // Save new or updated member
    const handleSaveMember = () => {
        if (isEditing && editIndex !== null) {
            // Update existing member
            const updatedMembers = members.map((member, index) =>
                index === editIndex ? { ...newMember, lastUpdated: new Date().toLocaleString() } : member
            );
            setMembers(updatedMembers);
        } else {
            // Add new member
            setMembers([...members, { ...newMember, lastUpdated: new Date().toLocaleString() }]);
        }
        handleCloseMemberModal();
    };

    // Handle edit button click
    const handleEditMember = (index) => {
        const memberToEdit = members[index];
        setNewMember(memberToEdit);
        setIsEditing(true);
        setEditIndex(index);
        handleShowMemberModal();
    };

    return (
        <Container>
            {/* Header and Add Buttons */}
            {/* <Row className="my-4">
                <h4 className='enrollementhead'>Enrollment Status</h4>
                <div className="d-flex justify-content-end">
                    <Button variant="success" className="me-2" onClick={handleShowMemberModal}>
                        Add Member
                    </Button>
                    <Button variant="success" onClick={handleShowGroupModal}>
                        Add Group
                    </Button>
                </div>
            </Row> */}



            <Row>
                <Card className="mt-0 border-0" style={{ position: 'relative' }}>
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className='enrollementhead'>Enrollment Status</h5>
                            <div className="d-flex">
                                <Button
                                    variant="success"
                                    className="custom-success-btn me-2"
                                    style={{ width: '160px' }}
                                    onClick={handleShowMemberModal}
                                >
                                    Add Member
                                </Button>
                                <Button
                                    variant="success"
                                    className="custom-success-btn"
                                    style={{ width: '160px' }}
                                    onClick={handleShowGroupModal}
                                >
                                    Add Group
                                </Button>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Row>



            {/* Add/Edit Member Modal */}
            <Modal show={showMemberModal} onHide={handleCloseMemberModal} centered className="custom-modal-width">
                <Modal.Body>
                    <Form>
                        <h4 className='memberheads'>{isEditing ? 'Edit Member' : 'Add Member'}</h4>


                        <Row className="mb-3">
                            <Col md={6}> {/* Set width to 8 columns */}
                                <Form.Group controlId="campaignName">
                                    <Form.Label className='grouplabel'> Member ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name=""
                                        className="floating-input memberslabels"
                                    // Holding value for quota
                                    />
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row className="mb-3">
                            <Col md={9}> {/* Set width to 8 columns */}
                                <Form.Group controlId="campaignName">
                                    <Form.Label className='grouplabel'> Member Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name=""
                                        className="floating-input memberslabels"
                                    // Holding value for quota
                                    />
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row className="mb-3">
                            <Col md={6}> {/* Set width to 8 columns */}
                                <Form.Group controlId="campaignName">
                                    <Form.Label className='grouplabel'>Mobile</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name=""
                                        className="floating-input memberslabels"
                                    // Holding value for quota
                                    />
                                </Form.Group>
                            </Col>
                        </Row>




                        <Form.Group controlId="formStatus" className="mt-3">
                            <Form.Label className='grouplabel'>Status</Form.Label>
                            <Form.Check
                                type="radio"
                                label="Approve"
                                name="status"
                                value="approve"
                                checked={newMember.status === 'approve'}
                                onChange={handleInputChange}
                                className='grouplabel'
                            />
                            <Form.Check
                                type="radio"
                                label="Reject"
                                name="status"
                                value="reject"
                                checked={newMember.status === 'reject'}
                                onChange={handleInputChange}
                                className='grouplabel'
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="" onClick={handleCloseMemberModal} className="me-2 groupclear">
                        Cancel
                    </Button>
                    <Button variant="" onClick={handleSaveMember} className='groupsearch'>
                        {isEditing ? 'Update' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Add Group Modal */}
            <Modal
                show={showGroupModal}
                onHide={handleCloseGroupModal}
                centered
                ClassName="modaldialogss"
            >
                <Modal.Body>
                    <h4 className='groupheading ps-3'>Add a Group</h4>

                    {/* Campaign Name Section */}


                    {/* Filter Section */}
                    <Card className="p-3 mb-3 border-0">
                        <Row className="mb-3">
                            <Col md={8}> {/* Set width to 8 columns */}
                                <Form.Group controlId="campaignName">
                                    <Form.Label className='grouplabel'>Campaign Name</Form.Label>
                                    <Form.Select className='grouplabel'>
                                        <option className='grouplabel'>Cantonese Opera Class (Beginner)</option>
                                        {/* Add more options if needed */}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-3">
                            <Col md={4} className="d-flex align-items-center">
                                <Form.Group controlId="filter" className="w-100">
                                    <Form.Label className='grouplabel'>Filter</Form.Label>
                                    <Form.Select className='grouplabel'>
                                        <option className='grouplabel'>None</option>
                                        {/* Generate options from 0 to 100 in increments of 5 */}
                                        {Array.from({ length: 21 }, (_, i) => (
                                            <option key={i} value={i * 5}>{i * 5}%</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <span className="ms-2 mt-4 align-self-center grouplabel">above</span> {/* This keeps "above" text aligned */}


                            </Col>
                        </Row>

                        <Row className="justify-content-end">
                            <Col md="auto">
                                <Button variant="" className="me-2 groupclear">Clear</Button>
                                <Button variant="" className='groupsearch'>Search</Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} className="d-flex justify-content-between mt-5">
                                <p className='grouplabel'>Quota: 10</p>
                                <p className='grouplabel'>No. of applicants: 8</p>
                                <p className='grouplabel'>Quota usage: 80%</p>
                            </Col>
                        </Row>


                    </Card>




                    {/* Table */}
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th className='tableheadgrp'>Member ID</th>
                                <th className='tableheadgrp'>Member Name</th>
                                <th className='tableheadgrp'>Mobile</th>
                                <th className='tableheadgrp'>Class 1</th>
                                <th className='tableheadgrp'>Class 2</th>
                                <th className='tableheadgrp'>Class 3</th>
                                <th className='tableheadgrp'>Cla</th>
                                <th className='tableheadgrp'>Reason of Absence</th>
                                <th className='tableheadgrp'>Overall Attendance</th>
                                <th className='tableheadgrp'>Status</th>
                                <th className='tableheadgrp'>Edit</th>
                            </tr>
                        </thead>
                        <tbody className='tableheadgrp'>
                            <tr>
                                <td>Q000006</td>
                                <td>Lily Kong</td>
                                <td>98370232</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td></td>
                                <td>100%</td>
                                <td>Approved</td>
                                <td>
                                    <Button variant="primary" size="sm">✎</Button>
                                </td>
                            </tr>
                            <tr>
                                <td>Q000006</td>
                                <td>Lily Kong</td>
                                <td>98370232</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td></td>
                                <td>100%</td>
                                <td>Approved</td>
                                <td>
                                    <Button variant="primary" size="sm">✎</Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="" onClick={handleCloseGroupModal} className='groupclear'>
                        Cancel
                    </Button>
                    <Button variant="" className='groupsearch'>Import</Button>
                </Modal.Footer>
            </Modal>


        </Container>
    );
}

export default Enrollment;
