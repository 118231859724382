import React from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import './membercss/MemberInfo.css';

function RecentDevices() {
    const devices = []; 

    return (
        <Container fluid>
            <Row>
                <Col>

                    <Card className="custom-shadow" style={{ border: 'none' }} >
                        <Card.Body>
                            <h6 className="titlename">Recent Devices</h6>
                            <Table bordered={false} responsive>
                                <thead >
                                    <tr >
                                        <th className="text-center table-header" style={{ backgroundColor: '#E9E9E9' }} >Device</th>
                                        <th className="text-center table-header" style={{ backgroundColor: '#E9E9E9' }} >Location</th>
                                        <th className="text-center table-header" style={{ backgroundColor: '#E9E9E9' }}>Recent Activity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {devices.length === 0 ? (
                                        <tr>
                                            <td style={{ color: '#9B9B9B' }} colSpan="3" className="text-center">
                                                No data
                                            </td>
                                        </tr>
                                    ) : (
                                        devices.map((device, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{device.name}</td>
                                                <td className="text-center">{device.location}</td>
                                                <td className="text-center">{device.activity}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default RecentDevices;
